import React, { useEffect, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import {
    CashIcon,
    ClientIcon,
    DayIcon,
    MenuIcon,
    MoneyIcon,
    MonthIcon,
    StylistIcon,
    YearIcon,
} from '../../assets/icons';
import { Header } from '../../components';
import { useAppointmentQuery } from '../../lib/dataClient/useAppointmentQuery';
import { Appointment } from '../../lib/dataTypes/appointments';
import moment from 'moment';
import { getProfileClient } from '../../lib/getProfileClient';
import { FashProfile } from '../../lib/dataTypes/fashProfile';
import Loader from '../../components/Loader';

interface Statistic {
    title: string;
    value: string;
    icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
}
const StatisticsOwnerPage = () => {
    const profileClient = getProfileClient();
    const { getAllAppointmentsBySalonOwner } = useAppointmentQuery();
    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [statisticsToShow, setStatisticsToShow] = useState<Statistic[]>();
    const tabs = [
        { title: 'Hoy', icon: DayIcon },
        { title: 'Últimos 7 días', icon: MonthIcon },
        { title: 'Últimos 30 días', icon: YearIcon },
    ];

    const statistics = [
        { title: 'Ingresos', value: '$ 0', icon: MoneyIcon },
        { title: 'Clientes', value: '0', icon: ClientIcon },
        { title: 'Top Estilistas', value: 'No se realizaron citas', icon: StylistIcon },
        { title: 'Ingreso por cliente', value: '$ 0', icon: CashIcon },
    ];

    const getStatistics = async () => {
        let filteredAppointments: Array<Appointment> | undefined = [];
        if (selectedTab === 0) {
            const today = moment();
            filteredAppointments = [...appointments];
            filteredAppointments = filteredAppointments.filter(
                (appointment) => moment(appointment.date).format('DD MMMM yy') === today.format('DD MMMM yy')
            );
        }
        if (selectedTab === 1) {
            filteredAppointments = [...appointments];
            filteredAppointments = filteredAppointments.filter((appointment) =>
                moment(appointment.date).isBetween(moment().subtract(7, 'days'), moment())
            );
        }
        if (selectedTab === 2) {
            filteredAppointments = [...appointments];
            filteredAppointments = filteredAppointments.filter((appointment) =>
                moment(appointment.date).isBetween(moment().month(), moment().add(1, 'month'))
            );
        }
        if (filteredAppointments) {
            let totalPrice = 0;
            const stylists = filteredAppointments.map((appointment) => appointment.stylist?.entioId);
            let totalClients = 0;
            let topStylist = '';
            let i = 0;
            let timesRepeatedStylist = 0;
            while (i < filteredAppointments?.length) {
                totalPrice += Number(filteredAppointments[i].price);
                totalClients++;
                let j = 0;
                let counter = 0;
                while (j < stylists.length) {
                    stylists[i] === stylists[j] && counter++;
                    if (counter > timesRepeatedStylist) {
                        timesRepeatedStylist = counter;
                        topStylist = stylists[i];
                    }
                    j++;
                }
                i++;
            }
            const prom = (totalPrice / filteredAppointments.length).toFixed(2);
            const promPrice = isNaN(Number(prom)) ? 0 : prom;
            const profile = await profileClient.getProfiles([topStylist]);
            //eslint-disable-next-line
            const stylistsArray: FashProfile[] | any = [];
            for (const data in profile) {
                stylistsArray.push(profile.data[data]);
            }
            statistics[0] = { ...statistics[0], value: `$ ${totalPrice}` };
            statistics[1] = { ...statistics[1], value: `${totalClients}` };
            statistics[2] = {
                ...statistics[2],
                value: stylistsArray ? stylistsArray[0]?.profile?.firstName : 'No se realizaron citas',
            };
            statistics[3] = { ...statistics[3], value: `$ ${promPrice}` };
            setStatisticsToShow(() => statistics);
        }
    };

    const getAppointments = async () => {
        const ownerId = await profileClient.getProfile();
        if (ownerId) {
            console.log(ownerId);
            try {
                const data = await getAllAppointmentsBySalonOwner(ownerId.data.user.id);
                if (data?.length > 0) {
                    setAppointments(() => data);
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    useEffect(() => {
        getAppointments();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        getStatistics();
        // eslint-disable-next-line
    }, [appointments]);
    useEffect(() => {
        getStatistics();
        // eslint-disable-next-line
    }, [selectedTab]);
    return (
        <IonPage>
            {!statisticsToShow ? (
                <Loader />
            ) : (
                <IonContent>
                    <Header
                        title="Estadísticas"
                        left={<MenuIcon />}
                        menuRequire
                        right={
                            <img src="https://via.placeholder.com/32" alt="avatar" className="rounded-full w-8 h-8" />
                        }
                    />
                    <div className="p-4 flex flex-col gap-7">
                        <ul className="flex max-w-[380px] w-full mx-auto">
                            {tabs.map(({ title, icon: Icon }, index) => (
                                <li
                                    key={index}
                                    onClick={() => setSelectedTab(index)}
                                    className={`flex-1 flex flex-col items-center gap-2 text-center p-2 rounded-md border-2 border-opacity-0 border-primaryColor transition-all duration-150 ${
                                        selectedTab === index && 'border-opacity-100 bg-gray-100'
                                    }`}>
                                    <Icon />
                                    <span>{title}</span>
                                </li>
                            ))}
                        </ul>
                        <section className="flex flex-col gap-6 max-w-[360px] w-full mx-auto">
                            {statisticsToShow.map(({ title, value, icon: Icon }, index) => (
                                <article
                                    key={`${index}-statistic-${title}`}
                                    className="rounded-2xl border-2 border-primaryColor bg-gray-100 p-[10px] flex flex-col items-center gap-2">
                                    <Icon />
                                    <h3 className="text-primaryColor font-medium text-xl tracking-[-0.25px]">
                                        {title}
                                    </h3>
                                    {title === 'Top Estilistas' ? (
                                        <p className="flex gap-4 font-medium text-[20px] leading-[39px] tracking-tight">
                                            {value}
                                        </p>
                                    ) : (
                                        <p className="flex gap-4 font-medium text-[32px] leading-[39px] tracking-tight">
                                            {value}
                                        </p>
                                    )}
                                </article>
                            ))}
                        </section>
                    </div>
                </IonContent>
            )}
        </IonPage>
    );
};

export default StatisticsOwnerPage;
