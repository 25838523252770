import { ServicesPerSalon } from './services';
import { User } from './users';

export interface Salon {
    _id: string;
    name: string;
    address: string;
    description: string;
    owner: User;
    services: Array<ServicesPerSalon>;
    workers: Array<User>;
    timetable: TimeTable;
    igUrl: string;
    phoneNumber: string;
    whatsApp: string;
    regularClients: Array<User>;
    img: {
        url?: string;
        blob: {
            mimeType: string;
            data: string;
        };
    };
}
export interface TimeTable {
    _id: string;
    monday: {
        start: string;
        end: string;
    };
    tuesday: {
        start: string;
        end: string;
    };
    wednesday: {
        start: string;
        end: string;
    };
    thursday: {
        start: string;
        end: string;
    };
    friday: {
        start: string;
        end: string;
    };
    saturday: {
        start: string;
        end: string;
    };
    sunday: {
        start: string;
        end: string;
    };
}

export const salonQuery = `  _id
name
description
owner {
    _id
    entioId
}
services {
    _id
    service {
        _id
        name
        img {
            url
            blob {
                mimeType
                data
            }
        }
    }
    price
    duration
}
address
workers {
        _id
         entioId
}
igUrl
phoneNumber
whatsApp
timetable {
    _id
    monday {
        start
        end
    }
    tuesday {
        start
        end
    }
    wednesday {
        start
        end
    }
    thursday {
        start
        end
    }
    friday {
        start
        end
    }
    saturday {
        start
        end
    }
    sunday {
        start
        end
    }
}
regularClients{
    _id
    entioId
}
img {
    url
    blob {
        mimeType
        data
    }
}
`;
