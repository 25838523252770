import { gql, useMutation } from '@apollo/client';
export const useServicePerSalonCreate = () => {
    const [create] = useMutation(gql`
        mutation CreateServicePerSalon($service: ObjectId!, $price: String!, $duration: String!) {
            insertOneServicePerSalon(data: { service: { link: $service }, price: $price, duration: $duration }) {
                _id
            }
        }
    `);
    return async (data: { service: string; price: string; duration: string }) => {
        try {
            console.log(data);
            const response = await create({
                variables: data,
            });
            return response?.data?.insertOneServicePerSalon;
        } catch (err) {
            console.log(err);
        }
    };
};
