import React from 'react';

const HomeIconBlack = () => {
    return (
        <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.3332 21.3334V14.6667H15.6666V21.3334C15.6666 22.0667 16.2666 22.6667 16.9999 22.6667H20.9999C21.7332 22.6667 22.3332 22.0667 22.3332 21.3334V12H24.5999C25.2132 12 25.5066 11.24 25.0399 10.84L13.8932 0.800022C13.3866 0.346689 12.6132 0.346689 12.1066 0.800022L0.959887 10.84C0.506553 11.24 0.786553 12 1.39989 12H3.66655V21.3334C3.66655 22.0667 4.26655 22.6667 4.99989 22.6667H8.99989C9.73322 22.6667 10.3332 22.0667 10.3332 21.3334Z"
                fill="#1F1F1F"
            />
        </svg>
    );
};

export default HomeIconBlack;
