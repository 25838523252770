import { IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { EditIcon, MenuIcon } from '../../assets/icons';
import { Header } from '../../components';
import Loader from '../../components/Loader';
import { useSalonQuery } from '../../lib/dataClient/useSalonQuery';
import { FashProfile } from '../../lib/dataTypes/fashProfile';
import { Salon } from '../../lib/dataTypes/salon';
import { getProfileClient } from '../../lib/getProfileClient';
const ProfileOwnerPage = () => {
    const [userProfile, setUserProfile] = useState<FashProfile>();
    const profileClient = getProfileClient();
    const [salon, setSalon] = useState<Salon>();
    //eslint-disable-next-line
    const [workers, setWorkers] = useState<FashProfile[] | any>();
    const { getOneSalonByOwnerEntioId } = useSalonQuery();
    const getUserData = async () => {
        const profile = await profileClient.getProfile();
        const salon = await getOneSalonByOwnerEntioId(profile.data.user.id);
        setUserProfile(() => profile.data);
        setSalon(() => salon);
    };

    const getWorkers = async () => {
        if (salon) {
            const workers: string[] = salon.workers.map((worker) => worker.entioId) || [' '];
            if (workers) {
                const response = await profileClient.getProfiles(workers);
                const profilesId = response.data;
                const workersProfiles = workers.map((worker) => profilesId[worker]);
                setWorkers(workersProfiles);
            }
        } else {
            setWorkers([]);
        }
    };

    useEffect(() => {
        getUserData();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        getWorkers();
        //eslint-disable-next-line
    }, [salon]);
    return (
        <IonPage>
            {!userProfile || !workers ? (
                <Loader />
            ) : (
                <div className="flex flex-col items-center font-primaryFont h-full overflow-scroll">
                    <Header left={<MenuIcon />} right={''} title="Mi Perfil" />

                    <div className="flex justify-center my-7">
                        <div className="relative h-[130px] rounded-full">
                            <img
                                src={
                                    userProfile?.profile?.img?.url
                                        ? userProfile?.profile?.img?.url
                                        : userProfile?.profile?.img?.blob.data
                                        ? `data:${userProfile.profile?.img?.blob.mimetype};base64,${userProfile.profile?.img?.blob.data}`
                                        : 'https://picsum.photos/200/300?random=1'
                                }
                                alt="profileImg"
                                className="h-full w-[130px] rounded-full"
                            />
                            <div className="flex justify-end">
                                <div
                                    className="bg-[#82385E] rounded-full p-2 absolute top-0 -right-2 cursor-pointer"
                                    onClick={() => {
                                        console.log('boton clickeado');
                                    }}>
                                    <EditIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[85%] flex flex-col items-center">
                        <div className="w-full flex flex-col items-start">
                            <label
                                htmlFor="nombre"
                                className="text-[18px] relative top-3 left-3 bg-white pl-1 pr-1 text-center focus:text-primaryColor">
                                Nombre
                            </label>
                            <input
                                className="outline outline-1 outline-gray-400 w-full p-2 rounded-[4px] h-[56px] text-[18px] focus:outline-primaryColor focus:text-primaryColor"
                                type="text"
                                defaultValue={userProfile?.profile.firstName}
                            />
                        </div>
                        <div className="w-full flex flex-col items-start">
                            <p className="text-[18px] relative top-3 left-3 bg-white text-center pl-1 pr-1">Correo</p>
                            <input
                                className="outline outline-1 outline-gray-400 w-full p-2 rounded-[4px] h-[56px] text-[18px] focus:outline-primaryColor focus:text-primaryColor"
                                type="text"
                                defaultValue={userProfile?.user.email}></input>
                        </div>
                        <div className="w-full flex flex-col items-start">
                            <p className="text-[18px] relative top-3 left-3 bg-white text-center pl-1 pr-1">Teléfono</p>
                            <input
                                className="outline outline-1 outline-gray-400 w-full p-2 rounded-[4px] h-[56px] text-[18px] focus:outline-primaryColor focus:text-primaryColor"
                                type="text"
                                defaultValue={userProfile?.user.phone}></input>
                        </div>
                        <div className="w-full flex flex-col items-start mb-1">
                            <p className="text-[18px] relative top-3 left-3 bg-white text-center pl-1 pr-1">
                                Cumpleaños *
                            </p>
                            <input
                                className="outline outline-1 outline-gray-400 w-full p-2 rounded-[4px] h-[56px] text-[18px] focus:outline-primaryColor focus:text-primaryColor"
                                type="date"
                                defaultValue={userProfile?.profile.birthDate}></input>
                            <p className="text-[14px] text-gray-400 mt-2">*Formato dia/mes/año</p>
                        </div>
                    </div>
                    <div className="w-full flex flex-col items-center mt-5">
                        <h2 className="bg-secondaryColor text-[24px] w-[93%] h-11 text-center text-primaryColor shadow-[0_1px_3px_rgba(0,0,0,0.3)] flex flex-col justify-center mb-1">
                            Mis Trabajadores
                        </h2>
                        <div className="flex flex-wrap w-full justify-center h-32 m-4">
                            {
                                //eslint-disable-next-line
                                workers?.map((worker: any, index: number) => (
                                    <div
                                        key={index}
                                        className="flex flex-wrap content-center justify-center w-32 h-32 hover:bg-[#F5F5F5] hover:shadow-sm hover:border-b-primaryColor hover:border-b-2">
                                        <img
                                            src={
                                                worker?.profile?.img?.url
                                                    ? worker?.profile?.img?.url
                                                    : worker?.profile?.img?.blob.data
                                                    ? `data:${worker.profile?.img?.blob.mimetype};base64,${worker?.profile?.img?.blob.data}`
                                                    : 'https://picsum.photos/200/300?random=1'
                                            }
                                            className="rounded-full w-20 h-20"
                                            alt=""
                                        />
                                        <p className="text-primaryColor text-center w-full">
                                            {worker?.profile?.firstName}
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            )}
        </IonPage>
    );
};
export default ProfileOwnerPage;
