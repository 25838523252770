import { IonContent, IonPage } from '@ionic/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MenuIcon, SettingsIcon } from '../../assets/icons';
import { Calendar, Header } from '../../components';
import { useSalonQuery } from '../../lib/dataClient/useSalonQuery';
import { Salon } from '../../lib/dataTypes/salon';
import { ScheduleArray } from '../../utils/schedule';
import { useAppointmentCreate } from '../../lib/dataClient/useAppointmentCreate';
import { useAppointmentQuery } from '../../lib/dataClient/useAppointmentQuery';
import { useSalonUpdate } from '../../lib/dataClient/useSalonUpdate';
import { ServicesPerSalon } from '../../lib/dataTypes/services';
import { getProfileClient } from '../../lib/getProfileClient';
import { FashProfile } from '../../lib/dataTypes/fashProfile';
import { useUserQuery } from '../../lib/dataClient/useUserQuery';
import { useIonToast } from '@ionic/react';
import Loader from '../../components/Loader';
import { findAvailableIntervals } from '../../utils/findAvailableIntervals';
interface Schedule {
    start: string;
    end: string;
}

const CreateAppointmentPage = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [salon, setSalon] = useState<Salon>();
    const { getSalonById } = useSalonQuery();
    const { id } = useParams<{ id: string }>();
    const [selectedService, setSelectedService] = useState<ServicesPerSalon[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedWorker, setSelectedWorker] = useState<string>('');
    const [hoursToShowBackup, setHoursToShowBackup] = useState<string[]>([]);
    const [hoursToShow, setHoursToShow] = useState<string[]>([]);
    const [selectedHour, setSelectedHour] = useState<string[]>([]);
    //eslint-disable-next-line
    const [workers, setWorkers] = useState<FashProfile[] | any>([]);
    const history = useHistory();
    const { getUserByEntioId } = useUserQuery();
    const appointmentCreate = useAppointmentCreate();
    const { getAppointmentsByStylist } = useAppointmentQuery();
    const { updateRegularClients } = useSalonUpdate();
    const profileClient = getProfileClient();
    const [toast] = useIonToast();

    const onError = async (error: string) => {
        await toast({
            color: 'warning',
            position: 'middle',
            message: error,
            duration: 2000,
            cssClass:
                'absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-red-500 text-white rounded-lg p-4',
        });
    };

    const salonData = async () => {
        try {
            const data = await getSalonById(id);
            setSalon(() => data);
        } catch (err) {
            console.log(err);
        }
    };
    const selectService = (service: ServicesPerSalon) => {
        const servicePrice = Number(service.price);
        let currentPrice: number;
        if (selectedService.some((e) => e.service._id === service.service._id)) {
            const serviceFiltered = selectedService.filter((e) => e.service._id !== service.service._id);
            setSelectedService(() => serviceFiltered);
            setHoursToShow(hoursToShowBackup);
            currentPrice = totalPrice - servicePrice;
            setTotalPrice(() => currentPrice);
        } else {
            setSelectedService(() => [...selectedService, service]);
            currentPrice = totalPrice + servicePrice;
            setTotalPrice(() => currentPrice);
        }
    };

    const getWorkers = async () => {
        if (salon) {
            const workersId = salon?.workers.map((worker) => worker.entioId);
            console.log(workersId);
            const profiles = await profileClient.getProfiles(workersId);
            //eslint-disable-next-line
            const workersArray: any = [];
            for (const data in profiles.data) {
                workersArray.push(profiles.data[data]);
            }
            setWorkers(() => workersArray);
        }
    };

    const salonInning = async () => {
        if (!selectedWorker || selectedDate.getHours() !== 0) {
            onError('Selecciona fecha y trabajador para mostrar los horarios');
            return;
        }
        const appointmentsArray = await getAppointmentsByStylist(id, selectedDate, selectedWorker);
        console.log(appointmentsArray);
        let filteredHours = [...ScheduleArray];
        const day = moment(selectedDate).format('dddd');
        let schedule: Schedule | undefined;
        day === 'Monday'
            ? (schedule = salon?.timetable.monday)
            : day === 'Tuesday'
            ? (schedule = salon?.timetable.tuesday)
            : day === 'Wednesday'
            ? (schedule = salon?.timetable.wednesday)
            : day === 'Thursday'
            ? (schedule = salon?.timetable.thursday)
            : day === 'Friday'
            ? (schedule = salon?.timetable.friday)
            : day === 'Saturday'
            ? (schedule = salon?.timetable.saturday)
            : (schedule = salon?.timetable.sunday);
        if (schedule) {
            const start = schedule.start;
            const indexStart = filteredHours.findIndex((e: string) =>
                e.startsWith('T') ? e.slice(1).startsWith(start) : e.startsWith(start)
            );
            const end = schedule.end;
            const indexEnd = filteredHours.findIndex((e: string) => e.endsWith(end));
            if (start === '00:00' && end === '00:00') {
                setHoursToShow(() => ['Cerrado']);
                setHoursToShowBackup(() => ['Cerrado']);
                return;
            }
            filteredHours = filteredHours.slice(indexStart, indexEnd + 1);
            if (moment(selectedDate).isBefore(moment().subtract(1, 'day'))) {
                filteredHours = ['Invalid date'];
                setHoursToShow(() => filteredHours);
                setHoursToShowBackup(() => filteredHours);
                return;
            }

            if (moment(selectedDate).hours() > 0) {
                filteredHours = ['Invalid hours'];
                setHoursToShow(() => filteredHours);
                setHoursToShowBackup(() => filteredHours);
                return;
            }
            if (moment(selectedDate).format('DD MMMM yy') === moment().format('DD MMMM yy')) {
                const time = moment().hours();
                filteredHours = filteredHours.filter((hour) =>
                    hour.includes('T')
                        ? Number(hour.substring(1, 3)) > time + 2
                        : Number(hour.substring(0, 2)) > time + 2
                );
                filteredHours.length === 0 && filteredHours.push('Invalid inning');
            }
            if (appointmentsArray && appointmentsArray.length) {
                for (let i = 0; i < filteredHours.length; i++) {
                    for (let j = 0; j < appointmentsArray.length; j++) {
                        for (let y = 0; y < appointmentsArray[j].time.length; y++) {
                            if (filteredHours[i] === appointmentsArray[j].time[y]) {
                                filteredHours[i] = 'T' + filteredHours[i];
                            }
                        }
                    }
                }
            }

            if (end && start) {
                setHoursToShow((prev) => filteredHours);
                setHoursToShowBackup((prev) => filteredHours);
            } else {
                setHoursToShow(() => []);
                setHoursToShowBackup(() => []);
            }
        }
    };

    const getSelectedWorker = async () => {
        const worker = await getUserByEntioId(selectedWorker);
        console.log(worker);
        if (worker) {
            return worker._id;
        }
    };
    const onSubmit = async () => {
        document.getElementById('btn-schedule')?.setAttribute('disabled', 'true');
        if (salon) {
            const clients = salon.regularClients.map((e) => e._id);
            const clientEntioId = await profileClient.getProfile();
            const clientId = await getUserByEntioId(clientEntioId.data.user.id);
            const data = {
                client: clientId._id,
                salon: salon._id,
                stylist: await getSelectedWorker(),
                service: selectedService.map((s) => s.service._id),
                servicePerSalon: selectedService,
                price: totalPrice.toString() + '.00',
                date: selectedDate,
                time: selectedHour,
            };
            const yesterday = moment().subtract(1, 'day');
            let error = '';
            !data.salon
                ? (error = 'No existe salon')
                : !data.client
                ? (error = 'Usted no esta registrado')
                : data.servicePerSalon.length === 0
                ? (error = 'Debes seleccionar un servicio')
                : !data.time
                ? (error = 'Debes seleccionar un turno')
                : hoursToShow.indexOf(data.time[0]) === -1
                ? (error = 'El horario elegido es incorrecto')
                : data.stylist === ''
                ? (error = 'Debes seleccionar un estilista')
                : !moment(data.date).isAfter(yesterday)
                ? (error = 'La fecha seleccionada no es correcta')
                : (error = '');
            if (error === '') {
                try {
                    const index = clients.indexOf(data.client);
                    if (index === -1) {
                        clients.push(data.client);
                        await updateRegularClients(id, clients);
                    }
                    const dataNewAppointment = await appointmentCreate(data);
                    salonInning();
                    history.push(`/clients/appointment/${dataNewAppointment._id}`);
                } catch (err) {
                    console.log(err);
                }
            } else {
                onError(error);
            }
        }
        document.getElementById('btn-schedule')?.setAttribute('disabled', 'false');
    };

    useEffect(() => {
        salonData();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedDate.toString().includes('00:00:00')) salonInning();
        //eslint-disable-next-line
    }, [selectedWorker]);
    useEffect(() => {
        getWorkers();
        // eslint-disable-next-line
    }, [salon]);
    useEffect(() => {
        if (selectedService.length && hoursToShowBackup.length) {
            let totalDuration = 0;
            selectedService.forEach((s) => {
                const [hours, minutes] = s.duration.split(':').map(Number);
                totalDuration += (hours * 60 + minutes) / 30;
            });
            const avalaibleHours = findAvailableIntervals(totalDuration, hoursToShowBackup);
            setHoursToShow(avalaibleHours);
            setSelectedHour([]);
        }
        // eslint-disable-next-line
    }, [selectedService, hoursToShowBackup]);

    return (
        <IonPage>
            <Header title="Nueva Cita" left={<MenuIcon />} right={<SettingsIcon />} menuRequire />
            <IonContent>
                {!salon ? (
                    <Loader />
                ) : (
                    <div className="flex flex-wrap flex-col items-center justify-center w-full mt-8 scrollbar-hidden">
                        <div className="text-center bg-secondaryColor my-2 py-2 px-4 gap-2 shadow-md w-11/12 mx-auto">
                            <h3 className="font-montserrat text-2xl text-primaryColor">{salon?.name}</h3>
                        </div>
                        <div className="text-center bg-secondaryColor my-2 py-2 px-4 gap-2 shadow-md w-11/12 mx-auto">
                            <h3 className="font-montserrat text-2xl text-primaryColor">Servicios</h3>
                        </div>

                        <div className="flex flex-wrap my-6 w-[95%] h-auto max-h-[35vh] p-1 justify-center gap-1 overflow-auto scrollbar-hidden">
                            {salon ? (
                                salon.services.map((service, index) => (
                                    <div
                                        key={index}
                                        onClick={() => selectService(service)}
                                        className={
                                            selectedService.some((e) => e.service._id === service.service._id)
                                                ? 'flex flex-wrap justify-center content-between items-center cursor-pointer w-[30%] h-[110px] bg-secondaryColor border-2 border-primaryColor rounded-xl m-1 p-1'
                                                : 'flex flex-wrap justify-center content-between items-center cursor-pointer w-[30%] h-[110px] bg-secondaryColor border-2 rounded-xl m-1 p-1'
                                        }>
                                        <div className="w-full">
                                            <h5 className="font-montserrat text-sm text-center">
                                                {service.service.name}
                                            </h5>
                                        </div>
                                        <div className="flex flex-col items-center w-full">
                                            <img
                                                src={`data:${service.service.img?.blob?.mimeType};base64,${service.service.img?.blob?.data}`}
                                                alt="img_services"
                                                className="w-8 h-8"
                                            />
                                            <p className="font-montserrat text-m text-center text-primaryColor">
                                                $ {service.price}
                                            </p>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No hay servicios disponibles</p>
                            )}
                        </div>

                        <div className="flex flex-wrap justify-center my-6">
                            <Calendar onChange={setSelectedDate} value={selectedDate} className="max-w-[360px]" />
                        </div>
                        <div className="text-center bg-secondaryColor my-2 py-2 px-4 gap-2 shadow-md w-11/12 mx-auto">
                            <h3 className="font-montserrat text-2xl text-primaryColor">Estilistas</h3>
                        </div>
                        <div className="flex justify-center items-center my-6 w-11/12 flex-wrap overflow-auto scrollbar-hidden h-[20vh] bg-secondaryColor">
                            {workers.map((worker: FashProfile, index: number) => (
                                <div
                                    key={index}
                                    onClick={() => setSelectedWorker(() => worker.user.id)}
                                    className={
                                        selectedWorker === worker.user.id
                                            ? 'flex flex-wrap justify-center content-between items-center w-[95px] h-[124px] cursor-pointer bg-secondaryColor border-2 border-primaryColor rounded-[4px] p-2 mx-2'
                                            : 'flex flex-wrap justify-center content-between items-center w-[95px] h-[124px] cursor-pointer bg-secondaryColor border-2 rounded-[4px] p-2 mx-2'
                                    }>
                                    <div className="flex justify-center w-full">
                                        <img
                                            src={
                                                worker?.profile.img?.url
                                                    ? worker?.profile.img?.url
                                                    : worker?.profile.img?.blob.data
                                                    ? `data:${worker.profile.img?.blob.mimetype};base64,${worker.profile.img?.blob.data}`
                                                    : 'https://picsum.photos/200/300?random=1'
                                            }
                                            alt="Not Found"
                                            className="rounded-full w-[80%] h-[60px]"
                                        />
                                    </div>
                                    <h5 className="font-montserrat text-sm text-center">
                                        {worker.profile?.firstName?.length > 10
                                            ? worker.profile?.firstName?.substring(0, 10) + '...'
                                            : worker.profile?.firstName}
                                    </h5>
                                </div>
                            ))}
                        </div>
                        <div className="my-6 flex flex-col items-center w-full">
                            <div className="flex flex-row w-full justify-around">
                                <button
                                    onClick={() => history.goBack()}
                                    className="font-montserrat py-1 px-6 border border-primaryColor rounded-3xl shadow-md">
                                    Cancelar
                                </button>
                                <button
                                    onClick={() => salonInning()}
                                    className="font-montserrat py-1 px-3 border border-primaryColor rounded-3xl shadow-md bg-primaryColor text-white ">
                                    Mostrar Horarios
                                </button>
                            </div>
                        </div>
                        {hoursToShow.length > 0 && (
                            <div className="flex flex-wrap justify-center">
                                <div className="text-center bg-secondaryColor w-full my-2 py-2 px-4 gap-2 shadow-md w-11/12 mx-auto">
                                    <h3 className="font-montserrat text-2xl text-primaryColor">Horarios disponibles</h3>
                                </div>
                                <div className="flex flex-wrap justify-center w-full my-6 max-w-[360px] gap-6">
                                    {hoursToShow.length > 0 && !hoursToShow[0].startsWith('I') ? (
                                        hoursToShow.map((hour, index) => (
                                            <button
                                                key={index}
                                                onClick={() => {
                                                    const totalHours = [hour];
                                                    let totalDuration = 0;
                                                    selectedService.forEach((s) => {
                                                        const [hours, minutes] = s.duration.split(':').map(Number);
                                                        totalDuration += (hours * 60 + minutes) / 30;
                                                    });
                                                    for (let i = 1; i < totalDuration; i++) {
                                                        totalHours.push(
                                                            hoursToShowBackup[hoursToShowBackup.indexOf(hour) + i]
                                                        );
                                                    }
                                                    setSelectedHour(totalHours);
                                                }}
                                                disabled={hour.startsWith('T')}
                                                className={
                                                    selectedHour.includes(hour) || selectedHour.includes(hour.slice(1))
                                                        ? 'flex justify-center items-center w-[45%] h-[38px] px-4 bg-primaryColor text-white rounded-xl font-monserrat text-xs'
                                                        : hour.startsWith('T') && !selectedHour.includes(hour.slice(1))
                                                        ? 'flex justify-center items-center w-[45%] h-[38px] px-4 outline outline-[1px] outline-primaryColor rounded-xl bg-gray-300 text-xs text-white'
                                                        : 'flex justify-center cursor-pointer items-center w-[45%] h-[38px] px-4 outline outline-[1px] rounded-xl text-xs'
                                                }>
                                                {hour.startsWith('T') ? hour.slice(1) : hour}
                                            </button>
                                        ))
                                    ) : (
                                        <div className="flex justify-center items-center max-w-[auto] h-[38px] px-4 ">
                                            <p className="font-monserrat text-red-400">
                                                {hoursToShow[0] === 'Invalid inning'
                                                    ? 'Sin turnos disponibles'
                                                    : hoursToShow[0] === 'Invalid date'
                                                    ? 'Fecha incorrecta'
                                                    : hoursToShow[0] === 'Invalid hours'
                                                    ? 'Seleccione una fecha'
                                                    : 'Cerrado'}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="flex flex-wrap justify-center w-full gap-12 mb-6">
                                    <button
                                        onClick={() => history.goBack()}
                                        className="font-montserrat py-1 px-6 border border-primaryColor rounded-3xl shadow-md">
                                        Cancelar
                                    </button>
                                    <button
                                        id="btn-schedule"
                                        onClick={onSubmit}
                                        className="font-montserrat py-1 px-6 border border-primaryColor rounded-3xl shadow-md bg-primaryColor text-white">
                                        Agendar
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </IonContent>
        </IonPage>
    );
};

export default CreateAppointmentPage;
