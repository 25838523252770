import React, { SVGProps } from 'react';

const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 10.667V8h24v2.667H4Zm0 6.666h24v-2.666H4v2.666ZM4 24h24v-2.667H4V24Z"
            fill="#82385E"
        />
    </svg>
);

export default MenuIcon;
