import React, { useEffect, useState } from 'react';
import { IonPage, IonContent, useIonToast } from '@ionic/react';
import { ClientDirectoryCardWithClose } from '../../../components';
import { CloseIcon } from '../../../assets/icons';
import { User } from '../../../lib/dataTypes/users';
import { useUserQuery } from '../../../lib/dataClient/useUserQuery';
import { useSalonUpdate } from '../../../lib/dataClient/useSalonUpdate';
import { Salon } from '../../../lib/dataTypes/salon';
import { getProfileClient } from '../../../lib/getProfileClient';
import { FashProfile } from '../../../lib/dataTypes/fashProfile';

interface Props {
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
    setSalon: React.Dispatch<React.SetStateAction<Salon | undefined>>;
    workers: Array<User>;
    edit?: boolean;
    salonId?: string;
}

const EditWorkersPage = (props: Props) => {
    const { updateWorkers } = useSalonUpdate();
    const { getUserByEntioId } = useUserQuery();
    const [userId, setUserID] = useState<string>('');
    const [userData, setUsersData] = useState<User>();
    const [workers, setWorkers] = useState<FashProfile[]>();
    //eslint-disable-next-line
    const [foundedUser, setFoundedUser] = useState<FashProfile | any>();
    const [toast] = useIonToast();
    const profileClient = getProfileClient();

    const onError = async (error: string) => {
        await toast({
            color: 'warning',
            position: 'middle',
            message: error,
            duration: 2000,
            cssClass:
                'absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-red-500 text-white rounded-lg p-4',
        });
    };

    const getWorkersProfiles = async () => {
        const workersProfiles = props.workers.map((worker) => worker.entioId);
        const response = await profileClient.getProfiles(workersProfiles);
        if (response.data) {
            //eslint-disable-next-line
            const workersArray: FashProfile[] | any = [];
            for (const data in response.data) {
                workersArray.push(response.data[data]);
            }
            setWorkers(() => workersArray);
        }
    };

    const addSalonWorkers = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setUserID(() => '');
        if (props.salonId) {
            const WorkersArray = [...props.workers];
            const idsWorkersCollection = WorkersArray.map((worker) => worker?._id);
            if (userData) {
                try {
                    if (!idsWorkersCollection.includes(userData._id)) {
                        idsWorkersCollection.push(userData._id);
                        const result = await updateWorkers(props.salonId, idsWorkersCollection);
                        if (result) {
                            props.setSalon(() => result);
                        }
                    }
                    setFoundedUser(() => undefined);
                    setUsersData(() => undefined);
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };

    const deleteSalonWorkers = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
        e.preventDefault();
        if (props.salonId) {
            const WorkersArray = props.workers.map((worker) => worker);
            const workers = WorkersArray.filter((worker) => worker.entioId !== id);
            try {
                if (!workers.some((worker) => worker.entioId === id)) {
                    const workersIds = workers.map((worker) => worker._id);
                    const result = await updateWorkers(props.salonId, workersIds);
                    if (result) {
                        props.setSalon(() => result);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getUserData = async () => {
        if (userId) {
            try {
                const result = await getUserByEntioId(userId);
                const profile = await profileClient.getProfiles([userId]);
                if (result) {
                    setUsersData(() => result);
                    for (const data in profile.data) {
                        setFoundedUser(() => profile.data[data]);
                    }
                } else {
                    onError('Debe ingresar un ID correcto');
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            onError('Debe ingresar un ID');
        }
    };

    useEffect(() => {
        getWorkersProfiles();
        //eslint-disable-next-line
    }, [props.workers]);

    return (
        <IonPage className="h-full w-full overflow-y-scroll font-montserrat">
            <IonContent>
                <div className="flex flex-row items-center justify-between bg-gray-100 w-full drop-shadow-md px-4 py-3">
                    <p className="flex flex-start text-primaryColor font-extrabold text-xl">Editar trabajadores</p>
                    <button onClick={() => props.setEdit(() => !props.edit)}>
                        <CloseIcon />
                    </button>
                </div>
                <div className="flex flex-col w-full items-center">
                    <div className="flex justify-around flex-row h-16 py-4 m-3 w-full">
                        <input
                            className="text-[18px] ml-3 outline outline-[1px] outline-primaryColor rounded-[4px] text-start px-1"
                            placeholder="Buscar"
                            value={userId}
                            onChange={(e) => {
                                setUsersData(() => undefined);
                                setUserID(() => e.target.value);
                            }}
                        />
                        <button
                            className="outline outline-[1px] outline-primaryColor py-1 px-1 rounded-[4px] h-full bg-primaryColor text-white"
                            onClick={(e) => {
                                setUsersData(() => undefined);
                                getUserData();
                                return;
                            }}>
                            Buscar
                        </button>
                    </div>
                    {foundedUser && (
                        <div className="flex flex-col w-[90%] justify-center items-center content-center">
                            <div className="flex flex-row w-full outline outline-[1px] outline-primaryColor mx-[5px] py-3 px-3 rounded-[6px]">
                                <img
                                    src="https://picsum.photos/200/300?random=1"
                                    className="w-20 h-20 my-1 rounded-full object-cover mr-2"
                                    alt="imagen de perfil"></img>
                                <div>
                                    <p>{foundedUser?.profile.firstName}</p>
                                    <p>{foundedUser?.user.email}</p>
                                    <p>{foundedUser?.user.phone}</p>
                                </div>
                            </div>

                            <button
                                className="outline outline-[1px] outline-primaryColor py-1 px-2 my-4 rounded-[4px] h-full bg-[#22c55e] text-white"
                                type="button"
                                onClick={(e) => addSalonWorkers(e)}>
                                Añadir
                            </button>
                        </div>
                    )}
                </div>
                <div className="w-[90%] border border-[1px] border-primaryColor mx-auto"></div>
                <div className="my-2">
                    {workers &&
                        workers.map((worker, i) => {
                            return (
                                <div key={i} className="mx-4">
                                    <ClientDirectoryCardWithClose
                                        image={'https://picsum.photos/200/300?random=1'}
                                        name={worker.profile.firstName}
                                        phone={worker.user.phone}
                                        email={worker.user.email}
                                        id={worker.user.id}
                                        deleteUser={deleteSalonWorkers}
                                    />
                                </div>
                            );
                        })}
                </div>
                <div className="flex justify-center items-center p-4">
                    <button
                        className="rounded-full bg-primaryColor py-1 px-5 text-white font-semibold "
                        onClick={() => props.setEdit(!props.edit)}>
                        Volver
                    </button>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default EditWorkersPage;
