import React, { useState } from 'react';
import { IonCardHeader, IonSpinner, useIonToast } from '@ionic/react';
import { EditIcon } from '../assets/icons';
import { Salon } from '../lib/dataTypes/salon';
import { useFileUpload } from '../lib/dataClient/useFileUpload';
import getFileUrl from '../lib/getFileUrl';
import { useSalonUpdate } from '../lib/dataClient/useSalonUpdate';

interface Props {
    salon?: Salon;
    setSalon: React.Dispatch<React.SetStateAction<Salon | undefined>>;
}

const SalonImageItem = (props: Props) => {
    const [img, setImg] = useState(props?.salon?.img?.url || '');
    const [uploadingFile, setUploadingFile] = useState(false);
    const uploadFile = useFileUpload();
    const [toast] = useIonToast();
    const { updateSalonImg } = useSalonUpdate();

    const onError = async (error: string) => {
        await toast({
            color: 'warning',
            position: 'middle',
            message: error,
            duration: 2000,
            cssClass:
                'absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-red-500 text-white rounded-lg p-4',
        });
    };

    async function uploadImg(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target?.files?.[0];
        if (!file?.type.includes('image')) {
            onError('El archivo debe ser una imagen.');
        } else {
            try {
                setUploadingFile(true);
                const uploadResult = await uploadFile(file);
                setImg(uploadResult.url);
            } catch (e) {
                onError('No se pudo cargar la imagen, intente nuevamente.');
            } finally {
                setUploadingFile(false);
            }
        }
    }

    async function onSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        try {
            if (props?.salon?._id) {
                const salonUpdated = await updateSalonImg({ id: props?.salon?._id, imgUrl: img });
                props.setSalon(salonUpdated);
            }
        } catch (e) {
            onError('Ocurrió un error, intente nuevamente.');
        }
    }

    return (
        <div className="mt-4 flex flex-col font-montserrat px-2 justify-center">
            <IonCardHeader className="navbar navbar-expand-lg shadow-md py-2 px-2 bg-gray-100 flex items-center justify-between">
                <div className="text-primaryColor text-[22px] px-2 font-montserrat">Imagen de salón</div>
                <label htmlFor="inputFile" className="relative cursor-pointer rounded-full bg-primaryColor p-1">
                    <EditIcon />
                </label>
                <input id="inputFile" type="file" accept="image/*" className="hidden" onChange={(e) => uploadImg(e)} />
            </IonCardHeader>
            <div className="flex justify-center flex-col w-full py-6 px-4">
                {uploadingFile ? (
                    <div className="m-auto">
                        <IonSpinner />
                    </div>
                ) : (
                    <img
                        src={img ? getFileUrl(img) : 'https://ciep.mx/wp-content/uploads/2019/09/placeholder.png'}
                        alt="salon"
                        className="max-h-[400px] m-auto"
                    />
                )}
                {props?.salon?.img?.url !== img && img !== '' && (
                    <div className="flex items-center justify-around col-span-5 mt-5 text-base mb-4 w-full">
                        <button
                            onClick={() => setImg(props?.salon?.img?.url || '')}
                            className="border border-primaryColor rounded-full px-5 bg-gray-200 drop-shadow-lg py-1 text-[18px]">
                            Cancelar
                        </button>
                        <button
                            onClick={(e) => onSubmit(e)}
                            className={`border bg-primaryColor text-gray-100 rounded-full px-5 drop-shadow-lg py-1 text-[18px]`}>
                            Guardar
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SalonImageItem;
