import { gql, useLazyQuery } from '@apollo/client';
export const useAppointmentQuery = () => {
    const [getAppointmentBySalonIdAndStylist] = useLazyQuery(
        gql`
            query getAppointments($id: ObjectId, $date: DateTime, $stylist: String) {
                appointments(query: { salon: { _id: $id }, date: $date, stylist: { entioId: $stylist } }) {
                    _id
                    service {
                        _id
                        name
                        img {
                            url
                            blob {
                                mimeType
                                data
                            }
                        }
                    }
                    servicePerSalon {
                        _id
                        duration
                    }
                    price
                    stylist {
                        _id
                        entioId
                    }
                    time
                    date
                    client {
                        _id
                        entioId
                    }
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
        }
    );
    const getAppointmentsByStylist = async (id: string, date: Date, stylist: string) => {
        try {
            const result = await getAppointmentBySalonIdAndStylist({ variables: { id, date, stylist } });
            console.log(result);
            return result.data?.appointments;
        } catch (err) {
            console.log(err);
        }
    };

    const [getAppointmentsByClient] = useLazyQuery(
        gql`
            query getAppointments($entioId: String) {
                appointments(query: { client: { entioId: $entioId } }) {
                    _id
                    service {
                        _id
                        name
                        img {
                            url
                            blob {
                                mimeType
                                data
                            }
                        }
                    }
                    servicePerSalon {
                        _id
                        duration
                    }
                    price
                    stylist {
                        _id
                        entioId
                    }
                    time
                    date
                    salon {
                        _id
                        name
                    }
                    client {
                        _id
                        entioId
                    }
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
        }
    );

    const getAppointmentByClientId = async (entioId: string) => {
        try {
            const result = await getAppointmentsByClient({ variables: { entioId } });
            console.log(result);
            return result.data.appointments;
        } catch (err) {
            console.log(err);
        }
    };

    const [getAppointmentBySalonId] = useLazyQuery(
        gql`
            query getAppointments($id: ObjectId, $date: DateTime) {
                appointments(query: { salon: { _id: $id }, date: $date }) {
                    _id
                    service {
                        _id
                        name
                    }
                    servicePerSalon {
                        _id
                        duration
                    }
                    price
                    stylist {
                        _id
                        entioId
                    }
                    time
                    date
                    client {
                        _id
                        entioId
                    }
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
        }
    );
    const getAppointmentsBySalonDate = async (id: string, date: Date) => {
        try {
            const result = await getAppointmentBySalonId({ variables: { id, date } });
            return result.data?.appointments;
        } catch (err) {
            console.log(err);
        }
    };

    const [getAllAppointmentsByStylist] = useLazyQuery(
        gql`
            query getAppointments($entioId: String) {
                appointments(query: { stylist: { entioId: $entioId } }) {
                    _id
                    service {
                        _id
                        name
                        img {
                            url
                            blob {
                                mimeType
                                data
                            }
                        }
                    }
                    servicePerSalon {
                        _id
                        duration
                    }
                    price
                    stylist {
                        _id
                        entioId
                    }
                    time
                    date
                    salon {
                        _id
                        name
                    }
                    client {
                        _id
                        entioId
                    }
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
        }
    );
    const getAppointmentsByStylistEntioId = async (entioId: string) => {
        console.log(entioId);
        try {
            const result = await getAllAppointmentsByStylist({ variables: { entioId } });
            console.log(result);
            return result.data?.appointments;
        } catch (err) {
            console.log(err);
        }
    };
    const [getAllAppointmentsFromSalon] = useLazyQuery(
        gql`
            query getAppointment($id: String!) {
                appointments(query: { salon: { owner: { entioId: $id } } }) {
                    _id
                    service {
                        _id
                        name
                    }
                    servicePerSalon {
                        _id
                        duration
                    }
                    price
                    stylist {
                        _id
                        entioId
                    }
                    salon {
                        _id
                        name
                        address
                    }
                    time
                    date
                    client {
                        _id
                        entioId
                    }
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
        }
    );

    const getAllAppointmentsBySalonOwner = async (id: string) => {
        try {
            const result = await getAllAppointmentsFromSalon({
                variables: { id: id },
            });
            console.log(result);
            return result.data?.appointments;
        } catch (err) {
            console.log(err);
        }
    };
    const [getAppointment] = useLazyQuery(
        gql`
            query getAppointment($id: ObjectId!) {
                appointment(query: { _id: $id }) {
                    _id
                    service {
                        _id
                        name
                    }
                    servicePerSalon {
                        _id
                        duration
                    }
                    price
                    stylist {
                        _id
                        entioId
                    }
                    salon {
                        _id
                        name
                        address
                    }
                    time
                    date
                    client {
                        _id
                        entioId
                    }
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
        }
    );

    const getAppointmentById = async (id: string) => {
        try {
            const result = await getAppointment({ variables: { id } });
            return result.data?.appointment;
        } catch (err) {
            console.log(err);
        }
    };

    return {
        getAppointmentBySalonId,
        getAppointmentByClientId,
        getAppointmentsByStylistEntioId,
        getAppointmentsByStylist,
        getAppointmentsBySalonDate,
        getAppointmentById,
        getAllAppointmentsBySalonOwner,
    };
};
