import React, { useEffect, useState } from 'react';
import { EditIcon, LogoSmall, InfoIcon } from '../../assets/icons/index';
import { Link } from 'react-router-dom';
import { useIonToast } from '@ionic/react';
import getAuthenticationClient from '../../lib/getAuthenticationClient';
import getRealmUser from '../../lib/getRealmUser';
import validateEmail from '../../lib/validateEmail';
import { useFileUpload } from '../../lib/dataClient/useFileUpload';
import { ImageFile } from '../../lib/dataTypes/file';
import getFileUrl from '../../lib/getFileUrl';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { login } from '../../reducers/AuthReducer';
import { UserDataResult } from 'entio-sdk';

const CreateAccountPage = () => {
    const history = useHistory();
    const [toast] = useIonToast();
    const [uploadingFile, setUploadingFile] = useState(false);
    const uploadFile = useFileUpload();
    const [files, setFiles] = useState<ImageFile[]>([]);
    const [productUrl, setProductUrl] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const [dataClient, setDataClient] = useState({
        email: '',
        username: '',
        phone: '',
        password: '',
        profile: {
            country: '',
            birthDate: '',
        },
    });
    const onError = async (error: string) => {
        await toast({
            color: 'warning',
            position: 'top',
            message: error,
            duration: 2000,
            cssClass:
                'absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-red-500 text-white rounded-lg p-4',
        });
    };
    const onLoginError = async (error: string) => {
        await toast({
            color: 'danger',
            position: 'top',
            message: error,
            duration: 2000,
            cssClass:
                'absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-red-500 text-white rounded-lg p-4',
        });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const uploadProduct = async (file: any) => {
        setUploadingFile(true);
        const uploadResult = await uploadFile(file);

        setUploadingFile(false);
        if (!uploadResult) {
            console.warn('An error occurred while uploading the file');
        }

        if (!uploadResult.url) {
            onError('Seleccione una imagen');
        } else {
            setFiles([...files, { type: 'IMAGE', url: uploadResult.url }]);
            setProductUrl([...productUrl, uploadResult.url]);
        }
    };

    console.log('archivos cargados', files);
    console.log('url de archivos', productUrl);
    //eslint-disable-next-line
    const onLoginSuccess = async (data: UserDataResult<any>) => {
        const sessionState = {
            token: data.token,
            user: data.user,
            profile: data.profile,
            rol: data.profile.rol,
        };
        dispatch(login(sessionState));
    };
    function handleChangue(e: React.ChangeEvent<HTMLInputElement>) {
        e.target.id === 'email' || e.target.id === 'username' || e.target.id === 'phone' || e.target.id === 'password'
            ? setDataClient({
                  ...dataClient,
                  [e.target.id]: e.target.value,
              })
            : setDataClient({
                  ...dataClient,
                  profile: {
                      ...dataClient.profile,
                      [e.target.id]: e.target.value,
                  },
              });
    }
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (dataClient.username.trim() === '') {
            onError('El campo Nombre no puede ir vacío').then();
            return;
        }
        //eslint-disable-next-line
        if (!/^[\w'\-,.]*[^0-9_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/g.test(dataClient.username)) {
            onError('Ingresa un Nombre válido').then();
            return;
        }
        if (dataClient.email.trim() === '') {
            onError('El campo email no puede ir vacío').then();
            return;
        }
        if (!validateEmail(dataClient.email)) {
            onError('Ingresa un email válido').then();
            return;
        }
        if (dataClient.password?.length < 8) {
            onError('La contraseña debe ser al menos de 8 caracteres').then();
            return;
        }
        if (dataClient.phone.trim() === '') {
            onError('El campo Teléfono no puede ir vacío').then();
            return;
        }
        try {
            document.getElementById('btn-create-account')?.setAttribute('disabled', 'true');
            const trimmedName = dataClient.username.replace(/  +/g, ' ').trim();
            const trimmedEmail = dataClient.email.trim();
            const trimmedPhoneNumber = dataClient.phone.replace(/ /g, '');
            const countryCodePhoneNumber = trimmedPhoneNumber.startsWith('+')
                ? trimmedPhoneNumber
                : `+52${trimmedPhoneNumber}`;
            const signUpResponse = await getAuthenticationClient().signUp({
                email: trimmedEmail,
                password: dataClient.password,
                username: trimmedName,
                phone: countryCodePhoneNumber,
                profile: {
                    country: dataClient.profile.country,
                    firstName: trimmedName,
                    birthDate: dataClient.profile.birthDate,
                    img: {
                        url: `${getFileUrl(productUrl[0])}`,
                        blob: {
                            mimetype: '',
                            data: '',
                        },
                    },
                    role: ['FASH.CLIENT', 'FASH.STYLIST'],
                },
            });
            if (!signUpResponse.success) {
                onLoginError(signUpResponse.message).then();
                document.getElementById('btn-create-account')?.setAttribute('disabled', 'false');
                return;
            }
            onLoginSuccess(signUpResponse.data).then();
            localStorage.setItem('token', signUpResponse.data.token);
            await getRealmUser();
            document.getElementById('btn-create-account')?.setAttribute('disabled', 'false');
            history.push('/stylist/profile');
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        console.log(dataClient);
    }, [dataClient]);
    return (
        <div className="flex flex-col h-full justify-start overflow-scroll">
            <div className="flex justify-between w-5/6 self-center mt-4">
                <LogoSmall />
                <button>
                    <InfoIcon />
                </button>
            </div>
            <div className="flex flex-col justify-center items-center mt-8">
                <h2 className="text-[24px] mb-4">Bienvenido</h2>
                <div className="w-[150px] h-[150px] flex flex-col items-center my-5 relative">
                    <label
                        htmlFor="uploadFile"
                        className="absolute top-0 right-0 z-10 rounded-full bg-primaryColor p-2">
                        <EditIcon />
                    </label>
                    {getFileUrl(productUrl[0]) ? (
                        <img
                            className="rounded-full shadow-md shadow-gray-500"
                            alt="profile"
                            src={getFileUrl(productUrl[0])}
                        />
                    ) : (
                        <img
                            className=" rounded-full shadow-md shadow-gray-500"
                            src={'https://cdn-icons-png.flaticon.com/512/847/847969.png'}
                            alt="profile"
                        />
                    )}
                </div>

                <input
                    id="uploadFile"
                    hidden
                    className="flex w-5/6 self-center mt-4 mb-10"
                    type="file"
                    accept="image/*"
                    name="uploadFile"
                    disabled={uploadingFile}
                    onChange={(e) => {
                        const file = e?.target?.files?.[0];
                        uploadProduct(file).then();
                    }}
                />
            </div>
            <form onSubmit={handleSubmit} className="flex flex-col items-center justify-around">
                <div className="flex flex-col w-4/5 justify-start focus:outline-none">
                    <p className="relative pl-2 pr-2 left-2 top-3 bg-white self-start text-[18px]">Nombre</p>
                    <input
                        className="outline outline-gray-400 rounded-[4px] outline-1 focus:outline-primaryColor h-[51px] focus:text-primaryColor pl-2"
                        type="text"
                        id="username"
                        onChange={handleChangue}
                    />
                </div>
                <div className="flex flex-col w-4/5 justify-start focus:outline-none">
                    <p className="relative pl-2 pr-2 left-2 top-3 bg-white self-start text-[18px] focus:text-primaryColor">
                        Correo
                    </p>
                    <input
                        className="outline pl-2 outline-gray-400 rounded-[4px] outline-1 focus:outline-primaryColor h-[51px] focus:text-primaryColor"
                        type="text"
                        id="email"
                        onChange={handleChangue}
                    />
                </div>
                <div className="flex flex-col w-4/5 justify-start focus:outline-none">
                    <p className="relative pl-2 pr-2 left-2 top-3 bg-white self-start text-[18px] focus:text-primaryColor">
                        Telefóno
                    </p>
                    <input
                        className="outline pl-2 outline-gray-400 rounded-[4px] outline-1 focus:outline-primaryColor h-[51px] focus:text-primaryColor"
                        type="text"
                        id="phone"
                        onChange={handleChangue}
                    />
                </div>
                <div className="flex flex-col w-4/5 justify-start focus:outline-none">
                    <p className="relative pl-2 pr-2 left-2 top-3 bg-white self-start text-[18px] focus:text-primaryColor">
                        Contraseña
                    </p>
                    <input
                        className="outline pl-2 outline-gray-400 rounded-[4px] outline-1 focus:outline-primaryColor h-[51px] focus:text-primaryColor"
                        type="password"
                        id="password"
                        onChange={handleChangue}
                    />
                </div>
                <div className="flex flex-col rounded-[4px] w-4/5 justify-start">
                    <p className="relative pl-2 pr-2 left-2 top-3 bg-white self-start text-[18px] focus:text-primaryColor">
                        País y Ciudad
                    </p>
                    <input
                        className="rounded-[4px] flex pl-2 flex-col outline outline-1 outline-gray-400 focus:outline-primaryColor h-[51px] focus:text-primaryColor"
                        type="text"
                        id="country"
                        onChange={handleChangue}
                    />
                </div>
                <div className="flex flex-col rounded-[4px] w-4/5 justify-start">
                    <p className="relative pl-2 pr-2 left-2 top-3 bg-white self-start text-[18px] focus:text-primaryColor">
                        Cumpleaños *
                    </p>
                    <input
                        type="date"
                        className="rounded-[4px] flex pl-2 outline outline-1 outline-gray-400 focus:outline-primaryColor h-[51px] focus:text-primaryColor"
                        id="birthDate"
                        onChange={handleChangue}
                    />
                </div>
                <button
                    id="btn-create-account"
                    type="submit"
                    className="rounded-full bg-primaryColor p-3 pr-10 pl-10 text-white font-medium m-10 mb-6 text-[20px] shadow-gray-500 shadow-md">
                    Crear Cuenta
                </button>
            </form>
            <div className="self-center text-[18px] mb-8 text-center sm:flex">
                <p>Ya tienes una cuenta?</p>
                <Link to="/" className="text-yellow-500 underline">
                    Inicia Sesión
                </Link>
            </div>
        </div>
    );
};

export default CreateAccountPage;
