import React, { useEffect, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { MenuIcon } from '../../assets/icons';
import { ServicesSalon, InformationSalon, Header } from '../../components';
import { useSalonQuery } from '../../lib/dataClient/useSalonQuery';
import { useParams } from 'react-router-dom';
import { Salon } from '../../lib/dataTypes/salon';
import Loader from '../../components/Loader';
const SalonPage = () => {
    const { getSalonById } = useSalonQuery();
    const [salon, setSalon] = useState<Salon>();
    const { id } = useParams<{ id: string }>();

    const salonData = async () => {
        try {
            const data = await getSalonById(id);
            setSalon(() => data);
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        salonData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return (
        <IonPage className="h-full w-full overflow-y-scroll">
            {!salon ? (
                <Loader />
            ) : (
                <IonContent>
                    <Header
                        title="Salón"
                        left={<MenuIcon />}
                        right={
                            <img
                                src="https://xsgames.co/randomusers/avatar.php?g=female"
                                alt="profile"
                                className="rounded-full h-[5vh]"
                            />
                        }
                    />
                    <div className="w-auto h-auto border-2 my-7 mx-3 border-primaryColor rounded-3xl bg-gray-100">
                        {salon && <InformationSalon salon={salon} />}
                        {salon && <ServicesSalon salon={salon} />}
                        <div className="w-full flex flex-row justify-center items-center p-3">
                            <a
                                href={`/clients/createappointment/${salon?._id}`}
                                className="rounded-full bg-primaryColor py-1 px-6 mt-2 mb-4 font-semibold text-[18px] text-white tracking-wider hover:drop-shadow-lg">
                                Agendar cita
                            </a>
                        </div>
                    </div>
                </IonContent>
            )}
        </IonPage>
    );
};

export default SalonPage;
