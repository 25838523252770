import React, { useState } from 'react';
import { IonPage, IonContent, useIonToast } from '@ionic/react';
import { Header } from '../../components';
import getAuthenticationClient from '../../lib/getAuthenticationClient';
import getRealmUser from '../../lib/getRealmUser';
import validateEmail from '../../lib/validateEmail';

//icons
import { MenuIcon, EditIcon } from '../../assets/icons';

const AddClientPage = () => {
    const [toast] = useIonToast();
    const [dataClient, setDataClient] = useState({
        email: '',
        username: '',
        phone: '',
        password: '12345678',
        profile: {
            preferredStylist: '',
            requestedServices: '',
            otherDetails: '',
        },
    });
    const onError = async (error: string) => {
        await toast({
            color: 'warning',
            position: 'top',
            message: error,
            duration: 2000,
            cssClass:
                'absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-red-500 text-white rounded-lg p-4',
        });
    };
    const onLoginError = async (error: string) => {
        await toast({
            color: 'danger',
            position: 'top',
            message: error,
            duration: 2000,
            cssClass:
                'absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-red-500 text-white rounded-lg p-4',
        });
    };
    function handleChangue(e: React.ChangeEvent<HTMLInputElement>) {
        e.target.id === 'email' || e.target.id === 'username' || e.target.id === 'phone'
            ? setDataClient({
                  ...dataClient,
                  [e.target.id]: e.target.value,
              })
            : setDataClient({
                  ...dataClient,
                  profile: {
                      ...dataClient.profile,
                      [e.target.id]: e.target.value,
                  },
              });
    }
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (dataClient.username.trim() === '') {
            onError('El campo Nombre no puede ir vacío').then();
            return;
        }
        //eslint-disable-next-line
        if (!/^[\w'\-,.]*[^0-9_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/g.test(dataClient.username)) {
            onError('Ingresa un Nombre válido').then();
            return;
        }
        if (dataClient.email.trim() === '') {
            onError('El campo email no puede ir vacío').then();
            return;
        }
        if (!validateEmail(dataClient.email)) {
            onError('Ingresa un email válido').then();
            return;
        }
        if (dataClient.password?.length < 8) {
            onError('La contraseña debe ser al menos de 8 caracteres').then();
            return;
        }
        if (dataClient.phone.trim() === '') {
            onError('El campo Teléfono no puede ir vacío').then();
            return;
        }
        try {
            const trimmedName = dataClient.username.replace(/  +/g, ' ').trim();
            const trimmedEmail = dataClient.email.trim();
            const trimmedPhoneNumber = dataClient.phone.replace(/ /g, '');
            const countryCodePhoneNumber = trimmedPhoneNumber.startsWith('+')
                ? trimmedPhoneNumber
                : `+52${trimmedPhoneNumber}`;
            const signUpResponse = await getAuthenticationClient().signUp({
                email: trimmedEmail,
                password: dataClient.password,
                username: trimmedName,
                phone: countryCodePhoneNumber,
            });
            if (!signUpResponse.success) {
                onLoginError(signUpResponse.message).then();
                return;
            }
            const realmUser = await getRealmUser();
            console.log('signUpResponse: ', signUpResponse);
            console.log('realmUser: ', realmUser);
            //history.push('/owner/clients');
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <IonPage className="h-full w-full overflow-y-scroll">
            <Header
                title="Agregar Cliente"
                right={
                    <img
                        src="https://xsgames.co/randomusers/avatar.php?g=female"
                        alt="profile"
                        className="rounded-full h-[5vh]"
                    />
                }
                left={<MenuIcon />}
                menuRequire
            />
            <IonContent>
                <div className="relative text-center py-7">
                    <button className="absolute text-center rounded-full bg-primaryColor w-auto  ml-7 p-1 hover:shadow-lg">
                        <EditIcon />
                    </button>
                    <img
                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                        className="rounded-full h-24 w-24 mb-1 mx-auto"
                        alt="Avatar"
                    />
                </div>
                <form
                    onSubmit={handleSubmit}
                    className="container relative mx-auto w-full pb-8 h-auto flex justify-center flex-col items-center p-2">
                    <div className="flex flex-col w-11/12 mb-6">
                        <div className="relative">
                            <div className="absolute -bottom-3 left-2">
                                <label htmlFor="username" className="text-[#82385E] bg-white px-1">
                                    Nombre
                                </label>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <input
                                type="text"
                                name="username"
                                id="username"
                                className="border h-12 rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full"
                                onChange={handleChangue}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-11/12 mb-6">
                        <div className="relative">
                            <div className="absolute -bottom-3 left-2">
                                <label htmlFor="userName" className="bg-white px-1 text-primaryColor">
                                    Correo
                                </label>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <input
                                type="text"
                                name="email"
                                id="email"
                                className="border h-12 rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full"
                                onChange={handleChangue}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-11/12 mb-6">
                        <div className="relative">
                            <div className="absolute -bottom-3 left-2">
                                <label className="text-[#82385E] bg-white px-1">Teléfono</label>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <input
                                type="text"
                                name="phone"
                                id="phone"
                                className="border h-12 rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full"
                                onChange={handleChangue}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-11/12 mb-6">
                        <div className="relative">
                            <div className="absolute -bottom-3 left-2">
                                <label className="text-[#82385E] bg-white px-1">Estilista de preferencia</label>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <select
                                id="preferredStylist"
                                onChange={(e) =>
                                    setDataClient({
                                        ...dataClient,
                                        profile: {
                                            ...dataClient.profile,
                                            [e.target.id]: e.target.value,
                                        },
                                    })
                                }
                                className="flex flex-row justify-center items-center border rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full bg-white h-12">
                                <option className="sm:text-sm md:text-sm">opcion1</option>
                                <option className="sm:text-sm md:text-sm">opcion2</option>
                                <option className="sm:text-sm md:text-sm">opcion3</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-col w-11/12 mb-6">
                        <div className="relative">
                            <div className="absolute -bottom-3 left-2">
                                <label className="text-[#82385E] bg-white px-1">Servicios mas solicitados</label>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <select
                                id="requestedServices"
                                onChange={(e) =>
                                    setDataClient({
                                        ...dataClient,
                                        profile: {
                                            ...dataClient.profile,
                                            [e.target.id]: e.target.value,
                                        },
                                    })
                                }
                                className="flex flex-row justify-center items-center h-12 border rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full bg-white">
                                <option className="sm:text-sm md:text-sm">opcion1</option>
                                <option className="sm:text-sm md:text-sm">opcion2</option>
                                <option className="sm:text-sm md:text-sm">opcion3</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-col w-11/12 mb-8">
                        <div className="relative">
                            <div className="absolute -bottom-3 left-2">
                                <label htmlFor="otherDetails" className="text-[#82385E] bg-white px-1">
                                    Otros detalles
                                </label>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <input
                                type="text"
                                name="otherDetails"
                                id="otherDetails"
                                onChange={handleChangue}
                                className="border rounded-md border-slate-400 focus:border-primaryColor focus:border-2 focus:outline-none h-12 px-2 pt-2 pb-1 w-full"
                            />
                        </div>
                    </div>
                    <div className="relative w-11/12">
                        <div className="flex flex-row justify-evenly">
                            <a
                                href="/owner/clients"
                                className="w-max bg-[#f5f5f5]  mx-1 rounded-full px-6 py-2 border border-primaryColor text-black hover:drop-shadow-xl">
                                Cancelar
                            </a>
                            <button
                                type="submit"
                                className="w-max bg-primaryColor border-white border mx-1 text-white rounded-full px-6 py-2 hover:drop-shadow-xl">
                                Guardar
                            </button>
                        </div>
                    </div>
                </form>
            </IonContent>
        </IonPage>
    );
};

export default AddClientPage;
