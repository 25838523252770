import { gql } from '@apollo/client';
export const getAllServicesQuery = gql`
    query getAllServices {
        services {
            _id
            description
            name
            img {
                url
                blob {
                    mimeType
                    data
                }
            }
        }
    }
`;
