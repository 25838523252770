import React, { useEffect, useState } from 'react';
import { timeGenerator } from '../utils/timeGenerator';
import { TimeTable } from './DaysHoursItem';

interface Props {
    time: string;
    day: string;
    tradingTime: string;
    setData: React.Dispatch<React.SetStateAction<TimeTable>>;
    setDayToEvaluate: React.Dispatch<React.SetStateAction<string>>;
}

const TimeSelect = (props: Props) => {
    const { day, tradingTime, setData, setDayToEvaluate } = props;
    const filteredHours = timeGenerator('hours');
    const hours = props.tradingTime === 'end' ? filteredHours?.filter((e) => e !== '00') : timeGenerator('hours');
    const mins = ['00', '30'];

    const [hour, setHour] = useState(props.time.split(':')[0] || '00');
    const [min, setMin] = useState(props.time.split(':')[1] || '00');
    const [fullTime, setFullTime] = useState(``);

    const settingTime = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { target } = event;
        if (target.dataset.time === 'hours') {
            setHour(() => target.value);
        } else if (target.dataset.time === 'mins') {
            setMin(() => target.value);
        }
        setDayToEvaluate(() => day);
    };

    useEffect(() => {
        if (tradingTime === 'start') {
            setData(
                (prevState) =>
                    (prevState = {
                        ...prevState,
                        [day as keyof TimeTable]: { ...prevState[day as keyof TimeTable], start: fullTime },
                    })
            );
        } else if (tradingTime === 'end') {
            setData(
                (prevState) =>
                    (prevState = {
                        ...prevState,
                        [day as keyof TimeTable]: { ...prevState[day as keyof TimeTable], end: fullTime },
                    })
            );
        }
        //eslint-disable-next-line
    }, [fullTime]);

    useEffect(() => {
        setFullTime(() => `${hour}:${min}`);
        //eslint-disable-next-line
    }, [hour, min]);
    return (
        <div className="flex justify-around outline-1 outline text-xs mt-1">
            <select data-day={day} data-time={'hours'} onChange={(e) => settingTime(e)} value={hour}>
                {hours?.map((hour, i) => (
                    <option key={i} value={hour}>
                        {hour}
                    </option>
                ))}
            </select>
            :
            <select data-day={day} data-time={'mins'} onChange={(e) => settingTime(e)} value={min}>
                {mins?.map((min, i) => (
                    <option key={i} value={min}>
                        {min}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default TimeSelect;
