import React from 'react';

const StylistNumIcon = () => {
    return (
        <div className="p-2">
            <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16 2.5H11.82C11.4 1.34 10.3 0.5 9 0.5C7.7 0.5 6.6 1.34 6.18 2.5H2C0.9 2.5 0 3.4 0 4.5V18.5C0 19.6 0.9 20.5 2 20.5H16C17.1 20.5 18 19.6 18 18.5V4.5C18 3.4 17.1 2.5 16 2.5ZM9 2.5C9.55 2.5 10 2.95 10 3.5C10 4.05 9.55 4.5 9 4.5C8.45 4.5 8 4.05 8 3.5C8 2.95 8.45 2.5 9 2.5ZM9 6.5C10.66 6.5 12 7.84 12 9.5C12 11.16 10.66 12.5 9 12.5C7.34 12.5 6 11.16 6 9.5C6 7.84 7.34 6.5 9 6.5ZM15 18.5H3V17.1C3 15.1 7 14 9 14C11 14 15 15.1 15 17.1V18.5Z"
                    fill="#82385E"
                />
            </svg>
        </div>
    );
};

export default StylistNumIcon;
