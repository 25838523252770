import * as Realm from 'realm-web';

export const appId = process.env.REACT_APP_REALM_APP_ID || 'fashadmin-dev-lmwdn';
const getRealmUser: () => Promise<Realm.User | undefined> = async () => {
    const app: Realm.App = new Realm.App({ id: appId });
    const token = localStorage.getItem('token');

    const { currentUser } = app;
    if (currentUser?.isLoggedIn) {
        const isToken = !!currentUser.identities?.find((identity) => identity.providerType === 'custom-token');
        const isAnon = !!currentUser.identities?.find((identity) => identity.providerType === 'anon-user');

        if ((token && isToken) || (!token && isAnon)) {
            return currentUser;
        }
    }

    const credentials = (token && Realm.Credentials.jwt(token)) || Realm.Credentials.anonymous();
    try {
        // Authenticate the user
        const user: Realm.User = await app.logIn(credentials, true);
        // `App.currentUser` updates to match the logged in user
        return user;
    } catch (err) {
        console.error('Failed to log in', err);
    }

    return undefined;
};

export default getRealmUser;
