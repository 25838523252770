import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export interface RegistrationApplicationState {
    id: string;
    status: string;
}
const getItem = (key: string) => localStorage.getItem(key) || '';

export const loadInitialState = (): RegistrationApplicationState => {
    const id = getItem('registrationApplicationId');
    const status = getItem('registrationApplicationStatus');
    return {
        id,
        status,
    };
};

const initialState = loadInitialState();

export const registrationApplicationSlice = createSlice({
    name: 'registrationApplication',
    initialState,
    reducers: {
        onRegistrationApplicationChanged: (
            state: RegistrationApplicationState,
            action: PayloadAction<{ _id: string; status: string }>
        ) => {
            const { _id, status } = action.payload;
            if (!_id && !status) {
                Object.assign(state, loadInitialState());
            }

            if (_id) {
                localStorage.setItem('registrationApplicationId', _id);
                Object.assign(state, { id: _id });
            }

            if (status) {
                localStorage.setItem('registrationApplicationStatus', status);
                Object.assign(state, { status });
            }
        },
    },
});

export const { onRegistrationApplicationChanged } = registrationApplicationSlice.actions;

export const getRegistrationApplicationInfo = (state: RootState) => state.registrationApplication;

export default registrationApplicationSlice.reducer;
