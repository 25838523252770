import React from 'react';

const CloseIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9998 0.666687C6.6265 0.666687 0.666504 6.62669 0.666504 14C0.666504 21.3734 6.6265 27.3334 13.9998 27.3334C21.3732 27.3334 27.3332 21.3734 27.3332 14C27.3332 6.62669 21.3732 0.666687 13.9998 0.666687ZM13.9998 24.6667C8.11984 24.6667 3.33317 19.88 3.33317 14C3.33317 8.12002 8.11984 3.33335 13.9998 3.33335C19.8798 3.33335 24.6665 8.12002 24.6665 14C24.6665 19.88 19.8798 24.6667 13.9998 24.6667ZM13.9998 12.12L18.7865 7.33335L20.6665 9.21335L15.8798 14L20.6665 18.7867L18.7865 20.6667L13.9998 15.88L9.21317 20.6667L7.33317 18.7867L12.1198 14L7.33317 9.21335L9.21317 7.33335L13.9998 12.12Z"
                fill="#82385E"
            />
        </svg>
    );
};

export default CloseIcon;
