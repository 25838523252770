import React from 'react';

const CallIcon = () => {
    return (
        <>
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.3125 2C4.375 2.89 4.53125 3.76 4.78125 4.59L3.53125 5.79C3.10417 4.59 2.83333 3.32 2.73958 2H4.3125ZM14.5833 14.02C15.4687 14.26 16.375 14.41 17.2917 14.47V15.96C15.9167 15.87 14.5937 15.61 13.3333 15.21L14.5833 14.02ZM5.3125 0H1.66667C1.09375 0 0.625 0.45 0.625 1C0.625 10.39 8.55208 18 18.3333 18C18.9062 18 19.375 17.55 19.375 17V13.51C19.375 12.96 18.9062 12.51 18.3333 12.51C17.0417 12.51 15.7812 12.31 14.6146 11.94C14.5104 11.9 14.3958 11.89 14.2917 11.89C14.0208 11.89 13.7604 11.99 13.5521 12.18L11.2604 14.38C8.3125 12.93 5.89583 10.62 4.39583 7.79L6.6875 5.59C6.97917 5.31 7.0625 4.92 6.94792 4.57C6.5625 3.45 6.35417 2.25 6.35417 1C6.35417 0.45 5.88542 0 5.3125 0Z"
                    fill="#82385E"
                />
            </svg>
        </>
    );
};

export default CallIcon;
