interface Tt {
    friday: D;
    monday: D;
    saturday: D;
    sunday: D;
    thursday: D;
    tuesday: D;
    wednesday: D;
}
interface D {
    end: string;
    start: string;
}

const getMinTime = function (workweek: Tt) {
    type Key = keyof typeof workweek;
    let minTime = '';
    const days: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    for (let i = 0; i < days.length; i++) {
        if (workweek[days[i] as Key].start !== '' && workweek[days[i] as Key].end !== '') {
            if (minTime === '') {
                minTime = workweek[days[i] as Key].start;
            } else if (Number(minTime.slice(0, 2)) > Number(workweek[days[i] as Key].start.slice(0, 2))) {
                minTime = workweek[days[i] as Key].start;
            } else if (Number(minTime.slice(0, 2)) === Number(workweek[days[i] as Key].start.slice(0, 2))) {
                if (Number(minTime.slice(3, 5)) > Number(workweek[days[i] as Key].start.slice(3, 5))) {
                    minTime = workweek[days[i] as Key].start;
                } else {
                    continue;
                }
            }
        } else {
            continue;
        }
    }
    return minTime;
};

export { getMinTime };
