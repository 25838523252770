import compressFile from '../compressFile';
import getRealmUser from '../getRealmUser';
import readFileAsBase64 from '../readFileAsBase64';

export const useFileUpload = () => {
    return async (file?: File) => {
        console.log('Uploading file...');
        if (!file) {
            console.log('No file to upload');
            return;
        }

        /**
         * If the file is an image, it will be compressed. It will be undefined otherwise
         */
        const compressedFile = await compressFile(file);

        /**
         * The file to be uploaded. If the file was unable to be compressed it'll use the original file instead.
         */
        const uploadFile = compressedFile || file;
        const base64File = await readFileAsBase64(uploadFile);
        const fileName = file.name;
        const realmUser = await getRealmUser();

        return realmUser?.functions?.uploadFile(base64File, fileName);
    };
};
