import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';

interface Props {
    component: React.ComponentType<React.ReactElement>;
    path: string;
    role: string;
}

const ProtectedRoute = ({ component: Component, ...otherProps }: Props) => {
    const currentProfile = JSON.parse(localStorage.getItem('currentProfile') || '{}');
    const history = useHistory();
    function validateSession() {
        if (!currentProfile.role) {
            history.push('/');
        } else if (!currentProfile?.role?.includes(otherProps.role)) {
            if (currentProfile?.role?.includes('FASH.OWNER')) {
                history.push('/owner/profile');
            } else if (currentProfile?.role?.includes('FASH.STYLIST')) {
                history.push('/stylist/profile');
            } else {
                history.push('/clients/profile');
            }
        }
    }
    useEffect(() => {
        validateSession();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>{currentProfile?.role?.includes(otherProps.role) ? <Route component={Component} {...otherProps} /> : null}</>
    );
};

export default ProtectedRoute;
