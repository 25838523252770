import React, { useEffect, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { CalendarIcon, ClockIcon, DollarIcon, MenuIcon, PingIcon, SeatedUserIcon } from '../../assets/icons';
import { Header } from '../../components';
import { useAppointmentQuery } from '../../lib/dataClient/useAppointmentQuery';
import { useParams } from 'react-router-dom';
import { Appointment } from '../../lib/dataTypes/appointments';
import moment from 'moment';
import { getProfileClient } from '../../lib/getProfileClient';
import { FashProfile } from '../../lib/dataTypes/fashProfile';
const ScheduleAppointmentPage = () => {
    const { getAppointmentById } = useAppointmentQuery();
    const { id } = useParams<{ id: string }>();
    const [appointment, setAppointment] = useState<Appointment>();
    const [client, setClient] = useState<FashProfile>();
    //eslint-disable-next-line
    const [stylist, setStylist] = useState<FashProfile[] | any>();
    const profileClient = getProfileClient();
    const getAppointment = async () => {
        try {
            const appointments = await getAppointmentById(id);
            const client = await profileClient.getProfile();
            if (appointments.stylist) {
                const stylist = await profileClient.getProfiles([appointments.stylist.entioId]);
                setAppointment(() => appointments);
                setClient(() => client.data);
                for (const data in stylist) {
                    setStylist(() => [stylist.data[data]]);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getAppointment();
        // eslint-disable-next-line
    }, []);

    return (
        <IonPage>
            <Header
                title="Fashadmin"
                left={<MenuIcon />}
                right={
                    <img
                        src="https://xsgames.co/randomusers/avatar.php?g=female"
                        alt="profile"
                        className="rounded-full h-[5vh]"
                    />
                }
                menuRequire
            />
            <IonContent>
                <div className="flex flex-column justify-center">
                    <div className="flex flex-wrap justify-center max-w-sm my-10 mx-6 p-6 bg-secondaryColor border-2 border-primaryColor rounded-3xl">
                        <div className="w-full text-center items-center flex flex-col">
                            <h1 className="font-montserrat text-2xl text-primaryColor">Cita Agendada</h1>
                            <img
                                className="h-[10vh] w-[10vh] rounded-full border border-2 border-primaryColor mt-2"
                                src={
                                    client?.profile.img?.url
                                        ? client?.profile.img?.url
                                        : client?.profile.img?.blob.data
                                        ? `data:${client.profile.img?.blob.mimetype};base64,${client.profile.img?.blob.data}`
                                        : 'https://picsum.photos/200/300?random=1'
                                }
                                alt="img"
                            />
                            <p className="font-montserrat text-lg my-5">
                                Genial! {client?.profile.firstName} agendaste una cita en {appointment?.salon.name}
                            </p>
                        </div>
                        <div className="w-full">
                            <div className="flex flex-wrap my-2 ml-2">
                                <div className="w-6 flex self-center justify-center">
                                    <SeatedUserIcon />
                                </div>
                                {appointment?.service.map((service, index) => (
                                    <p key={index} className="font-montserrat text-[16px] ml-4">
                                        {service.name}
                                    </p>
                                ))}
                            </div>
                            <hr className="bg-gray-400 h-0.5 w-full" />
                            <div className="flex flex-wrap my-2 ml-2">
                                <div className="w-6 flex self-center justify-center">
                                    <CalendarIcon />
                                </div>
                                <p className="font-montserrat text-[16px] ml-4">
                                    {moment(appointment?.date).format('DD MMMM yy')}
                                </p>
                            </div>
                            <hr className="bg-gray-400 h-0.5 w-full" />
                            <div className="flex flex-wrap my-2 ml-2">
                                <div className="w-6 flex self-center justify-center">
                                    <ClockIcon />
                                </div>
                                <p className="font-montserrat text-[16px] ml-4">{`${appointment?.time?.[0].slice(
                                    0,
                                    8
                                )}${appointment?.time?.[appointment?.time?.length - 1].slice(8)}`}</p>
                            </div>
                            <hr className="bg-gray-400 h-0.5 w-full" />
                            <div className="flex flex-wrap my-2 ml-2">
                                <div className="w-6 flex self-center justify-center">
                                    <PingIcon />
                                </div>
                                <p className="font-montserrat text-[16px] ml-4">{appointment?.salon?.address}</p>
                            </div>
                            <hr className="bg-gray-400 h-0.5 w-full" />
                            <div className="flex flex-wrap w-full my-2 ">
                                <div className="flex flex-wrap ml-2">
                                    <div className="w-6 flex self-center justify-center">
                                        <DollarIcon />
                                    </div>
                                    <p className="font-montserrat text-[16px] ml-4">{appointment?.price}</p>
                                </div>
                            </div>
                            <hr className="bg-gray-400 h-0.5 w-full" />

                            <div className="flex flex-wrap flex-col items-center w-full">
                                <h2 className="text-lg font-medium text-primaryColor">Estilista</h2>
                                <img
                                    src={
                                        stylist?.profile?.img?.url
                                            ? stylist?.profile?.img?.url
                                            : stylist?.profile?.img?.blob.data
                                            ? `data:${stylist?.profile?.img?.blob.mimetype};base64,${stylist?.profile?.img?.blob.data}`
                                            : 'https://picsum.photos/200/300?random=1'
                                    }
                                    alt="Not Found"
                                    className="h-[10vh] w-[10vh] rounded-full border border-2 border-primaryColor"
                                />
                                <p className="font-montserrat text-lg">{stylist?.profile?.firstName}</p>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-around w-full my-5">
                            <a
                                href="/clients/catalogue"
                                className="font-montserrat text-lg py-2 px-6 border border-primaryColor rounded-3xl shadow-md">
                                Volver
                            </a>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default ScheduleAppointmentPage;
