import React from 'react';

const FeedbackIcon = () => {
    return (
        <>
            <svg width="22" height="22" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18 0H2.01001C0.91001 0 0.0100098 0.9 0.0100098 2V20L4.00001 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM11 12H9.00001V10H11V12ZM11 7C11 7.55 10.55 8 10 8C9.45001 8 9.00001 7.55 9.00001 7V5C9.00001 4.45 9.45001 4 10 4C10.55 4 11 4.45 11 5V7Z"
                    fill="#1F1F1F"
                />
            </svg>
        </>
    );
};

export default FeedbackIcon;
