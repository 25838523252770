import { gql, useMutation } from '@apollo/client';
import { salonQuery } from '../dataTypes/salon';
export const useSalonUpdate = () => {
    const [updateOneSalonClient] = useMutation(gql`
        mutation updateOneSalon($id: ObjectId, $regularClients: [ObjectId]!) {
            updateOneSalon(query: { _id: $id }, set: { regularClients: { link: $regularClients } }) {
              ${salonQuery}
            }
        }
    `);
    const updateRegularClients = async (id: string, regularClients: string[]) => {
        try {
            const result = await updateOneSalonClient({
                variables: {
                    id,
                    regularClients,
                },
            });
            console.log(result.data);
            return result.data.updateOneSalon;
        } catch (err) {
            console.log(err);
        }
    };

    const [updateSalonWorkers] = useMutation(gql`
        mutation updateOneSalon($id: ObjectId, $workers: [ObjectId]!) {
            updateOneSalon(query: { _id: $id }, set: { workers: { link: $workers } }) {
              ${salonQuery}
            }
        }
    `);
    const updateWorkers = async (id: string, workers: string[]) => {
        try {
            const result = await updateSalonWorkers({
                variables: {
                    id,
                    workers,
                },
            });
            console.log(result.data);
            return result.data.updateOneSalon;
        } catch (err) {
            console.log(err);
        }
    };

    const [updateOneSalonServices] = useMutation(gql`
        mutation updateOneSalon($id: ObjectId!, $services: [ObjectId]!) {
            updateOneSalon(query: { _id: $id }, set: { services: { link: $services } }) {
                ${salonQuery}
            }
        }
    `);

    const updateSalonServices = async (id: string, services: string[]) => {
        try {
            const result = await updateOneSalonServices({
                variables: {
                    id,
                    services,
                },
            });
            console.log(result.data);
            return result.data.updateOneSalon;
        } catch (err) {
            console.log(err);
        }
    };

    const [updateOneSalonGralInfo] = useMutation(gql`
        mutation updateOneSalon(
            $id: ObjectId!
            $address: String!
            $phoneNumber: String!
            $whatsApp: String!
            $igUrl: String!
        ) {
            updateOneSalon(
                query: { _id: $id }
                set: { address: $address, phoneNumber: $phoneNumber, whatsApp: $whatsApp, igUrl: $igUrl }
            ) {
                ${salonQuery}
            }
        }
    `);

    const updateSalonInfo = async (data: {
        id: string;
        address: string;
        phoneNumber: string;
        whatsApp: string;
        igUrl: string;
    }) => {
        try {
            const result = await updateOneSalonGralInfo({
                variables: {
                    id: data.id,
                    address: data.address,
                    phoneNumber: data.phoneNumber,
                    whatsApp: data.whatsApp,
                    igUrl: data.igUrl,
                },
            });
            console.log(result.data);
            return result.data.updateOneSalon;
        } catch (err) {
            console.log(err);
        }
    };

    const [updateOneSalonTimetable] = useMutation(gql`
        mutation updateOneSalon($id: ObjectId!, $timetable: ObjectId!) {
            updateOneSalon(query: { _id: $id }, set: { timetable: { link: $timetable } }) {
                ${salonQuery}
            }
        }
    `);

    const updateSalonTimetable = async (id: string, timetable: string) => {
        try {
            const result = await updateOneSalonTimetable({
                variables: {
                    id,
                    timetable,
                },
            });
            console.log(result.data);
            return result.data.updateOneSalon;
        } catch (err) {
            console.log(err);
        }
    };

    const [updateOneSalonImg] = useMutation(gql`
    mutation updateOneSalon(
        $id: ObjectId!
        $img: SalonImgUpdateInput!
    ) {
        updateOneSalon(
            query: { _id: $id }
            set: { img: $img }
        ) {
            ${salonQuery}
        }
    }
`);

    const updateSalonImg = async (data: { id: string; imgUrl: string }) => {
        try {
            const result = await updateOneSalonImg({
                variables: {
                    id: data.id,
                    img: {
                        url: data.imgUrl,
                    },
                },
            });
            console.log(result.data);
            return result.data.updateOneSalon;
        } catch (err) {
            console.log(err);
        }
    };

    return {
        updateRegularClients,
        updateSalonServices,
        updateSalonInfo,
        updateSalonTimetable,
        updateWorkers,
        updateSalonImg,
    };
};
