import React from 'react';

const InstagramIcon = () => {
    return (
        <>
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.44444 0C3.61528 0 0.5 2.99067 0.5 6.66667V17.3333C0.5 21.0093 3.61528 24 7.44444 24H18.5556C22.3847 24 25.5 21.0093 25.5 17.3333V6.66667C25.5 2.99067 22.3847 0 18.5556 0H7.44444ZM7.44444 2.66667H18.5556C20.8528 2.66667 22.7222 4.46133 22.7222 6.66667V17.3333C22.7222 19.5387 20.8528 21.3333 18.5556 21.3333H7.44444C5.14722 21.3333 3.27778 19.5387 3.27778 17.3333V6.66667C3.27778 4.46133 5.14722 2.66667 7.44444 2.66667ZM19.9444 4C19.5761 4 19.2228 4.14048 18.9624 4.39052C18.7019 4.64057 18.5556 4.97971 18.5556 5.33333C18.5556 5.68696 18.7019 6.02609 18.9624 6.27614C19.2228 6.52619 19.5761 6.66667 19.9444 6.66667C20.3128 6.66667 20.6661 6.52619 20.9265 6.27614C21.187 6.02609 21.3333 5.68696 21.3333 5.33333C21.3333 4.97971 21.187 4.64057 20.9265 4.39052C20.6661 4.14048 20.3128 4 19.9444 4ZM13 5.33333C9.17083 5.33333 6.05556 8.324 6.05556 12C6.05556 15.676 9.17083 18.6667 13 18.6667C16.8292 18.6667 19.9444 15.676 19.9444 12C19.9444 8.324 16.8292 5.33333 13 5.33333ZM13 8C15.2972 8 17.1667 9.79467 17.1667 12C17.1667 14.2053 15.2972 16 13 16C10.7028 16 8.83333 14.2053 8.83333 12C8.83333 9.79467 10.7028 8 13 8Z"
                    fill="#82385E"
                />
            </svg>
        </>
    );
};

export default InstagramIcon;
