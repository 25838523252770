import React from 'react';
import './App.css';
import Routes from './Routes';
import '@ionic/react/css/core.css';

function App() {
    return <Routes />;
}

export default App;
