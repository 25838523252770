export function findAvailableIntervals(duration: number, hoursToShow: string[]) {
    const result = hoursToShow.map((interval, index) => {
        if (hoursToShow[index].startsWith('T')) {
            return interval;
        }
        for (let i = 1; i < duration; i++) {
            if (hoursToShow[index + i]?.startsWith('T') || !hoursToShow[index + i]) {
                return `T${interval}`;
            }
        }
        return interval;
    });
    return result;
}
