import { gql, useLazyQuery } from '@apollo/client';
import { salonQuery } from '../dataTypes/salon';
export const useSalonQuery = () => {
    const [getOneSalon] = useLazyQuery(
        gql`
            query getSalon($id: ObjectId) {
                salon(query: { _id: $id }) {
                    ${salonQuery}
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
        }
    );
    const getSalonById = async (id: string) => {
        try {
            const result = await getOneSalon({ variables: { id } });
            console.log(result);
            return result.data?.salon;
        } catch (err) {
            console.log(err);
        }
    };

    const [getOneSalonByOwnerEntio] = useLazyQuery(
        gql`
            query getSalon($entioId: String!) {
                salon(query: { owner: { entioId: $entioId } }) {
                    ${salonQuery}
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
        }
    );

    const getOneSalonByOwnerEntioId = async (entioId: string) => {
        try {
            const result = await getOneSalonByOwnerEntio({ variables: { entioId } });
            console.log(result);
            return result.data.salon;
        } catch (err) {
            console.log(err);
        }
    };
    const [getOneSalonByStylistEntio] = useLazyQuery(
        gql`
            query getSalon($entioId: String) {
                salon(query: { workers: { entioId: $entioId } }) {
                    ${salonQuery}
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
        }
    );

    const getOneSalonByStylistEntioId = async (entioId: string) => {
        try {
            const result = await getOneSalonByStylistEntio({ variables: { entioId } });
            console.log(result);
            return result.data.salon;
        } catch (err) {
            console.log(err);
        }
    };
    const [getSalonFromOwnerId] = useLazyQuery(gql`
        query getSalonFromOwnerId($name: String!) {
            salon(query: { name: $name }) {
               ${salonQuery}
            }
        }
    `);

    const getSalonByName = async (name: string) => {
        try {
            const result = await getSalonFromOwnerId({ variables: { name } });
            console.log(result);
            return result.data.salon;
        } catch (err) {
            console.error(err);
        }
    };

    return {
        getSalonById,
        getOneSalonByOwnerEntioId,
        getOneSalonByStylistEntioId,
        getSalonByName,
    };
};
