import React from 'react';
import { IonFab } from '@ionic/react';

const RegisterFAB = () => {
    return (
        <IonFab
            slot="fixed"
            className="bottom-4 right-4 bg-primaryColor w-14 h-14 rounded-2xl shadow-md text-center text-white text-6xl font-normal flex justify-center items-center">
            <a href={`/owner/clients/add`}>+</a>
        </IonFab>
    );
};
export default RegisterFAB;
