import React from 'react';
import ReactCalendar, { CalendarProps, Detail } from 'react-calendar';
import { CalendarArrowIcon } from '../assets/icons';
import { toMonthYearDate } from '../utils/dateFormatter';

type LabelProps = {
    date: Date;
    label: string;
    locale: string;
    view: Detail;
};

const Calendar = (props: CalendarProps) => {
    const getNavigationLabel = (props: LabelProps) => {
        if (props.view === 'month') return toMonthYearDate(props.date);
        return props.label;
    };
    return (
        <ReactCalendar
            locale={'es'}
            prevLabel={<CalendarArrowIcon />}
            nextLabel={<CalendarArrowIcon className="rotate-180" />}
            navigationLabel={getNavigationLabel}
            {...props}
        />
    );
};
export default Calendar;
