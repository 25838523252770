import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { AuthReducer, RegistrationApplicationReducer } from '../reducers';

export const store = configureStore({
    reducer: {
        auth: AuthReducer,
        registrationApplication: RegistrationApplicationReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
