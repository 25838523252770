import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    CalendarIconBlack,
    CalendarIconWhite,
    CloseSessionIcon,
    FeedbackIcon,
    HomeIconBlack,
    HomeIconWhite,
    ManSittingIcon,
    ManSittingIconWhite,
    PeopleIcon,
    PeopleIconWhite,
} from '../assets/icons';

type Props = {
    statistics?: boolean;
    schedule?: boolean;
    salon?: boolean;
    directory?: boolean;
    menuState?: boolean;
    profile?: boolean;
};

const Menu = ({ statistics, schedule, salon, directory, profile }: Props) => {
    const [rol, setRol] = useState('');

    const location = useLocation();
    console.log(rol);

    useEffect(() => {
        location.pathname.startsWith('/clients')
            ? setRol(() => 'user')
            : location.pathname.startsWith('/stylist')
            ? setRol(() => 'stylist')
            : setRol(() => 'owner');
    }, [location]);
    return (
        <div className="fixed w-screen mx-0 my-0 top-[12px] pt-[48px] px-[28px] pb-[100vh] bg-white flex flex-col justify-center items-center gap-3 mt-10 z-[999]">
            {rol === 'owner' && (
                <a
                    href="/owner/statistics"
                    className={`flex h-12 p-4 w-full rounded-[100px] items-center gap-4 font-medium font-primaryFont border-[1px]
                        ${statistics ? 'bg-primaryColor text-white' : 'bg-secondaryColor border-[#1f1f1f80]'}`}>
                    {statistics ? <HomeIconWhite /> : <HomeIconBlack />}
                    Estadísticas
                </a>
            )}
            {rol !== 'user' && (
                <a
                    href={rol === 'owner' ? '/owner/schedule' : '/stylist/schedule'}
                    className={`flex h-12 p-4 w-full rounded-[100px] items-center gap-4 font-medium font-primaryFont border-[1px]
                        ${schedule ? 'bg-primaryColor text-white' : 'bg-secondaryColor border-[#1f1f1f80]'}`}>
                    {schedule ? <CalendarIconWhite /> : <CalendarIconBlack />}
                    Agenda
                </a>
            )}
            {rol === 'user' && (
                <a
                    href="/clients/catalogue"
                    className={`flex h-12 p-4 w-full rounded-[100px] items-center gap-4 font-medium font-primaryFont border-[1px]
                        ${schedule ? 'bg-primaryColor text-white' : 'bg-secondaryColor border-[#1f1f1f80]'}`}>
                    {schedule ? <CalendarIconWhite /> : <CalendarIconBlack />}
                    Catálogo
                </a>
            )}
            {rol === 'owner' && (
                <a
                    href="/owner/hairdresser"
                    className={`flex h-12 p-4 w-full rounded-[100px] items-center gap-4 font-medium font-primaryFont border-[1px]
                        ${salon ? 'bg-primaryColor text-white' : 'bg-secondaryColor border-[#1f1f1f80]'}`}>
                    {salon ? <ManSittingIconWhite /> : <ManSittingIcon />}
                    Mi Peluquería
                </a>
            )}
            {rol === 'owner' && (
                <a
                    href="/owner/clients"
                    className={`flex h-12 p-4 w-full rounded-[100px] items-center gap-4 font-medium font-primaryFont border-[1px]
                        ${directory ? 'bg-primaryColor text-white' : 'bg-secondaryColor border-[#1f1f1f80]'}`}>
                    {directory ? <PeopleIconWhite /> : <PeopleIcon />}
                    Directorio de Clientes
                </a>
            )}
            <a
                href={rol === 'user' ? '/clients/profile' : rol === 'owner' ? '/owner/profile' : '/stylist/profile'}
                className={`flex h-12 p-4 w-full rounded-[100px] items-center gap-4 font-medium font-primaryFont border-[1px]
                        ${profile ? 'bg-primaryColor text-white' : 'bg-secondaryColor border-[#1f1f1f80]'}`}>
                {profile ? <PeopleIconWhite /> : <PeopleIcon />}
                Perfil
            </a>
            <div className="h-[2px] border-secondaryColor w-11/12 bg-secondaryColor my-2" />
            <a
                href="https://wa.me/525530116076"
                className="flex h-12 p-4 w-full bg-secondaryColor rounded-[100px] text-[#000000] items-center gap-4 font-medium font-primaryFont border-[1px] border-[#1f1f1f80]">
                <FeedbackIcon />
                Enviar feedback
            </a>
            <a
                href="/"
                className="flex h-12 p-4 w-full bg-secondaryColor rounded-[100px] text-[#000000] items-center gap-4 font-medium font-primaryFont border-[1px] border-[#1f1f1f80]">
                <CloseSessionIcon />
                Cerrar sesión
            </a>
        </div>
    );
};

export default Menu;
