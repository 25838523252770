import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, EntioJWTPayload } from 'entio-sdk';
import { RootState } from '../app/store';
import { FashProfile } from '../lib/dataTypes/fashProfile';

export interface SessionState {
    token: string;
    loggedIn?: boolean;
    user: User;
    profile: FashProfile;
    userId?: string;
    roles?: Array<string>;
    tokenPayload?: EntioJWTPayload;
}
const getItem = (key: string) => localStorage.getItem(key) || '';

export const loadInitialState = (): SessionState => {
    const token = getItem('token');
    const user = getItem('currentUser');
    const profile = getItem('currentProfile');
    const userId = getItem('userId');
    const roles = getItem('roles');
    return {
        token,
        loggedIn: !!token,
        user: JSON.parse(user || '{}'),
        profile: JSON.parse(profile || '{}'),
        userId: userId,
        roles: JSON.parse(roles || '[]'),
    };
};
const initialState = loadInitialState();

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        validateToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
            state.loggedIn = true;
        },
        checkRole: (state, action: PayloadAction<any>) => {
            state.roles = action.payload;
        },
        validateRole: (state, action: PayloadAction<any>) => {
            const roles = action.payload || [];

            state.roles = roles;
            localStorage.setItem('roles', JSON.stringify(roles));
        },
        login: (state: SessionState, action: PayloadAction<SessionState>) => {
            const { user, profile, token, tokenPayload } = action.payload;
            if (!token || !user || !profile) {
                Object.assign(state, loadInitialState());
                return;
            }

            const userId = user.id;
            const loggedIn = !!token;

            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('currentProfile', JSON.stringify(profile));
            localStorage.setItem('userId', userId);
            localStorage.setItem('token', token);
            Object.assign(state, {
                token,
                loggedIn,
                user,
                profile,
                userId,
            });
        },
        logout: (state: SessionState) => {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('currentProfile');
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('roles');

            Object.assign(state, loadInitialState());
        },
    },
});

export const { login, logout, validateRole } = authSlice.actions;

export const getAuthInfo = (state: RootState) => state.auth;

export default authSlice.reducer;
