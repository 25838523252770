import React from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import ProtectedRoute from './components/auth/ProtectedRoute';

// Auth Pages
import {
    LoginPage,
    SelectAccount,
    EditHairdresserServices,
    EditHairdresserInformation,
    EditHairdresserWorkers,
} from './pages/';

// Client Pages
import {
    CataloguePage,
    CreateAccountPage,
    CreateAppointmentPage,
    ProfileClientPage,
    SalonPage,
    ScheduledAppointmentPage,
} from './pages/';

// Owner Pages
import {
    AddClientPage,
    ClientDirectoryPage,
    CreateOwnerAccountPage,
    CreateOwnerAppointmentPage,
    DetailAppointmentPage,
    MainOwnerPage,
    MyHairdresserPage,
    ProfileOwnerPage,
    SchedulePage,
    StatisticsOwnerPage,
} from './pages/';

// Stylist Pages
import { CreateStylistAccountPage, ProfilePage, ScheduleStylistPage, ScheduleStylistAppointmentPage } from './pages/';

const { PUBLIC_URL } = process.env;
const baseUrl = PUBLIC_URL || '/';

const Routes = () => {
    return (
        <IonApp>
            <IonReactRouter basename={baseUrl}>
                <IonRouterOutlet>
                    {/* Auth Routes */}
                    <Route exact path={'/register'} component={SelectAccount} />
                    <Route exact path={'/'} component={LoginPage} />
                    {/* Client Routes */}
                    <Route path="/clients/register" component={CreateAccountPage} />
                    <ProtectedRoute path="/clients/catalogue" role={'FASH.CLIENT'} component={CataloguePage} />
                    <ProtectedRoute path="/clients/salon/:id" role={'FASH.CLIENT'} component={SalonPage} />
                    <ProtectedRoute path="/clients/profile" role={'FASH.CLIENT'} component={ProfileClientPage} />
                    <ProtectedRoute
                        path="/clients/createappointment/:id"
                        role={'FASH.CLIENT'}
                        component={CreateAppointmentPage}
                    />
                    <ProtectedRoute
                        path="/clients/appointment/:id"
                        role={'FASH.CLIENT'}
                        component={ScheduledAppointmentPage}
                    />
                    {/* Owner Routes */}
                    <ProtectedRoute path="/owner/profile" role={'FASH.OWNER'} component={ProfileOwnerPage} />
                    <ProtectedRoute path="/owner/salon" role={'FASH.OWNER'} component={MainOwnerPage} />
                    <ProtectedRoute path="/owner/schedule" role={'FASH.OWNER'} component={SchedulePage} />
                    <ProtectedRoute
                        path="/owner/appointment/:id"
                        role={'FASH.OWNER'}
                        component={DetailAppointmentPage}
                    />
                    <ProtectedRoute
                        path="/owner/schedule/add"
                        role={'FASH.OWNER'}
                        component={CreateOwnerAppointmentPage}
                    />
                    <Route path="/owner/register" component={CreateOwnerAccountPage} />
                    <ProtectedRoute path="/owner/hairdresser" role={'FASH.OWNER'} component={MyHairdresserPage} />
                    <ProtectedRoute path="/owner/services" role={'FASH.OWNER'} component={EditHairdresserServices} />
                    <ProtectedRoute
                        path="/owner/information"
                        role={'FASH.OWNER'}
                        component={EditHairdresserInformation}
                    />
                    <ProtectedRoute path="/owner/workers" role={'FASH.OWNER'} component={EditHairdresserWorkers} />
                    <ProtectedRoute path="/owner/clients" role={'FASH.OWNER'} component={ClientDirectoryPage} />
                    <ProtectedRoute path="/owner/clients/add" role={'FASH.OWNER'} component={AddClientPage} />
                    <ProtectedRoute path="/owner/statistics" role={'FASH.OWNER'} component={StatisticsOwnerPage} />
                    {/* Stylist Routes */}
                    <Route path="/stylist/register" component={CreateStylistAccountPage} />
                    <ProtectedRoute path="/stylist/profile" role={'FASH.STYLIST'} component={ProfilePage} />
                    <ProtectedRoute path="/stylist/schedule" role={'FASH.STYLIST'} component={ScheduleStylistPage} />
                    <ProtectedRoute
                        path="/stylist/appointment/:id"
                        role={'FASH.STYLIST'}
                        component={ScheduleStylistAppointmentPage}
                    />
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    );
};

export default Routes;
