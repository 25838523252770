import React, { SVGProps } from 'react';

const YearIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={33} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M27.333 4H26V2.667c0-.734-.6-1.334-1.333-1.334-.734 0-1.334.6-1.334 1.334V4H10V2.667c0-.734-.6-1.334-1.333-1.334-.734 0-1.334.6-1.334 1.334V4H6a2.675 2.675 0 0 0-2.667 2.667V28c0 1.467 1.2 2.667 2.667 2.667h21.333C28.8 30.667 30 29.467 30 28V6.667C30 5.2 28.8 4 27.333 4ZM26 28H7.333C6.6 28 6 27.4 6 26.667v-16h21.333v16c0 .733-.6 1.333-1.333 1.333Z"
            fill="#82385E"
        />
    </svg>
);

export default YearIcon;
