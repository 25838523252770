import React from 'react';

const AddressIcon = () => {
    return (
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 10.6613C9.1 10.6613 10 9.76126 10 8.66125C10 7.56125 9.1 6.66125 8 6.66125C6.9 6.66125 6 7.56125 6 8.66125C6 9.76126 6.9 10.6613 8 10.6613ZM8 0.661255C12.2 0.661255 16 3.88125 16 8.86125C16 12.0413 13.55 15.7813 8.66 20.0913C8.28 20.4213 7.71 20.4213 7.33 20.0913C2.45 15.7813 0 12.0413 0 8.86125C0 3.88125 3.8 0.661255 8 0.661255Z"
                fill="#82385E"
            />
        </svg>
    );
};

export default AddressIcon;
