import React from 'react';
import { IonCardHeader } from '@ionic/react';
import { EditIcon } from '../assets/icons';
import { Salon } from '../lib/dataTypes/salon';

interface Props {
    salon?: Salon;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
    edit: boolean;
}
const OurServices = (props: Props) => {
    return (
        <div className="mt-4 flex flex-col font-montserrat px-2 justify-center items-center">
            <IonCardHeader className="navbar navbar-expand-lg shadow-md py-2 px-2 bg-gray-100 flex items-center w-full justify-between">
                <div className="text-primaryColor text-[1.5rem] px-2">Nuestros Servicios</div>
                <button onClick={() => props.setEdit(!props.edit)} className="rounded-full bg-primaryColor p-1">
                    <EditIcon />
                </button>
            </IonCardHeader>
            <div className="flex flex-wrap justify-start p-1 w-[93vw] h-auto max-h-[35vh] overflow-auto scrollbar-hidden">
                {props.salon &&
                    props.salon.services.map((servicio, index) => {
                        return (
                            <div
                                className="flex flex-col border-[1px] border-gray-400 justify-between items-center w-[28vw] py-1 rounded-md m-1 h-[110px]"
                                key={index}>
                                <p className="text-xs text-center">{servicio.service.name}</p>
                                <div className="flex w-full flex-col items-center h-[70%] justify-center">
                                    {servicio.service.img?.blob && (
                                        <img
                                            src={`data:${servicio.service.img.blob?.mimeType};base64,${servicio.service.img.blob?.data}`}
                                            alt="img_services"
                                            className="w-8 h-8"
                                        />
                                    )}
                                    <p className="text-primaryColor text-sm"> $ {servicio.price}</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default OurServices;
