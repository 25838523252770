import React, { useEffect, useState } from 'react';
import { IonCardHeader, useIonToast } from '@ionic/react';
import { EditIcon } from '../assets/icons';
import { Salon } from '../lib/dataTypes/salon';
import { useUpdateTimeTable } from '../lib/dataClient/useTimeTableUpdate';
import { useSalonUpdate } from '../lib/dataClient/useSalonUpdate';
import TimeSelect from './TimeSelect';

interface Props {
    salon: Salon;
    setSalon: React.Dispatch<React.SetStateAction<Salon | undefined>>;
}
export interface Time {
    start: string;
    end: string;
}
export interface TimeTable {
    monday: Time;
    tuesday: Time;
    wednesday: Time;
    thursday: Time;
    friday: Time;
    saturday: Time;
    sunday: Time;
}

const DaysHoursItem = (props: Props) => {
    console.log(props.salon);
    const [editTime, setEditTime] = useState(false);
    const [dayToEvaluate, setDayToEvaluate] = useState('');
    const getStart = (day: string) => {
        type key = keyof TimeTable;
        const { start } = props.salon.timetable[day as key];
        return start;
    };
    const getEnd = (day: string) => {
        type key = keyof TimeTable;
        const { end } = props.salon.timetable[day as key];
        return end;
    };

    const [data, setData] = useState({
        monday: {
            start: getStart('monday'),
            end: getEnd('monday'),
        },
        tuesday: {
            start: getStart('tuesday'),
            end: getEnd('tuesday'),
        },
        wednesday: {
            start: getStart('wednesday'),
            end: getEnd('wednesday'),
        },
        thursday: {
            start: getStart('thursday'),
            end: getEnd('thursday'),
        },
        friday: {
            start: getStart('friday'),
            end: getEnd('friday'),
        },
        saturday: {
            start: getStart('saturday'),
            end: getEnd('saturday'),
        },
        sunday: {
            start: getStart('sunday'),
            end: getEnd('sunday'),
        },
    });

    const [error, setError] = useState({
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
        sunday: '',
    });

    const [dayClosed, setDayClosed] = useState({
        monday:
            props.salon.timetable.monday.start === '' ||
            (props.salon.timetable.monday.start === '00:00' && props.salon.timetable.monday.end === '00:00')
                ? true
                : false,
        tuesday:
            props.salon.timetable.tuesday.start === '' ||
            (props.salon.timetable.tuesday.start === '00:00' && props.salon.timetable.tuesday.end === '00:00')
                ? true
                : false,
        wednesday:
            props.salon.timetable.wednesday.start === '' ||
            (props.salon.timetable.wednesday.start === '00:00' && props.salon.timetable.wednesday.end === '00:00')
                ? true
                : false,
        thursday:
            props.salon.timetable.thursday.start === '' ||
            (props.salon.timetable.thursday.start === '00:00' && props.salon.timetable.thursday.end === '00:00')
                ? true
                : false,
        friday:
            props.salon.timetable.friday.start === '' ||
            (props.salon.timetable.friday.start === '00:00' && props.salon.timetable.friday.end === '00:00')
                ? true
                : false,
        saturday:
            props.salon.timetable.saturday.start === '' ||
            (props.salon.timetable.saturday.start === '00:00' && props.salon.timetable.saturday.end === '00:00')
                ? true
                : false,
        sunday:
            props.salon.timetable.sunday.start === '' ||
            (props.salon.timetable.sunday.start === '00:00' && props.salon.timetable.sunday.end === '00:00')
                ? true
                : false,
    });
    const updateTimeTable = useUpdateTimeTable();
    const { updateSalonTimetable } = useSalonUpdate();
    const [toast] = useIonToast();
    const [disabled, setDisabled] = useState(false);
    const onError = async (error: string) => {
        await toast({
            color: 'warning',
            position: 'middle',
            message: error,
            duration: 2000,
            cssClass:
                'absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-red-500 text-white rounded-lg p-4',
        });
    };
    const disableButton = () => {
        error.monday !== '' ||
        error.tuesday !== '' ||
        error.wednesday !== '' ||
        error.thursday !== '' ||
        error.friday !== '' ||
        error.saturday !== '' ||
        error.sunday !== ''
            ? setDisabled(() => true)
            : setDisabled(() => false);
    };

    const errorValidate = (day: string) => {
        type key = keyof typeof data;
        type errorkey = keyof typeof error;
        const start = data[day as key]?.start;
        const end = data[day as key]?.end;
        let err = '';
        start === end
            ? (err = 'El horario de apertura y cierre no puede ser el mismo')
            : start.split(':')[0] > end.split(':')[0]
            ? (err = 'El horario de apertura no puede ser posterior al de cierre')
            : (err = '');

        setError(() => {
            return { ...error, [day as errorkey]: err };
        });
        disableButton();
        console.log(error);
    };

    useEffect(() => {
        errorValidate(dayToEvaluate);
        //eslint-disable-next-line
    }, [data]);
    useEffect(() => {
        disableButton();
        //eslint-disable-next-line
    }, [error]);

    const updateSalonTime = async () => {
        if (!disabled) {
            try {
                const id = props.salon.timetable._id;
                console.log(id);
                const updatedTimeTable = await updateTimeTable(id, data);
                console.log(updatedTimeTable);
                if (updatedTimeTable) {
                    const updatedSalon = await updateSalonTimetable(props.salon._id, id);
                    props.setSalon(() => updatedSalon);
                    setEditTime(() => !editTime);
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            onError('Hubo un error en su formulario');
        }
    };

    return (
        <div className="mt-4 flex flex-col font-montserrat px-2 justify-center">
            <IonCardHeader className="navbar navbar-expand-lg shadow-md py-2 px-2 bg-gray-100 flex items-center justify-between">
                <div className="text-primaryColor text-[22px] px-2 font-montserrat w-full">
                    Días y Horarios de Atención
                </div>
                <div
                    className="relative rounded-full bg-primaryColor p-1"
                    onClick={() => {
                        setEditTime(() => !editTime);
                    }}>
                    <EditIcon />
                </div>
            </IonCardHeader>
            <div className="flex flex-wrap my-6 mx-4 text-sm">
                <div className={`flex ${editTime ? 'flex-col' : 'flex-row'} justify-between items-center w-full px-1`}>
                    <div className={`flex flex-row justify-between items-center w-full`}>
                        <p>Lunes</p>
                        {editTime && (
                            <div className="flex flex-row items-center w-[40%] justify-end">
                                {dayClosed.monday && <p className="text-end mr-2">Cerrado</p>}
                                <div
                                    className={`w-[10vw] ${
                                        dayClosed.monday ? 'bg-gray-200' : 'bg-primaryColor '
                                    } rounded-full h-5vw] flex items-center px-1`}
                                    onClick={() => setDayClosed(() => ({ ...dayClosed, monday: !dayClosed.monday }))}>
                                    <div
                                        className={`w-[5vw] transition  easy-in-out duration-400 rounded-full bg-white h-[4vw] ${
                                            !dayClosed.monday && 'translate-x-[60%]'
                                        }`}></div>
                                </div>
                            </div>
                        )}
                    </div>
                    {editTime ? (
                        !dayClosed.monday && (
                            <div className="flex flex-col justify-around w-full text-sm ">
                                <div className="flex flex-row justify-around w-full text-sm ">
                                    <TimeSelect
                                        time={props.salon.timetable.monday.start}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        tradingTime="start"
                                        day="monday"
                                    />
                                    <p className="mx-1"> a </p>
                                    <TimeSelect
                                        time={props.salon.timetable.monday.end}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        day="monday"
                                        tradingTime="end"
                                    />
                                </div>
                                <div>
                                    {error.monday !== '' && (
                                        <p className="text-xs text-center text-red-500">{error.monday}</p>
                                    )}
                                </div>
                            </div>
                        )
                    ) : dayClosed.monday ? (
                        <p>Cerrado</p>
                    ) : (
                        <div className="w-[80%] flex justify-end">
                            {props.salon.timetable.monday.start} a {props.salon.timetable.monday.end}
                        </div>
                    )}
                </div>
                <div className="flex flex-col justify-around w-full h-[1px] my-3 bg-primaryColor" />

                <div className={`flex ${editTime ? 'flex-col' : 'flex-row'} justify-between items-center w-full px-1`}>
                    <div className={`flex flex-row justify-between items-center w-full`}>
                        <p>Martes</p>
                        {editTime && (
                            <div className="flex flex-row items-center w-[40%] justify-end">
                                {dayClosed.tuesday && <p className="text-end mr-2">Cerrado</p>}
                                <div
                                    className={`w-[10vw] ${
                                        dayClosed.tuesday ? 'bg-gray-200' : 'bg-primaryColor '
                                    } rounded-full h-[5vw] flex items-center px-1`}
                                    onClick={() => setDayClosed(() => ({ ...dayClosed, tuesday: !dayClosed.tuesday }))}>
                                    <div
                                        className={`w-[5vw] transition  easy-in-out duration-400 rounded-full bg-white h-[4vw] ${
                                            !dayClosed.tuesday && 'translate-x-[60%]'
                                        }`}></div>
                                </div>
                            </div>
                        )}
                    </div>
                    {editTime ? (
                        !dayClosed.tuesday && (
                            <div className="flex flex-col justify-around w-full text-sm ">
                                <div className="flex flex-row justify-around w-full text-sm ">
                                    <TimeSelect
                                        time={props.salon.timetable.tuesday.start}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        tradingTime="start"
                                        day="tuesday"
                                    />
                                    <p className="mx-1"> a </p>
                                    <TimeSelect
                                        time={props.salon.timetable.tuesday.end}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        tradingTime="end"
                                        day="tuesday"
                                    />
                                </div>
                                <div>
                                    {error.tuesday !== '' && (
                                        <p className="text-xs text-center text-red-500">{error.tuesday}</p>
                                    )}
                                </div>
                            </div>
                        )
                    ) : dayClosed.tuesday ? (
                        <p>Cerrado</p>
                    ) : (
                        <div className="w-[80%] flex justify-end">
                            {props.salon.timetable.tuesday.start} a {props.salon.timetable.tuesday.end}
                        </div>
                    )}
                </div>
                <div className="flex flex-col justify-around w-full h-[1px] my-3 bg-primaryColor" />

                <div className={`flex ${editTime ? 'flex-col' : 'flex-row'} justify-between items-center w-full px-1`}>
                    <div className={`flex flex-row justify-between items-center w-full`}>
                        <p>Miércoles</p>
                        {editTime && (
                            <div className="flex flex-row items-center w-[40%] justify-end">
                                {dayClosed.wednesday && <p className="text-end mr-2">Cerrado</p>}
                                <div
                                    className={`w-[10vw] ${
                                        dayClosed.wednesday ? 'bg-gray-200' : 'bg-primaryColor '
                                    } rounded-full h-[5vw] flex items-center px-1`}
                                    onClick={() =>
                                        setDayClosed(() => ({ ...dayClosed, wednesday: !dayClosed.wednesday }))
                                    }>
                                    <div
                                        className={`w-[5vw] transition  easy-in-out duration-400 rounded-full bg-white h-[4vw] ${
                                            !dayClosed.wednesday && 'translate-x-[60%]'
                                        }`}></div>
                                </div>
                            </div>
                        )}{' '}
                    </div>
                    {editTime ? (
                        !dayClosed.wednesday && (
                            <div className="flex flex-col justify-around w-full text-sm ">
                                <div className="flex flex-row justify-around w-full text-sm ">
                                    <TimeSelect
                                        time={props.salon.timetable.wednesday.start}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        tradingTime="start"
                                        day="wednesday"
                                    />
                                    <p className="mx-1"> a </p>
                                    <TimeSelect
                                        time={props.salon.timetable.wednesday.end}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        tradingTime="end"
                                        day="wednesday"
                                    />
                                </div>
                                <div>
                                    {error.wednesday !== '' && (
                                        <p className="text-xs text-center text-red-500">{error.wednesday}</p>
                                    )}
                                </div>
                            </div>
                        )
                    ) : dayClosed.wednesday ? (
                        <p>Cerrado</p>
                    ) : (
                        <div className="w-[80%] flex justify-end">
                            {props.salon.timetable.wednesday.start} a {props.salon.timetable.wednesday.end}
                        </div>
                    )}
                </div>
                <div className="flex flex-col justify-around w-full h-[1px] my-3 bg-primaryColor" />

                <div className={`flex ${editTime ? 'flex-col' : 'flex-row'} justify-between items-center w-full px-1`}>
                    <div className={`flex flex-row justify-between items-center w-full`}>
                        <p>Jueves</p>
                        {editTime && (
                            <div className="flex flex-row items-center w-[40%] justify-end">
                                {dayClosed.thursday && <p className="text-end mr-2">Cerrado</p>}
                                <div
                                    className={`w-[10vw] ${
                                        dayClosed.thursday ? 'bg-gray-200' : 'bg-primaryColor '
                                    } rounded-full h-[5vw] flex items-center px-1`}
                                    onClick={() =>
                                        setDayClosed(() => ({ ...dayClosed, thursday: !dayClosed.thursday }))
                                    }>
                                    <div
                                        className={`w-[5vw] transition  easy-in-out duration-400 rounded-full bg-white h-[4vw] ${
                                            !dayClosed.thursday && 'translate-x-[60%]'
                                        }`}></div>
                                </div>
                            </div>
                        )}{' '}
                    </div>
                    {editTime ? (
                        !dayClosed.thursday && (
                            <div className="flex flex-col justify-around w-full text-sm ">
                                <div className="flex flex-row justify-around w-full text-sm ">
                                    <TimeSelect
                                        time={props.salon.timetable.thursday.start}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        tradingTime="start"
                                        day="thursday"
                                    />
                                    <p className="mx-1"> a </p>
                                    <TimeSelect
                                        time={props.salon.timetable.thursday.end}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        tradingTime="end"
                                        day="thursday"
                                    />
                                </div>
                                <div>
                                    {error.thursday !== '' && (
                                        <p className="text-xs text-center text-red-500">{error.thursday}</p>
                                    )}
                                </div>
                            </div>
                        )
                    ) : dayClosed.thursday ? (
                        <p>Cerrado</p>
                    ) : (
                        <div className="w-[80%] flex justify-end">
                            {props.salon.timetable.thursday.start} a {props.salon.timetable.thursday.end}
                        </div>
                    )}
                </div>
                <div className="flex flex-col justify-around w-full h-[1px] my-3 bg-primaryColor" />

                <div className={`flex ${editTime ? 'flex-col' : 'flex-row'} justify-between items-center w-full px-1`}>
                    <div className={`flex flex-row justify-between items-center w-full`}>
                        <p>Viernes</p>
                        {editTime && (
                            <div className="flex flex-row items-center w-[40%] justify-end">
                                {dayClosed.friday && <p className="text-end mr-2">Cerrado</p>}
                                <div
                                    className={`w-[10vw] ${
                                        dayClosed.friday ? 'bg-gray-200' : 'bg-primaryColor '
                                    } rounded-full h-[5vw] flex items-center px-1`}
                                    onClick={() => setDayClosed(() => ({ ...dayClosed, friday: !dayClosed.friday }))}>
                                    <div
                                        className={`w-[5vw] transition  easy-in-out duration-400 rounded-full bg-white h-[4vw] ${
                                            !dayClosed.friday && 'translate-x-[60%]'
                                        }`}></div>
                                </div>
                            </div>
                        )}{' '}
                    </div>
                    {editTime ? (
                        !dayClosed.friday && (
                            <div className="flex flex-col justify-around w-full text-sm ">
                                <div className="flex flex-row justify-around w-full text-sm ">
                                    <TimeSelect
                                        time={props.salon.timetable.friday.start}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        tradingTime="start"
                                        day="friday"
                                    />
                                    <p className="mx-1"> a </p>
                                    <TimeSelect
                                        time={props.salon.timetable.friday.end}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        tradingTime="end"
                                        day="friday"
                                    />
                                </div>
                                <div>
                                    {error.friday !== '' && (
                                        <p className="text-xs text-center text-red-500">{error.friday}</p>
                                    )}
                                </div>
                            </div>
                        )
                    ) : dayClosed.friday ? (
                        <p>Cerrado</p>
                    ) : (
                        <div className="w-[80%] flex justify-end">
                            {props.salon.timetable.friday.start} a {props.salon.timetable.friday.end}
                        </div>
                    )}
                </div>
                <div className="flex flex-col justify-around w-full h-[1px] my-3 bg-primaryColor" />

                <div className={`flex ${editTime ? 'flex-col' : 'flex-row'} justify-between items-center w-full px-1`}>
                    <div className={`flex flex-row justify-between items-center w-full`}>
                        <p>Sábado</p>
                        {editTime && (
                            <div className="flex flex-row items-center w-[40%] justify-end">
                                {dayClosed.saturday && <p className="text-end mr-2">Cerrado</p>}
                                <div
                                    className={`w-[10vw] ${
                                        dayClosed.saturday ? 'bg-gray-200' : 'bg-primaryColor '
                                    } rounded-full h-5vw] flex items-center px-1`}
                                    onClick={() =>
                                        setDayClosed(() => ({ ...dayClosed, saturday: !dayClosed.saturday }))
                                    }>
                                    <div
                                        className={`w-[5vw] transition  easy-in-out duration-400 rounded-full bg-white h-[4vw] ${
                                            !dayClosed.saturday && 'translate-x-[60%]'
                                        }`}></div>
                                </div>
                            </div>
                        )}
                    </div>
                    {editTime ? (
                        !dayClosed.saturday && (
                            <div className="flex flex-col justify-around w-full text-sm ">
                                <div className="flex flex-row justify-around w-full text-sm ">
                                    <TimeSelect
                                        time={props.salon.timetable.saturday.start}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        tradingTime="start"
                                        day="saturday"
                                    />
                                    <p className="mx-1"> a </p>
                                    <TimeSelect
                                        time={props.salon.timetable.saturday.end}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        tradingTime="end"
                                        day="saturday"
                                    />
                                </div>
                                <div>
                                    {error.saturday !== '' && (
                                        <p className="text-xs text-center text-red-500">{error.saturday}</p>
                                    )}
                                </div>
                            </div>
                        )
                    ) : dayClosed.saturday ? (
                        <p>Cerrado</p>
                    ) : (
                        <div className="w-[80%] flex justify-end">
                            {props.salon.timetable.saturday.start} a {props.salon.timetable.saturday.end}
                        </div>
                    )}
                </div>
                <div className="flex flex-col justify-around w-full h-[1px] my-3 bg-primaryColor" />

                <div className={`flex ${editTime ? 'flex-col' : 'flex-row'} justify-between items-center w-full px-1`}>
                    <div className={`flex flex-row justify-between items-center w-full`}>
                        <p>Domingo</p>
                        {editTime && (
                            <div className="flex flex-row items-center w-[40%] justify-end">
                                {dayClosed.sunday && <p className="text-end mr-2">Cerrado</p>}
                                <div
                                    className={`w-[10vw] ${
                                        dayClosed.sunday ? 'bg-gray-200' : 'bg-primaryColor '
                                    } rounded-full h-5vw] flex items-center px-1`}
                                    onClick={() => setDayClosed(() => ({ ...dayClosed, sunday: !dayClosed.sunday }))}>
                                    <div
                                        className={`w-[5vw] transition  easy-in-out duration-400 rounded-full bg-white h-[4vw] ${
                                            !dayClosed.sunday && 'translate-x-[60%]'
                                        }`}></div>
                                </div>
                            </div>
                        )}
                    </div>
                    {editTime ? (
                        !dayClosed.sunday && (
                            <div className="flex flex-col justify-around w-full text-sm ">
                                <div className="flex flex-row justify-around w-full text-sm ">
                                    <TimeSelect
                                        time={props.salon.timetable.sunday.start}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        tradingTime="start"
                                        day="sunday"
                                    />
                                    <p className="mx-1"> a </p>
                                    <TimeSelect
                                        time={props.salon.timetable.sunday.end}
                                        setData={setData}
                                        setDayToEvaluate={setDayToEvaluate}
                                        tradingTime="end"
                                        day="sunday"
                                    />
                                </div>
                                <div>
                                    {error.sunday !== '' && (
                                        <p className="text-xs text-center text-red-500">{error.sunday}</p>
                                    )}
                                </div>
                            </div>
                        )
                    ) : dayClosed.sunday ? (
                        <p>Cerrado</p>
                    ) : (
                        <div className="w-[80%] flex justify-end">
                            {props.salon.timetable.sunday.start} a {props.salon.timetable.sunday.end}
                        </div>
                    )}
                </div>
                <div className="flex flex-col justify-around w-full h-[1px] my-3 bg-primaryColor" />

                {editTime && (
                    <div className="w-full flex justify-around">
                        <button
                            onClick={() => setEditTime(!editTime)}
                            className="outline outline-[1px] outline-primaryColor bg-gray-200 rounded-full px-5 drop-shadow-lg mt-2 text-[18px]">
                            Cancelar
                        </button>
                        <button
                            disabled={disabled}
                            onClick={updateSalonTime}
                            className={`outline  outline-[1px] text-gray-100 rounded-full px-5 ${
                                !disabled ? 'bg-primaryColor' : 'bg-gray-200'
                            } drop-shadow-lg mt-2 text-[18px]`}>
                            Guardar
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DaysHoursItem;
