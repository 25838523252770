import React, { useEffect, useState } from 'react';
import { MenuIcon, SearchIcon, SettingsIcon } from '../../assets/icons';
import { ClientDirectoryCard, Header, RegisterFAB } from '../../components';
import { useSalonQuery } from '../../lib/dataClient/useSalonQuery';
import { FashProfile } from '../../lib/dataTypes/fashProfile';
import { Salon } from '../../lib/dataTypes/salon';
import { User } from '../../lib/dataTypes/users';
import { getProfileClient } from '../../lib/getProfileClient';
const ClientDirectoryPage = () => {
    const [filtered, setFiltered] = useState<FashProfile[]>([]);
    const [clients, setClients] = useState<FashProfile[]>([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [salon, setSalon] = useState<Salon>();
    const profileClient = getProfileClient();
    const selectOptions = [
        {
            label: 'Nombre',
            value: 'names',
        },
        {
            label: 'Email',
            value: 'email',
        },
        {
            label: 'Telefono',
            value: 'phone',
        },
    ];
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(() => e.target.value);
    };
    console.log(selectedOption);
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const clientsCopy = [...clients];
        console.log(selectedOption);
        if (e.target.value === '') {
            setFiltered(() => clientsCopy);
            return;
        }
        selectedOption === 'names' || selectedOption === ''
            ? setFiltered(() =>
                  clientsCopy.filter((client) =>
                      client.profile.firstName?.toLowerCase().includes(e.target.value.toLowerCase())
                  )
              )
            : selectedOption === 'email'
            ? setFiltered(() =>
                  clientsCopy.filter((client) =>
                      client.user.email?.toLowerCase().includes(e.target.value.toLowerCase())
                  )
              )
            : selectedOption === 'phone' &&
              setFiltered(() =>
                  clientsCopy.filter((client) =>
                      client.user.phone?.toLowerCase().includes(e.target.value.toLowerCase())
                  )
              );
    };

    const { getOneSalonByOwnerEntioId } = useSalonQuery();

    const getClients = async () => {
        const owner = await profileClient.getProfile();
        if (owner) {
            try {
                const data = await getOneSalonByOwnerEntioId(owner.data.user.id);
                if (data) {
                    const clientsEntioIds = data.regularClients.map((e: User) => e.entioId);
                    const getProfiles = await profileClient.getProfiles(clientsEntioIds);
                    //eslint-disable-next-line
                    const clientsProfiles: FashProfile[] | any = [];
                    for (const data in getProfiles.data) {
                        clientsProfiles.push(getProfiles.data[data]);
                    }
                    setClients(() => clientsProfiles);
                    setSalon(() => data);
                    setFiltered(() => clientsProfiles);
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    useEffect(() => {
        getClients();
        //eslint-disable-next-line
    }, []);
    return (
        <div className="w-full flex flex-col overflow-scroll h-screen items-start">
            <Header title="Directorio de Clientes" left={<MenuIcon />} right={<SettingsIcon />} menuRequire />
            <div className="w-10/12 mx-auto mt-4 mb-8 bg-[#F5F5F5] h-12 rounded-2xl pl-5 pr-4 flex shadow-[0_4px_8px_3px_rgba(0,0,0,0.15)] shadow-[0_1px_3px_rgba(0,0,0,0.3)]">
                <input
                    type="text"
                    placeholder="Buscar..."
                    name="search"
                    className="bg-transparent h-12 w-full focus:outline-none text-[18px] font-monserrat text-black"
                    onChange={handleSearch}
                />
                <button type="submit">
                    <SearchIcon />
                </button>
            </div>
            <div className="flex flex-col w-10/12 mx-auto h-12">
                <div className="relative">
                    <div className="absolute -bottom-3 left-2">
                        <label htmlFor="userName" className="text-[#82385E] text-[18px] bg-white pr-1 font-monserrat">
                            Filtrar por
                        </label>
                    </div>
                </div>
                <div className=" flex justify-center h-12">
                    <select
                        name="select"
                        className="filter-client border rounded-md border-1 border-[#82385E] focus:border-[#82385E] focus:border-1 focus:outline-none px-2 pt-1 pb-1 w-full h-12 font-monserrat"
                        onChange={handleChange}>
                        <option value="" className="border-transparent">
                            Elija una opción
                        </option>
                        {selectOptions.map((option) => (
                            <option value={option.value} key={option.value} className="bg-[#F5F5F5] border-transparent">
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <section className="w-10/12 mx-auto mt-2">
                {filtered &&
                    filtered.map((client, index) => (
                        <ClientDirectoryCard
                            key={index}
                            name={client.profile.firstName}
                            phone={client.user.phone}
                            email={client.user.email}
                            id={client.user.id}
                            image={'https://xsgames.co/randomusers/avatar.php?g=male'}
                        />
                    ))}
            </section>
            {salon && <RegisterFAB />}
        </div>
    );
};

export default ClientDirectoryPage;
