import React from 'react';

const WorkersIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="none" viewBox="0 0 26 24">
            <path
                fill="#82385E"
                d="M16 2.5h-4.18C11.4 1.34 10.3.5 9 .5c-1.3 0-2.4.84-2.82 2H2c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H3v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1.4z"></path>
        </svg>
    );
};

export default WorkersIcon;
