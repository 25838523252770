import React, { SVGProps } from 'react';

const ClientIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={28} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.811 5.728a3.314 3.314 0 0 1 0-4.747 3.48 3.48 0 0 1 4.844 0 3.314 3.314 0 0 1 0 4.747c-1.352 1.325-3.509 1.325-4.844 0ZM4.09 23.491V10.073c0-.923-.77-1.678-1.712-1.678-.941 0-1.711.755-1.711 1.678V23.49c0 4.63 3.834 8.387 8.558 8.387h8.558c.941 0 1.711-.754 1.711-1.677 0-.922-.77-1.677-1.711-1.677H9.225c-2.842 0-5.135-2.248-5.135-5.033Zm22.73 5.62-6.453-6.324a3.455 3.455 0 0 0-2.413-.973h-4.45v-6.172c1.865 1.492 4.553 2.851 7.188 3.388 1.147.235 2.226-.604 2.226-1.745 0-.889-.668-1.61-1.575-1.761-2.43-.402-4.93-1.694-6.419-3.304l-2.396-2.6a3.67 3.67 0 0 0-1.18-.839 3.872 3.872 0 0 0-1.644-.386h-.051c-2.123 0-3.851 1.694-3.851 3.774v9.645c0 2.785 2.293 5.032 5.134 5.032h8.678l4.758 4.663c.668.654 1.78.654 2.448 0a1.657 1.657 0 0 0 0-2.398Z"
            fill="#82385E"
        />
    </svg>
);

export default ClientIcon;
