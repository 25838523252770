import React from 'react';

const SeatedUserIcon = () => {
    return (
        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.60847 4.29616C3.60719 3.3149 3.60719 1.71721 4.60847 0.735945C5.60976 -0.245315 7.24005 -0.245315 8.24133 0.735945C9.24262 1.71721 9.24262 3.3149 8.24133 4.29616C7.22721 5.29 5.60976 5.29 4.60847 4.29616ZM2.56739 17.6187V7.55445C2.56739 6.86253 1.98973 6.29642 1.2837 6.29642C0.577664 6.29642 0 6.86253 0 7.55445V17.6187C0 21.0908 2.87548 23.9088 6.41849 23.9088H12.837C13.543 23.9088 14.1207 23.3427 14.1207 22.6508C14.1207 21.9588 13.543 21.3927 12.837 21.3927H6.41849C4.28755 21.3927 2.56739 19.707 2.56739 17.6187ZM19.6149 21.833L14.7754 17.0903C14.3004 16.6248 13.6457 16.3606 12.9653 16.3606H9.62773V11.7311C11.027 12.8507 13.0424 13.8697 15.0193 14.2723C15.8793 14.4484 16.6881 13.8194 16.6881 12.964C16.6881 12.2972 16.1874 11.7563 15.5071 11.643C13.6842 11.3411 11.81 10.3724 10.6932 9.16472L8.89602 7.21478C8.65212 6.9506 8.34403 6.73673 8.01027 6.58577C7.638 6.40964 7.21438 6.29642 6.77792 6.29642H6.73941C5.14762 6.29642 3.85109 7.56703 3.85109 9.12698V16.3606C3.85109 18.449 5.57125 20.1347 7.70218 20.1347H14.2105L17.7792 23.632C18.2798 24.1227 19.1143 24.1227 19.6149 23.632C20.1284 23.1414 20.1284 22.3363 19.6149 21.833Z"
                fill="#82385E"
            />
        </svg>
    );
};

export default SeatedUserIcon;
