import { gql, useMutation } from '@apollo/client';

export const useSalonCreate = () => {
    const [create] = useMutation(gql`
        mutation CreateSalon(
            $owner: ObjectId
            $timetable: ObjectId
            $name: String
            $workers: [ObjectId]
            $services: [ObjectId]
            $regularClients: [ObjectId]
            $address: String
            $phoneNumber: String
            $whatsApp: String
            $igUrl: String
            $description: String
        ) {
            insertOneSalon(
                data: {
                    owner: { link: $owner }
                    timetable: { link: $timetable }
                    name: $name
                    workers: { link: $workers }
                    services: { link: $services }
                    regularClients: { link: $regularClients }
                    address: $address
                    igUrl: $igUrl
                    phoneNumber: $phoneNumber
                    whatsApp: $whatsApp
                    description: $description
                }
            ) {
                _id
            }
        }
    `);

    return async (data: { owner: string; timetable: string; name: string }) => {
        try {
            const response = await create({
                variables: {
                    owner: data.owner,
                    timetable: data.timetable,
                    name: data.name,
                    workers: [],
                    services: [],
                    address: '',
                    igUrl: '',
                    regularClients: [],
                    phoneNumber: '',
                    whatsApp: '',
                    description: '',
                },
            });
            return response?.data?.insertOneSalon;
        } catch (err) {
            console.log(err);
        }
    };
};
