import React, { useEffect, useState } from 'react';
import { IonContent, IonFab, IonPage } from '@ionic/react';
import { MenuIcon, SettingsIcon } from '../../assets/icons';
import { Calendar, Header } from '../../components';
import { toFullLongDate } from '../../utils/dateFormatter';
import { useAppointmentQuery } from '../../lib/dataClient/useAppointmentQuery';
import { Appointment } from '../../lib/dataTypes/appointments';
import { useSalonQuery } from '../../lib/dataClient/useSalonQuery';
import { Salon } from '../../lib/dataTypes/salon';
import { ScheduleArray } from '../../utils/schedule';
import moment from 'moment';
import { getProfileClient } from '../../lib/getProfileClient';
import Loader from '../../components/Loader';
import { FashProfile } from '../../lib/dataTypes/fashProfile';

interface Schedule {
    start: string;
    end: string;
}

const SchedulePage = () => {
    const date: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);

    const [selectedDate, setSelectedDate] = useState<Date>(date);
    const { getAppointmentsBySalonDate } = useAppointmentQuery();
    const { getOneSalonByOwnerEntioId } = useSalonQuery();
    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [hoursToShow, setHoursToShow] = useState<string[]>([]);
    const [clients, setClients] = useState<FashProfile[]>();
    const [salon, setSalon] = useState<Salon>();
    const [loading, setLoading] = useState<boolean>(true);
    const profileClient = getProfileClient();
    const getAppointments = async () => {
        if (salon && selectedDate) {
            try {
                const data = await getAppointmentsBySalonDate(salon?._id, selectedDate);
                setAppointments(() => data);
            } catch (err) {
                console.log(err);
            }
        }
    };
    const getSalonData = async () => {
        const userEntio = await profileClient.getProfile();

        try {
            const data = await getOneSalonByOwnerEntioId(userEntio?.data?.user.id);
            console.log(data);
            setSalon(() => data);
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    const salonInning = async (date: Date) => {
        console.log(date);
        let filteredHours = [...ScheduleArray];
        const day = moment(date).format('dddd');
        let schedule: Schedule | undefined;
        day === 'Monday'
            ? (schedule = salon?.timetable.monday)
            : day === 'Tuesday'
            ? (schedule = salon?.timetable.tuesday)
            : day === 'Wednesday'
            ? (schedule = salon?.timetable.wednesday)
            : day === 'Thursday'
            ? (schedule = salon?.timetable.thursday)
            : day === 'Friday'
            ? (schedule = salon?.timetable.friday)
            : day === 'Saturday'
            ? (schedule = salon?.timetable.saturday)
            : (schedule = salon?.timetable.sunday);
        const start = schedule?.start || '';
        const indexStart = filteredHours.findIndex((e: string) =>
            e.startsWith('T') ? e.slice(1).startsWith(start) : e.startsWith(start)
        );
        if (moment(selectedDate).isBefore(moment().subtract(1, 'day'))) {
            filteredHours = ['Fecha no válida'];
            setHoursToShow(() => filteredHours);
            return;
        }
        const end = schedule?.end || '';
        const indexEnd = filteredHours.findIndex((e: string) => e.endsWith(end));
        if (end && start) {
            setHoursToShow(() => filteredHours.slice(indexStart, indexEnd + 1));
        } else {
            setHoursToShow(() => []);
        }
    };
    const getClients = async () => {
        const clientsId = appointments.map((appointment) => appointment.client.entioId);
        const profiles = await profileClient.getProfiles(clientsId);
        //eslint-disable-next-line
        const profilesArray: FashProfile[] | any = [];
        for (const data in profiles.data) {
            profilesArray.push(profiles.data[data]);
        }
        setClients(() => profilesArray);
    };

    useEffect(() => {
        getSalonData();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        setSelectedDate(() => date);
        salonInning(selectedDate);
        getAppointments();
        //eslint-disable-next-line
    }, [salon]);
    useEffect(() => {
        if (selectedDate) {
            salonInning(selectedDate);
            getAppointments();
        }
        //eslint-disable-next-line
    }, [selectedDate]);

    useEffect(() => {
        getClients();
        //eslint-disable-next-line
    }, [appointments]);
    return (
        <IonPage>
            {loading ? (
                <Loader />
            ) : (
                <IonContent>
                    <IonFab
                        slot="fixed"
                        className="bottom-4 right-4 bg-primaryColor w-14 h-14 rounded-[4px] shadow-md text-center text-white text-6xl font-normal flex justify-center items-center">
                        <a href={`/owner/schedule/add`}>+</a>
                    </IonFab>
                    <Header title="Agenda" left={<MenuIcon />} right={<SettingsIcon />} menuRequire />
                    <div className="px-4 py-8 flex flex-col gap-7 w-full max-w-[420px] mx-auto">
                        <p className="text-center text-primaryColor">
                            {hoursToShow.length > 1
                                ? ' '
                                : 'Selecciona una fecha en el calendario para mostrar los turnos'}{' '}
                        </p>
                        <Calendar onChange={setSelectedDate} value={selectedDate} className="m-2" />
                        <header className="bg-gray-100 text-center shadow-[0_1px_2px_rgb(0,0,0,0.3)] mx-2">
                            <h2 className="p-2 text-xl tracking-[-0.025px] text-primaryColor">
                                {selectedDate && toFullLongDate(selectedDate)}
                            </h2>
                        </header>
                        <section className="flex flex-col gap-4">
                            {hoursToShow &&
                                hoursToShow.map((hour, index) => (
                                    <article
                                        key={index}
                                        className="flex flex-shrink-0 justify-between items-center rounded-[4px] bg-primaryColor bg-opacity-[0.12] h-[10vh] outline outline-[#82385e] outline-2 mx-2">
                                        {hour === 'Fecha no válida' ? (
                                            <p className="py-1 px-3 w-full text-center self-center font-medium rounded-[4px] mx-2">
                                                {hour}
                                            </p>
                                        ) : (
                                            <p className="py-1 px-3 min-w-[114px] text-center bg-white border border-[#1F1F1F80] rounded-[4px] mx-2">
                                                {hour}
                                            </p>
                                        )}
                                        {hour !== 'Fecha no válida' && (
                                            <div className="flex h-[10vh] items-center justify-start w-[45%]">
                                                {appointments &&
                                                    appointments.map(
                                                        (appointment, index) =>
                                                            appointment.time.includes(hour) && (
                                                                <a
                                                                    key={index}
                                                                    href={`/owner/appointment/${appointment._id}`}
                                                                    className="w-1/4 outline outline-1 h-[95%] flex flex-col justify-around items-center p-1 bg-[#f3f3f3] rounded-[4px]">
                                                                    <img
                                                                        className="rounded-full h-[5vh] w-[5vh]"
                                                                        src="https://picsum.photos/200/300?random=1"
                                                                        alt=""
                                                                    />
                                                                    <p className="text-xs">
                                                                        {clients &&
                                                                            clients[index]?.profile?.firstName.split(
                                                                                ' '
                                                                            )[0]}
                                                                    </p>
                                                                </a>
                                                            )
                                                    )}
                                            </div>
                                        )}
                                    </article>
                                ))}
                        </section>
                    </div>
                </IonContent>
            )}
        </IonPage>
    );
};

export default SchedulePage;
