import React, { useState } from 'react';

type Props = {
    image: string;
    name: string;
    phone: string;
    email: string;
    id: string;
    deleteUser: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => Promise<void>;
};

const ClientDirectoryCardWithClose = ({ name, image, phone, email, deleteUser, id }: Props) => {
    const [activeDelete, setActiveDelete] = useState<boolean>(false);
    return (
        <div className="w-full bg-[#F5F5F5] mx-auto my-5 border border-1 border-[#7E747C] rounded-[12px] flex flex-col justify-around pl-4 items-center">
            <div className="w-full flex flex-row items-center">
                <img src={image} className="w-16 h-16 rounded-full" alt="client" />
                <div className="pl-4 py-1 font-monserrat">
                    <p className="text-[#82385E] text-[20px] font-medium leading-6 mb-1 tracking-tight">{name}</p>
                    <p className="text-[14px] leading-6 tracking-tight">Teléfono: {phone}</p>
                    <p className="text-[14px] leading-6 tracking-tight">Correo: {email.substring(0, 12)}...</p>
                    <div className="flex w-full justify-around items-center">
                        <a href={`/owner/worker`} className="tracking-tight text-yellow-500 text-xs">
                            Ver más
                        </a>
                        <button
                            onClick={() => setActiveDelete(true)}
                            // onClick={(e) => deleteUser(e, id)}
                            className="py-1 px-3 rounded-full h-full text-xs text-red-500 mx-1">
                            Eliminar
                        </button>
                    </div>
                </div>
            </div>
            {activeDelete && (
                <div className="bg-slate-800 bg-opacity-50 flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 font-montserrat">
                    <div className="bg-white px-12 py-10 rounded-md text-center font-montserrat">
                        <h1 className=" mb-4 font-bold text-slate-500 font-montserrat">Seguro que deseas eliminar?</h1>
                        <button
                            onClick={() => setActiveDelete(false)}
                            className="bg-gray-400 px-4 py-1 rounded-full text-white">
                            Cancelar
                        </button>
                        <button
                            onClick={(e) => [deleteUser(e, id), setActiveDelete(false)]}
                            className="bg-primaryColor px-7 py-1 ml-2 rounded-full  text-white font-semibold">
                            Ok
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClientDirectoryCardWithClose;
