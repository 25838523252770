interface Tt {
    friday: D;
    monday: D;
    saturday: D;
    sunday: D;
    thursday: D;
    tuesday: D;
    wednesday: D;
}
interface D {
    end: string;
    start: string;
}
const getFirstDay = function (workweek: Tt) {
    type Key = keyof typeof workweek;
    let startDay = '';
    const days: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    for (let i = 0; i < days.length; i++) {
        if (
            Object.keys(workweek).includes(days[i]) &&
            workweek[days[i] as Key].start !== '' &&
            workweek[days[i] as Key].end !== '' &&
            startDay === ''
        ) {
            startDay =
                days[i] === 'monday'
                    ? 'Lunes'
                    : days[i] === 'tuesday'
                    ? 'Martes'
                    : days[i] === 'wednesday'
                    ? 'Miércoles'
                    : days[i] === 'thursday'
                    ? 'Jueves'
                    : days[i] === 'friday'
                    ? 'Viernes'
                    : days[i] === 'saturday'
                    ? 'Sábado'
                    : days[i] === 'sunday'
                    ? 'domingo'
                    : 'No establecido';
        }
    }
    return startDay;
};

export { getFirstDay };
