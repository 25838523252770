const readFileAsBase64 = async (file: Blob) => {
    const dataUrl = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

    console.log(dataUrl.substring(0, 40));
    const base64File = dataUrl.substring(dataUrl.indexOf(';base64,') + 8);
    console.log(base64File.substring(0, 20));

    return base64File;
};

export default readFileAsBase64;
