export const ScheduleArray = [
    '00:00 a 00:30',
    '00:30 a 01:00',
    '01:00 a 01:30',
    '01:30 a 02:00',
    '02:00 a 02:30',
    '02:30 a 03:00',
    '03:00 a 03:30',
    '03:30 a 04:00',
    '04:00 a 04:30',
    '04:30 a 05:00',
    '05:00 a 05:30',
    '05:30 a 06:00',
    '06:00 a 06:30',
    '06:30 a 07:00',
    '07:00 a 07:30',
    '07:30 a 08:00',
    '08:00 a 00:30',
    '08:30 a 09:00',
    '09:00 a 09:30',
    '09:30 a 10:00',
    '10:00 a 10:30',
    '10:30 a 11:00',
    '11:00 a 11:30',
    '11:30 a 12:00',
    '12:00 a 12:30',
    '12:30 a 13:00',
    '13:00 a 13:30',
    '13:30 a 14:00',
    '14:00 a 14:30',
    '14:30 a 15:00',
    '15:00 a 15:30',
    '15:30 a 16:00',
    '16:00 a 16:30',
    '16:30 a 17:00',
    '17:00 a 17:30',
    '17:30 a 18:00',
    '18:00 a 18:30',
    '18:30 a 19:00',
    '19:00 a 19:30',
    '19:30 a 20:00',
    '20:00 a 20:30',
    '20:30 a 21:00',
    '21:00 a 21:30',
    '21:30 a 22:00',
    '22:00 a 22:30',
    '22:30 a 23:00',
    '23:00 a 23:30',
    '23:30 a 00:00',
];
