import { gql, useMutation } from '@apollo/client';
export const useServicePerSalonUpdate = () => {
    const [updateOneSalonServices] = useMutation(gql`
        mutation updateOneServicePerSalon($id: ObjectId!, $price: String!, $duration: String!) {
            updateOneServicePerSalon(query: { _id: $id }, set: { price: $price, duration: $duration }) {
                _id
            }
        }
    `);

    return async (id: string, price: string, duration: string) => {
        try {
            const result = await updateOneSalonServices({ variables: { id, price, duration } });
            console.log(result.data);
            return result.data.updateOneServicePerSalon;
        } catch (err) {
            console.log(err);
        }
    };
};
