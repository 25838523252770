import React from 'react';

const ManSittingIconWhite = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.59 4.41C2.81 3.63 2.81 2.36 3.59 1.58C4.37 0.799995 5.64 0.799995 6.42 1.58C7.2 2.36 7.2 3.63 6.42 4.41C5.63 5.2 4.37 5.2 3.59 4.41ZM2 15V7C2 6.45 1.55 6 1 6C0.45 6 0 6.45 0 7V15C0 17.76 2.24 20 5 20H10C10.55 20 11 19.55 11 19C11 18.45 10.55 18 10 18H5C3.34 18 2 16.66 2 15ZM15.28 18.35L11.51 14.58C11.14 14.21 10.63 14 10.1 14H7.5V10.32C8.59 11.21 10.16 12.02 11.7 12.34C12.37 12.48 13 11.98 13 11.3C13 10.77 12.61 10.34 12.08 10.25C10.66 10.01 9.2 9.24 8.33 8.27999L6.93 6.73C6.74 6.52 6.5 6.35 6.24 6.23C5.95 6.09 5.62 6 5.28 6H5.25C4.01 6 3 7.01 3 8.25V14C3 15.66 4.34 17 6 17H11.07L13.85 19.78C14.24 20.17 14.89 20.17 15.28 19.78C15.68 19.39 15.68 18.75 15.28 18.35Z"
                fill="white"
            />
        </svg>
    );
};

export default ManSittingIconWhite;
