import React, { SVGProps } from 'react';

const CashIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M20.333 21h4c.734 0 1.334-.6 1.334-1.333v-8c0-.734-.6-1.334-1.334-1.334h-4c-.733 0-1.333.6-1.333 1.334v8C19 20.4 19.6 21 20.333 21Zm1.334-8H23v5.333h-1.333V13Zm-9.334 8h4c.734 0 1.334-.6 1.334-1.333v-8c0-.734-.6-1.334-1.334-1.334h-4c-.733 0-1.333.6-1.333 1.334v8C11 20.4 11.6 21 12.333 21Zm1.334-8H15v5.333h-1.333V13Zm-5.334-2.667c-.733 0-1.333.6-1.333 1.334v8C7 20.4 7.6 21 8.333 21c.734 0 1.334-.6 1.334-1.333v-8c0-.734-.6-1.334-1.334-1.334ZM3 7.667v16c0 1.466 1.2 2.666 2.667 2.666H27c1.467 0 2.667-1.2 2.667-2.666v-16C29.667 6.2 28.467 5 27 5H5.667A2.675 2.675 0 0 0 3 7.667Zm22.667 16H7c-.733 0-1.333-.6-1.333-1.334V9c0-.733.6-1.333 1.333-1.333h18.667C26.4 7.667 27 8.267 27 9v13.333c0 .734-.6 1.334-1.333 1.334Z"
            fill="#82385E"
        />
    </svg>
);

export default CashIcon;
