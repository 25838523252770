import React from 'react';
import style from '../styles/loader.module.css';
const Loader = () => {
    return (
        <div className={style.container}>
            <div className={style.water}></div>
        </div>
    );
};

export default Loader;
