import React from 'react';
const CalendarIconWhite = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" fill="none" viewBox="0 0 18 21">
            <path
                fill="white"
                fillRule="evenodd"
                d="M16 2.5h-1v-2h-2v2H5v-2H3v2H2a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16H2v-11h14v11zm-12.5-7a2.5 2.5 0 015 0 2.5 2.5 0 01-5 0z"
                clipRule="evenodd"></path>
        </svg>
    );
};

export default CalendarIconWhite;
