import React from 'react';
import { InstagramIcon, WhatsAppIcon, CallIcon } from '../assets/icons';
import { Salon } from '../lib/dataTypes/salon';

interface Props {
    salon: Salon;
}
const ServicesSalon = (props: Props) => {
    const timetable = props.salon?.timetable;
    return (
        <div className="m-auto rounded-2xl">
            <p className="bg-gray-100 p-2 flex flex-row w-full items-center justify-center font-medium font-montserrat text-3xl tracking-wider text-primaryColor">
                Servicios
            </p>
            <div className="w-full flex flex-row flex-wrap justify-evenly py-2 bg-white">
                {props.salon?.services.map((services, index) => (
                    <div
                        key={index}
                        className="flex flex-wrap justify-center content-between items-center cursor-pointer w-[30%] h-[110px] bg-secondaryColor border-2 rounded-xl m-1 p-1">
                        <p className="font-montserrat text-sm font-medium text-center">
                            {services.service.name.length > 10
                                ? services.service.name.substring(0, 10) + '...'
                                : services.service.name}
                        </p>
                        <div className="flex flex-col justify-around items-center">
                            <img
                                src={`data:${services.service.img?.blob?.mimeType};base64,${services.service.img?.blob?.data}`}
                                alt="img_services"
                                className="w-8 h-8"
                            />
                            <p className="font-montserrat text-xl font-thin text-primaryColor">{services.price}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="bg-gray-100 rounded-b-3xl w-full">
                <div className="flex flex-col items-center">
                    <div className="flex flex-col justify-center items-start p-2 pr-0 w-5/6">
                        <p className="font-montserrat text-[18px]">Dias y horarios de atencion:</p>
                        {timetable?.monday.start !== timetable.monday.end ? (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Lunes</p>
                                <p className="font-montserrat text-[14px]">
                                    {timetable?.monday.start} a {timetable?.monday.end}{' '}
                                </p>
                            </div>
                        ) : (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Lunes</p>

                                <p className="font-montserrat text-[14px]">Cerrado</p>
                            </div>
                        )}
                        {timetable?.tuesday.start !== timetable.thursday.end ? (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Martes</p>
                                <p className="font-montserrat text-[14px]">
                                    {timetable?.tuesday.start} a {timetable?.tuesday.end}{' '}
                                </p>
                            </div>
                        ) : (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Martes</p>

                                <p className="font-montserrat text-[14px]">Cerrado</p>
                            </div>
                        )}
                        {timetable?.wednesday.start !== timetable.wednesday.end ? (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Miercoles</p>
                                <p className="font-montserrat text-[14px]">
                                    {timetable?.wednesday.start} a {timetable?.wednesday.end}{' '}
                                </p>
                            </div>
                        ) : (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Miercoles</p>

                                <p className="font-montserrat text-[14px]">Cerrado</p>
                            </div>
                        )}
                        {timetable?.thursday.start !== timetable.thursday.end ? (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Jueves</p>
                                <p className="font-montserrat text-[14px]">
                                    {timetable?.thursday.start} a {timetable?.thursday.end}{' '}
                                </p>
                            </div>
                        ) : (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Jueves</p>

                                <p className="font-montserrat text-[14px]">Cerrado</p>
                            </div>
                        )}
                        {timetable?.friday.start !== timetable.friday.end ? (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Viernes</p>
                                <p className="font-montserrat text-[14px]">
                                    {timetable?.friday.start} a {timetable?.friday.end}{' '}
                                </p>
                            </div>
                        ) : (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Viernes</p>

                                <p className="font-montserrat text-[14px]">Cerrado</p>
                            </div>
                        )}
                        {timetable?.saturday.start !== timetable.saturday.end ? (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Sabado</p>
                                <p className="font-montserrat text-[14px]">
                                    {timetable?.saturday.start} a {timetable?.saturday.end}{' '}
                                </p>
                            </div>
                        ) : (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Sabado</p>

                                <p className="font-montserrat text-[14px]">Cerrado</p>
                            </div>
                        )}
                        {timetable?.sunday.start !== timetable.sunday.end ? (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Domingo</p>
                                <p className="font-montserrat text-[14px]">
                                    {timetable?.sunday.start} a {timetable?.sunday.end}{' '}
                                </p>
                            </div>
                        ) : (
                            <div className="flex justify-between w-full">
                                <p className="font-montserrat text-[14px]">Domingo</p>

                                <p className="font-montserrat text-[14px]">Cerrado</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-span-2 row-span-2 flex flex-row justify-evenly items-center flex-wrap">
                    {props.salon.igUrl && (
                        <a href={`https://instagram.com/${props.salon?.igUrl}`}>
                            <InstagramIcon />
                        </a>
                    )}
                    {props.salon.whatsApp && (
                        <a href={`https://wa.me/${props.salon?.phoneNumber}`}>
                            <WhatsAppIcon />
                        </a>
                    )}
                    {props.salon.phoneNumber && (
                        <a href={`tel: ${props.salon?.phoneNumber}`}>
                            <CallIcon />
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ServicesSalon;
