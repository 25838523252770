import React, { SVGProps } from 'react';

const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M25.907 17.307C25.96 16.88 26 16.453 26 16c0-.453-.04-.88-.093-1.307l2.813-2.2c.253-.2.32-.56.16-.853l-2.667-4.613a.67.67 0 0 0-.813-.294l-3.32 1.334a9.74 9.74 0 0 0-2.253-1.307l-.507-3.533a.65.65 0 0 0-.653-.56h-5.334a.65.65 0 0 0-.653.56l-.507 3.533c-.813.333-1.56.787-2.253 1.307L6.6 6.733a.65.65 0 0 0-.813.294L3.12 11.64a.657.657 0 0 0 .16.853l2.813 2.2C6.04 15.12 6 15.56 6 16c0 .44.04.88.093 1.307l-2.813 2.2c-.253.2-.32.56-.16.853l2.667 4.613c.16.294.52.4.813.294l3.32-1.334a9.74 9.74 0 0 0 2.253 1.307l.507 3.533c.04.32.32.56.653.56h5.334a.65.65 0 0 0 .653-.56l.507-3.533c.813-.333 1.56-.787 2.253-1.307l3.32 1.334a.65.65 0 0 0 .813-.294l2.667-4.613a.673.673 0 0 0-.16-.853l-2.813-2.2ZM16 20.667A4.672 4.672 0 0 1 11.333 16 4.672 4.672 0 0 1 16 11.333 4.672 4.672 0 0 1 20.667 16 4.672 4.672 0 0 1 16 20.667Z"
            fill="#82385E"
        />
    </svg>
);

export default SettingsIcon;
