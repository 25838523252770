import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useIonToast } from '@ionic/react';
import { ResponseType, UserDataResult } from 'entio-sdk';
import { login } from '../../reducers/AuthReducer';
import { useAppDispatch } from '../../app/hooks';
import { useHistory } from 'react-router-dom';
import getAuthenticationClient from '../../lib/getAuthenticationClient';
import { InfoIcon, FashadminLogo } from '../../assets/icons';
import getRealmUser from '../../lib/getRealmUser';
import Loader from '../../components/Loader';

const LoginPage = () => {
    const history = useHistory();
    const [toast] = useIonToast();
    const [loader, setLoader] = useState<boolean>(false);
    const [user, setUser] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const dispatch = useAppDispatch();

    const onUserChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setUser(event.target.value || '');
    };

    const onPasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value || '');
    };

    //eslint-disable-next-line
    const onLoginSuccess = async (data: UserDataResult<any>) => {
        console.log(data);
        const sessionState = {
            token: data.token,
            user: data.user,
            profile: data.profile,
            rol: data.profile.rol,
        };
        dispatch(login(sessionState));
    };

    const onLoginError = async (error: string) => {
        await toast({
            color: 'danger',
            position: 'top',
            message: error,
            duration: 2000,
            cssClass:
                'absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-red-500 text-white rounded-lg p-4',
        });
    };

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoader(() => true);
        const loginResponse = await getAuthenticationClient().signIn(user, password, ResponseType.TOKEN);

        if (!loginResponse.success) {
            onLoginError(loginResponse.message).then();
            setLoader(() => false);
            return;
        }
        onLoginSuccess(loginResponse.data).then();
        localStorage.setItem('token', loginResponse.data.token);
        await getRealmUser();
        const currentProfile = JSON.parse(localStorage.getItem('currentProfile') || '{}');
        if (currentProfile?.role?.includes('FASH.OWNER')) {
            history.push('/owner/profile');
        } else if (currentProfile?.role?.includes('FASH.STYLIST')) {
            history.push('/stylist/profile');
        } else {
            history.push('/clients/profile');
        }
    };

    useEffect(() => {
        localStorage.clear();
    }, []);

    return (
        <>
            {loader ? (
                <Loader />
            ) : (
                <div className="w-full font-monserrat">
                    <div className="flex justify-end mt-4 mr-6 mb-10">
                        <button>
                            <InfoIcon />
                        </button>
                    </div>
                    <div className="flex justify-center mb-14">
                        <FashadminLogo />
                    </div>
                    <div className="flex justify-center mb-12">
                        <p className="font-medium text-[20px]">Iniciar sesión</p>
                    </div>
                    <form onSubmit={(e) => onSubmit(e)}>
                        <div className="flex justify-center w-full pb-5">
                            <div className="flex flex-col w-4/6">
                                <div className="relative">
                                    <div className="absolute -bottom-3 left-2">
                                        <label htmlFor="userName" className="text-[#82385E] bg-white px-1">
                                            User name
                                        </label>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <input
                                        type="text"
                                        name="userName"
                                        id="userName"
                                        onChange={(e) => onUserChanged(e)}
                                        className="border rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center w-full mb-20">
                            <div className="flex flex-col w-4/6">
                                <div className="relative">
                                    <div className="absolute -bottom-3 left-2">
                                        <label htmlFor="userName" className="text-[#82385E] bg-white px-1">
                                            Contraseña
                                        </label>
                                    </div>
                                </div>
                                <div className="flex justify-center mb-1">
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        onChange={(e) => onPasswordChanged(e)}
                                        className="border rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1  w-full"
                                    />
                                </div>
                                <div className="flex justify-start">
                                    <p className="text-[#B7990D] underline">Recuperar contraseña</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center w-full">
                            <div className="flex flex-col">
                                <div className="flex justify-center w-full mb-4">
                                    <input
                                        type="submit"
                                        className="rounded-full bg-[#82385E] text-white font-semibold px-6 py-2"
                                        value="Iniciar sesión"
                                    />
                                </div>
                                <div className="flex">
                                    <p> ¿Es tu primera vez aquí?</p>
                                    <a href="/register" className="text-[#B7990D] underline pl-2">
                                        Crea tu cuenta
                                    </a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

export default LoginPage;
