import React, { SVGProps } from 'react';

const StylistIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={30} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M26.2 3.2h-6.688C18.84 1.344 17.08 0 15 0c-2.08 0-3.84 1.344-4.512 3.2H3.8A3.21 3.21 0 0 0 .6 6.4v22.4c0 1.76 1.44 3.2 3.2 3.2h22.4c1.76 0 3.2-1.44 3.2-3.2V6.4c0-1.76-1.44-3.2-3.2-3.2ZM15 3.2c.88 0 1.6.72 1.6 1.6 0 .88-.72 1.6-1.6 1.6-.88 0-1.6-.72-1.6-1.6 0-.88.72-1.6 1.6-1.6Zm0 6.4c2.656 0 4.8 2.144 4.8 4.8 0 2.656-2.144 4.8-4.8 4.8a4.794 4.794 0 0 1-4.8-4.8c0-2.656 2.144-4.8 4.8-4.8Zm9.6 19.2H5.4v-2.24c0-3.2 6.4-4.96 9.6-4.96 3.2 0 9.6 1.76 9.6 4.96v2.24Z"
            fill="#82385E"
        />
    </svg>
);

export default StylistIcon;
