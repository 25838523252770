import React from 'react';

const InfoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28">
            <path
                fill="#82385E"
                d="M14 .667C6.64.667.667 6.64.667 14S6.64 27.333 14 27.333 27.333 21.36 27.333 14 21.36.667 14 .667zm0 20c-.733 0-1.333-.6-1.333-1.334V14c0-.733.6-1.333 1.333-1.333s1.333.6 1.333 1.333v5.333c0 .734-.6 1.334-1.333 1.334zM15.333 10h-2.666V7.333h2.666V10z"></path>
        </svg>
    );
};

export default InfoIcon;
