import React, { useEffect, useState } from 'react';
import { CopyIcon, EditIcon, MenuIcon } from '../../assets/icons';
import { ClientCard, Header } from '../../components';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import { useAppointmentQuery } from '../../lib/dataClient/useAppointmentQuery';
import { FashProfile } from '../../lib/dataTypes/fashProfile';
import { Appointment } from '../../lib/dataTypes/appointments';
import { getProfileClient } from '../../lib/getProfileClient';
const ProfilePage = () => {
    const { getAppointmentsByStylistEntioId } = useAppointmentQuery();
    const [userProfile, setUserProfile] = useState<FashProfile>();
    const [userAppointments, setUserAppointments] = useState<Appointment[]>([]);
    const profileClient = getProfileClient();
    const getUserData = async () => {
        const profile = await profileClient.getProfile();
        setUserProfile(() => profile.data);
        const appointments = await getAppointmentsByStylistEntioId(profile.data.profile.user);
        setUserAppointments(() => appointments);
        console.log(appointments);
        // setUserAppointments(() => appointments);
    };
    const copyToClipBoard = () => {
        if (userProfile) {
            copy(userProfile.profile.user);
        }
        alert('Copied to clipboard');
    };
    useEffect(() => {
        getUserData();
        //eslint-disable-next-line
    }, []);

    const [profile, setProfile] = useState({
        imgUrl: '',
        name: '',
        mailOrPhone: '',
        birthDate: '',
    });
    const [emailError, setEmailError] = useState(false);
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.id === 'birthDate') {
            setProfile(() => ({ ...profile, [e.target.id]: moment(e.target.value).format('DD/MM/YYYY') }));
        }
        if (e.target.id === 'mailOrPhone') {
            if (emailValidate(e.target.value)) {
                setProfile(() => ({ ...profile, [e.target.id]: e.target.value }));
                setEmailError(false);
            } else {
                setEmailError(() => true);
            }
        }
        setProfile(() => ({ ...profile, [e.target.id]: e.target.value }));
    };

    const emailValidate = (string: string) => {
        if (/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/.test(string)) {
            return true;
        } else {
            return false;
        }
    };
    return (
        <div className="flex flex-col items-center font-primaryFont h-full overflow-scroll">
            <Header title="Mi Perfil" left={<MenuIcon />} right={''} menuRequire />
            <div className="flex justify-center my-7">
                <div className="relative h-[130px] rounded-full">
                    <img
                        src={
                            userProfile?.profile.img?.url
                                ? userProfile?.profile.img?.url
                                : userProfile?.profile.img?.blob.data
                                ? `data:${userProfile.profile.img?.blob.mimetype};base64,${userProfile.profile.img?.blob.data}`
                                : 'https://picsum.photos/200/300?random=1'
                        }
                        alt="profileImg"
                        className="h-full w-[130px] rounded-full"
                    />
                    <div className="flex justify-end">
                        <div
                            className="bg-[#82385E] rounded-full p-2 absolute top-0 -right-2 cursor-pointer"
                            onClick={() => {
                                console.log('boton clickeado');
                            }}>
                            <EditIcon />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-[85%] flex flex-col items-center">
                <div className="w-full flex flex-col items-start relative">
                    <label
                        htmlFor="Link"
                        className="text-[18px] relative top-3 left-3 bg-white pl-1 pr-1 text-center focus:text-primaryColor">
                        Link de Estilista
                    </label>
                    <input
                        className="outline outline-1 outline-gray-400 w-full p-2 rounded-[4px] h-[56px] text-[18px] focus:outline-primaryColor focus:text-primaryColor"
                        type="text"
                        defaultValue={userProfile?.profile.user}
                        id="Link"
                    />
                    <button
                        onClick={copyToClipBoard}
                        className="absolute right-2 top-10 p-2 flex justify-center items-center rounded-full bg-primaryColor">
                        <CopyIcon />
                    </button>
                </div>
                <div className="w-full flex flex-col items-start">
                    <label
                        htmlFor="name"
                        className="text-[18px] relative top-3 left-3 bg-white pl-1 pr-1 text-center focus:text-primaryColor">
                        Nombre
                    </label>
                    <input
                        className="outline outline-1 outline-gray-400 w-full p-2 rounded-[4px] h-[56px] text-[18px] focus:outline-primaryColor focus:text-primaryColor"
                        type="text"
                        defaultValue={userProfile?.profile.firstName}
                        id="name"
                        onChange={onChange}
                    />
                </div>
                <div className="w-full flex flex-col items-start">
                    <label
                        htmlFor="mailOrPhone"
                        className="text-[18px] relative top-3 left-3 bg-white text-center pl-1 pr-1">
                        Correo
                    </label>
                    <input
                        className="outline outline-1 outline-gray-400 w-full p-2 rounded-[4px] h-[56px] text-[18px] focus:outline-primaryColor focus:text-primaryColor"
                        type="text"
                        id="mailOrPhone"
                        defaultValue={userProfile?.user.email}
                        onChange={onChange}></input>
                    {emailError && <p className="text-[14px] text-red-400 mt-2">*El email ingresado no es correcto</p>}
                </div>
                <div className="w-full flex flex-col items-start mb-1">
                    <p className="text-[18px] relative top-3 left-3 bg-white text-center pl-1 pr-1">Teléfono</p>
                    <input
                        className="outline outline-1 outline-gray-400 w-full p-2 rounded-[4px] h-[56px] text-[18px] focus:outline-primaryColor focus:text-primaryColor"
                        type="Text"
                        id="phone"
                        defaultValue={userProfile?.user.phone}
                        onChange={onChange}></input>
                </div>
                <div className="w-full flex flex-col items-start mb-1">
                    <p className="text-[18px] relative top-3 left-3 bg-white text-center pl-1 pr-1">Cumpleaños *</p>
                    <input
                        className="outline outline-1 outline-gray-400 w-full p-2 rounded-[4px] h-[56px] text-[18px] focus:outline-primaryColor focus:text-primaryColor"
                        type="Date"
                        id="birthDate"
                        defaultValue={userProfile?.profile.birthDate}
                        onChange={onChange}></input>
                    <p className="text-[14px] text-gray-400 mt-2">*Formato dia/mes/año</p>
                </div>
            </div>
            <div className="w-full flex flex-col items-center mt-5">
                <h2 className="bg-secondaryColor text-[24px] w-[93%] h-11 text-center text-primaryColor shadow-[0_4px_8px_3px_rgba(0,0,0,0.15)] shadow-[0_1px_3px_rgba(0,0,0,0.3)] flex flex-col justify-center mb-1">
                    Mis Clientes
                </h2>
                <div className="w-[85%] flex flex-col items-center mt-4 mb-4 ">
                    {userAppointments ? (
                        userAppointments.map((appointment, i) => <ClientCard appointment={appointment} key={i} />)
                    ) : (
                        <p>Hoy no tienes citas</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
