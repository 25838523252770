import { gql, useMutation } from '@apollo/client';

interface day {
    start: string;
    end: string;
}
export const useTimeTableCreate = () => {
    const [create] = useMutation(gql`
        mutation CreateTimetable(
            $monday: TimeTableMondayInsertInput
            $tuesday: TimeTableTuesdayInsertInput
            $wednesday: TimeTableWednesdayInsertInput
            $thursday: TimeTableThursdayInsertInput
            $friday: TimeTableFridayInsertInput
            $saturday: TimeTableSaturdayInsertInput
            $sunday: TimeTableSundayInsertInput
        ) {
            insertOneTimeTable(
                data: {
                    monday: $monday
                    tuesday: $tuesday
                    wednesday: $wednesday
                    thursday: $thursday
                    friday: $friday
                    saturday: $saturday
                    sunday: $sunday
                }
            ) {
                _id
            }
        }
    `);

    return async (data: {
        monday: day;
        tuesday: day;
        wednesday: day;
        thursday: day;
        friday: day;
        saturday: day;
        sunday: day;
    }) => {
        try {
            const response = await create({
                variables: {
                    monday: data.monday,
                    tuesday: data.tuesday,
                    wednesday: data.wednesday,
                    thursday: data.thursday,
                    friday: data.friday,
                    saturday: data.saturday,
                    sunday: data.sunday,
                },
            });
            return response?.data?.insertOneTimeTable;
        } catch (err) {
            console.log(err);
        }
    };
};
