import { IonContent, IonPage } from '@ionic/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { MenuIcon, SearchIcon, SettingsIcon } from '../../assets/icons';
import { Calendar, Header } from '../../components';
import { useSalonQuery } from '../../lib/dataClient/useSalonQuery';
import { Salon } from '../../lib/dataTypes/salon';
import { ScheduleArray } from '../../utils/schedule';
import { useAppointmentCreate } from '../../lib/dataClient/useAppointmentCreate';
import { useAppointmentQuery } from '../../lib/dataClient/useAppointmentQuery';
import { useSalonUpdate } from '../../lib/dataClient/useSalonUpdate';
import { ServicesPerSalon } from '../../lib/dataTypes/services';
import { getProfileClient } from '../../lib/getProfileClient';
import { FashProfile } from '../../lib/dataTypes/fashProfile';
import { useUserQuery } from '../../lib/dataClient/useUserQuery';
import Loader from '../../components/Loader';
import { findAvailableIntervals } from '../../utils/findAvailableIntervals';
interface Schedule {
    start: string;
    end: string;
}

const CreateAppointmentPage = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [salon, setSalon] = useState<Salon>();
    const [loading, setLoading] = useState<boolean>(true);
    const { getOneSalonByOwnerEntioId } = useSalonQuery();
    const [inputSearch, setInputSearch] = useState('');
    const [selectedService, setSelectedService] = useState<ServicesPerSalon[]>([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedWorker, setSelectedWorker] = useState<string>('');
    const [hoursToShowBackup, setHoursToShowBackup] = useState<string[]>([]);
    const [hoursToShow, setHoursToShow] = useState<string[]>([]);
    const [selectedHour, setSelectedHour] = useState<string[]>([]);
    const [regularClients, setRegularClients] = useState<FashProfile[]>([]);
    const [filteredClients, setFilteredClients] = useState<FashProfile[]>([]);
    const [workers, setWorkers] = useState<FashProfile[]>();
    const profileClient = getProfileClient();
    const { getUserByEntioId } = useUserQuery();
    const [error, setError] = useState('');
    const [submitError, setSubmitError] = useState('');
    const appointmentCreate = useAppointmentCreate();
    const { getAppointmentsByStylist } = useAppointmentQuery();
    const { updateRegularClients } = useSalonUpdate();
    const salonData = async () => {
        const owner = await profileClient.getProfile();
        if (owner.data) {
            try {
                const data = await getOneSalonByOwnerEntioId(owner.data.user.id);
                setSalon(() => data);
            } catch (err) {
                console.log(err);
            }
            setLoading(false);
        }
    };

    const getWorkers = async () => {
        if (salon) {
            const workersId = salon?.workers.map((worker) => worker.entioId);
            const profiles = await profileClient.getProfiles(workersId);
            //eslint-disable-next-line
            const workersArray: any = [];
            for (const data in profiles.data) {
                workersArray.push(profiles.data[data]);
            }
            setWorkers(() => workersArray);
        }
    };

    const getSelectedUser = async (id: string) => {
        const worker = await getUserByEntioId(id);
        console.log(worker);
        if (worker) {
            return worker._id;
        }
    };
    const selectService = (service: ServicesPerSalon) => {
        const servicePrice = Number(service.price);
        let currentPrice: number;
        if (selectedService.some((e) => e.service._id === service.service._id)) {
            const serviceFiltered = selectedService.filter((e) => e.service._id !== service.service._id);
            setSelectedService(() => serviceFiltered);
            setHoursToShow(hoursToShowBackup);
            currentPrice = totalPrice - servicePrice;
            setTotalPrice(() => currentPrice);
        } else {
            setSelectedService(() => [...selectedService, service]);
            currentPrice = totalPrice + servicePrice;
            setTotalPrice(() => currentPrice);
        }
    };

    const salonInning = async () => {
        if (!selectedWorker || selectedDate.getHours() !== 0) {
            setError(() => 'Selecciona fecha y trabajador para mostrar los horarios');
            return;
        }
        setError(() => '');
        if (salon) {
            const appointmentsArray = await getAppointmentsByStylist(salon?._id, selectedDate, selectedWorker);
            let filteredHours = [...ScheduleArray];
            const day = moment(selectedDate).format('dddd');
            let schedule: Schedule | undefined;
            day === 'Monday'
                ? (schedule = salon?.timetable.monday)
                : day === 'Tuesday'
                ? (schedule = salon?.timetable.tuesday)
                : day === 'Wednesday'
                ? (schedule = salon?.timetable.wednesday)
                : day === 'Thursday'
                ? (schedule = salon?.timetable.thursday)
                : day === 'Friday'
                ? (schedule = salon?.timetable.friday)
                : day === 'Saturday'
                ? (schedule = salon?.timetable.saturday)
                : (schedule = salon?.timetable.sunday);
            const start = schedule?.start || '';
            const indexStart = filteredHours.findIndex((e: string) =>
                e.startsWith('T') ? e.slice(1).startsWith(start) : e.startsWith(start)
            );
            if (moment(selectedDate).isBefore(moment().subtract(1, 'day'))) {
                filteredHours = ['Invalid date'];
                setHoursToShow(() => filteredHours);
                setHoursToShowBackup(() => filteredHours);
                return;
            }
            if (moment(selectedDate).hours() > 0) {
                filteredHours = ['Invalid hours'];
                setHoursToShow(() => filteredHours);
                setHoursToShowBackup(() => filteredHours);
                return;
            }
            if (appointmentsArray && appointmentsArray.length) {
                for (let i = 0; i < filteredHours.length; i++) {
                    for (let j = 0; j < appointmentsArray.length; j++) {
                        for (let y = 0; y < appointmentsArray[j].time.length; y++) {
                            if (filteredHours[i] === appointmentsArray[j].time[y]) {
                                filteredHours[i] = 'T' + filteredHours[i];
                            }
                        }
                    }
                }
            }

            console.log(filteredHours);

            const end = schedule?.end || '';
            const indexEnd = filteredHours.findIndex((e: string) => e.endsWith(end));
            if (end && start) {
                setHoursToShow(() => filteredHours.slice(indexStart, indexEnd + 1));
                setHoursToShowBackup((prev) => filteredHours);
            } else {
                setHoursToShow(() => []);
                setHoursToShowBackup(() => []);
            }
        }
    };

    const onSubmit = async () => {
        document.getElementById('btn-schedule')?.setAttribute('disabled', 'true');
        if (salon) {
            const clients = salon.regularClients.map((e) => e._id);
            const data = {
                client: await getSelectedUser(selectedClient),
                salon: salon._id,
                stylist: await getSelectedUser(selectedWorker),
                service: selectedService.map((s) => s.service._id),
                servicePerSalon: selectedService,
                price: totalPrice.toString() + '.00',
                date: selectedDate,
                time: selectedHour,
            };
            const yesterday = moment().subtract(1, 'day');
            let error = '';
            !data.salon
                ? (error = 'No existe salon')
                : !data.client
                ? (error = 'Usted no esta registrado')
                : data.servicePerSalon.length === 0
                ? (error = 'Debes seleccionar un servicio')
                : !data.time
                ? (error = 'Debes seleccionar un turno')
                : hoursToShow.indexOf(data.time[0]) === -1
                ? (error = 'El horario elegido es incorrecto')
                : data.stylist === ''
                ? (error = 'Debes seleccionar un estilista')
                : !moment(data.date).isAfter(yesterday)
                ? (error = 'La fecha seleccionada no es correcta')
                : (error = '');
            if (error === '') {
                setSubmitError(() => '');
                try {
                    const index = clients.indexOf(data.client);
                    if (index === -1) {
                        clients.push(data.client);
                        await updateRegularClients(salon._id, clients);
                    }
                    await appointmentCreate(data);
                    salonInning();
                } catch (err) {
                    console.log(err);
                }
            } else {
                setSubmitError(() => error);
            }
        }
        document.getElementById('btn-schedule')?.setAttribute('disabled', 'false');
    };

    const getRegularClients = async () => {
        if (salon) {
            const clientsId = salon?.regularClients.map((client) => client.entioId);
            const profiles = await profileClient.getProfiles(clientsId);
            //eslint-disable-next-line
            const clientsArray: any = [];
            for (const data in profiles.data) {
                clientsArray.push(profiles.data[data]);
            }
            setRegularClients(() => clientsArray);
        }
    };

    const filterClients = (name: string) => {
        setInputSearch(() => name);
        if (name !== '') {
            const regularClientsCopy = [...regularClients];
            setFilteredClients(() =>
                regularClientsCopy.filter((client) =>
                    client.profile.firstName.toLowerCase().includes(inputSearch.toLowerCase())
                )
            );
        } else {
            setFilteredClients(() => []);
        }
    };

    const selectClient = (client: FashProfile) => {
        setSelectedClient(() => client.user.id);
        setFilteredClients(() => []);
        setInputSearch(() => client.profile.firstName);
    };

    useEffect(() => {
        salonData();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (selectedDate.toString().includes('00:00:00')) salonInning();
        //eslint-disable-next-line
    }, [selectedWorker]);

    useEffect(() => {
        getRegularClients();
        getWorkers();
        // eslint-disable-next-line
    }, [salon]);
    useEffect(() => {
        if (selectedService.length && hoursToShowBackup.length) {
            let totalDuration = 0;
            selectedService.forEach((s) => {
                const [hours, minutes] = s.duration.split(':').map(Number);
                totalDuration += (hours * 60 + minutes) / 30;
            });
            const avalaibleHours = findAvailableIntervals(totalDuration, hoursToShowBackup);
            setHoursToShow(avalaibleHours);
            setSelectedHour([]);
        }
        // eslint-disable-next-line
    }, [selectedService, hoursToShowBackup]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <IonPage>
                    <Header title="Nueva Cita" left={<MenuIcon />} right={<SettingsIcon />} menuRequire />
                    <IonContent>
                        <div className="flex flex-wrap justify-center w-full mx-auto mt-8">
                            <div className="w-full">
                                <div className="flex justify-center w-full pb-5">
                                    <div className="flex flex-col w-10/12">
                                        <div className="relative">
                                            <div className="absolute -bottom-3 left-2">
                                                <label
                                                    htmlFor="userName"
                                                    className="text-primaryColor font-semibold bg-white px-1">
                                                    Nombre del cliente
                                                </label>
                                            </div>
                                        </div>
                                        <div className="flex justify-center border-2 border-primaryColor rounded">
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                placeholder="Buscar..."
                                                name="userName"
                                                value={inputSearch}
                                                onChange={(e) => filterClients(e.target.value)}
                                                className="bg-transparent h-12 w-full focus:outline-none text-[18px] font-monserrat text-black pl-3"
                                            />
                                            <div className="pr-2 flex items-center">
                                                <SearchIcon />
                                            </div>
                                        </div>
                                        {filteredClients.length > 0 && (
                                            <div className="border-2 border-primaryColor rounded p-2">
                                                {filteredClients.map((client, index) => (
                                                    <div
                                                        className="flex items-center text-center font-monserrat hover:text-primaryColor cursor-pointer my-[2%] shadow-sm shadow-black bg-[#f3f3f3] text-xs px-2 h-[60px]"
                                                        key={index}
                                                        onClick={() => selectClient(client)}>
                                                        <img
                                                            src={
                                                                client?.profile.img?.url
                                                                    ? client?.profile.img?.url
                                                                    : client?.profile.img?.blob.data
                                                                    ? `data:${client.profile.img?.blob.mimetype};base64,${client.profile.img?.blob.data}`
                                                                    : 'https://picsum.photos/200/300?random=1'
                                                            }
                                                            alt="Not Found"
                                                            className="rounded-full w-[20%] h-[90%]"
                                                        />
                                                        <div className="flex flex-col text-center w-[80%]">
                                                            <p>{client.profile.firstName}</p>
                                                            <p>{client.user.email}</p>
                                                            <p>{client.user.phone}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="text-center bg-secondaryColor my-2 py-2 px-4 gap-2 shadow-md w-11/12 mx-auto">
                                    <h3 className="font-montserrat text-2xl text-primaryColor">{salon?.name}</h3>
                                </div>
                                <div className="text-center bg-secondaryColor my-2 py-2 px-4 gap-2 shadow-md w-11/12 mx-auto">
                                    <h3 className="font-montserrat text-2xl text-primaryColor">Servicios</h3>
                                </div>
                                <div>
                                    <div className="flex flex-wrap my-6 w-[95%] h-auto max-h-[35vh] p-1 justify-center gap-1 overflow-auto scrollbar-hidden">
                                        {salon ? (
                                            salon.services.map((service, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => selectService(service)}
                                                    className={
                                                        selectedService.some(
                                                            (e) => e.service._id === service.service._id
                                                        )
                                                            ? 'flex flex-wrap justify-center content-between items-center cursor-pointer w-[30%] h-[110px] bg-secondaryColor border-2 border-primaryColor rounded-xl m-1 p-1'
                                                            : 'flex flex-wrap justify-center content-between items-center cursor-pointer w-[30%] h-[110px] bg-secondaryColor border-2 rounded-xl m-1 p-1'
                                                    }>
                                                    <div className="w-full">
                                                        <h5 className="font-montserrat text-sm text-center">
                                                            {service.service.name}
                                                        </h5>
                                                    </div>
                                                    <div className="flex flex-col items-center w-full">
                                                        <img
                                                            src={`data:${service.service.img?.blob?.mimeType};base64,${service.service.img?.blob?.data}`}
                                                            alt="img_services"
                                                            className="w-8 h-8"
                                                        />
                                                        <p className="font-montserrat text-m text-center text-primaryColor">
                                                            $ {service.price}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <MenuIcon />
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-wrap justify-center my-6">
                                    <Calendar
                                        onChange={setSelectedDate}
                                        value={selectedDate}
                                        className="max-w-[360px]"
                                    />
                                </div>
                                <div className="text-center bg-secondaryColor my-2 py-2 px-4 gap-2 shadow-md w-11/12 mx-auto">
                                    <h3 className="font-montserrat text-2xl text-primaryColor">Estilistas</h3>
                                </div>
                                <div className="flex justify-center items-center my-6 w-11/12 flex-wrap overflow-auto scrollbar-hidden h-[20vh] bg-secondaryColor">
                                    {workers &&
                                        workers?.map((worker: FashProfile, index: number) => (
                                            <div
                                                key={index}
                                                onClick={() => setSelectedWorker(() => worker.user.id)}
                                                className={
                                                    selectedWorker === worker.user.id
                                                        ? 'flex flex-wrap justify-center content-between items-center w-[95px] h-[124px] cursor-pointer bg-secondaryColor border-2 border-primaryColor rounded-[4px] p-2 mx-2'
                                                        : 'flex flex-wrap justify-center content-between items-center w-[95px] h-[124px] cursor-pointer bg-secondaryColor border-2 rounded-[4px] p-2 mx-2'
                                                }>
                                                <div className="flex justify-center w-full">
                                                    <img
                                                        src={
                                                            worker?.profile.img?.url
                                                                ? worker?.profile.img?.url
                                                                : worker?.profile.img?.blob.data
                                                                ? `data:${worker.profile.img?.blob.mimetype};base64,${worker.profile.img?.blob.data}`
                                                                : 'https://picsum.photos/200/300?random=1'
                                                        }
                                                        alt="Not Found"
                                                        className="rounded-full w-[80%] h-[60px]"
                                                    />
                                                </div>
                                                <h5 className="font-montserrat text-sm text-center">
                                                    {worker.profile?.firstName?.length > 10
                                                        ? worker.profile?.firstName?.substring(0, 10) + '...'
                                                        : worker.profile?.firstName}
                                                </h5>
                                            </div>
                                        ))}
                                </div>
                                <div className="my-6 flex flex-col items-center w-full">
                                    <div className="flex flex-row w-full justify-around">
                                        <a
                                            href="/owner/schedule"
                                            className="font-montserrat py-2 px-6 border border-primaryColor rounded-3xl shadow-md">
                                            Cancelar
                                        </a>
                                        <button
                                            onClick={() => salonInning()}
                                            className="font-montserrat py-1 px-3 border border-primaryColor rounded-3xl shadow-md bg-primaryColor text-white ">
                                            Mostrar Horarios
                                        </button>
                                    </div>
                                    <p className="text-xs text-red-400 my-3">{error}</p>
                                </div>
                                {hoursToShow.length > 0 && (
                                    <div>
                                        <div className="flex flex-wrap justify-center">
                                            <div className="text-center bg-secondaryColor w-full my-2 py-2 px-4 gap-2 shadow-md w-11/12 mx-auto">
                                                <h3 className="font-montserrat text-2xl text-primaryColor">
                                                    Horarios disponibles
                                                </h3>
                                            </div>
                                            <div className="flex flex-wrap justify-center w-full my-6 max-w-[360px] gap-6">
                                                {hoursToShow.length > 0 && !hoursToShow[0].startsWith('I') ? (
                                                    hoursToShow.map((hour, index) => (
                                                        <button
                                                            key={index}
                                                            onClick={() => {
                                                                const totalHours = [hour];
                                                                let totalDuration = 0;
                                                                selectedService.forEach((s) => {
                                                                    const [hours, minutes] = s.duration
                                                                        .split(':')
                                                                        .map(Number);
                                                                    totalDuration += (hours * 60 + minutes) / 30;
                                                                });
                                                                for (let i = 1; i < totalDuration; i++) {
                                                                    totalHours.push(
                                                                        hoursToShowBackup[
                                                                            hoursToShowBackup.indexOf(hour) + i
                                                                        ]
                                                                    );
                                                                }
                                                                setSelectedHour(totalHours);
                                                            }}
                                                            disabled={hour.startsWith('T')}
                                                            className={
                                                                selectedHour.includes(hour) ||
                                                                selectedHour.includes(hour.slice(1))
                                                                    ? 'flex justify-center items-center w-[45%] h-[38px] px-4 bg-primaryColor text-white rounded-xl font-monserrat text-xs'
                                                                    : hour.startsWith('T') &&
                                                                      !selectedHour.includes(hour.slice(1))
                                                                    ? 'flex justify-center items-center w-[45%] h-[38px] px-4 outline outline-[1px] outline-primaryColor rounded-xl bg-gray-300 text-xs text-white'
                                                                    : 'flex justify-center cursor-pointer items-center w-[45%] h-[38px] px-4 outline outline-[1px] rounded-xl text-xs'
                                                            }>
                                                            {hour.startsWith('T') ? hour.slice(1) : hour}
                                                        </button>
                                                    ))
                                                ) : (
                                                    <div className="flex justify-center items-center max-w-[auto] h-[38px] px-4 border-2 rounded-xl">
                                                        <p className="font-monserrat">
                                                            {hoursToShow[0] === 'Invalid inning'
                                                                ? 'Sin turnos disponibles'
                                                                : hoursToShow[0] === 'Invalid date'
                                                                ? 'Fecha incorrecta'
                                                                : hoursToShow[0] === 'Invalid hours'
                                                                ? 'Seleccione una fecha'
                                                                : 'Cerrado'}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <p className="text-sm my-2 text-red-400">{submitError}</p>
                                        <div className="flex flex-wrap justify-center w-full gap-12 mb-6">
                                            <a
                                                href="/owner/schedule"
                                                className="font-montserrat py-2 px-6 border border-primaryColor rounded-3xl shadow-md">
                                                Cancelar
                                            </a>
                                            <button
                                                id="btn-schedule"
                                                onClick={onSubmit}
                                                className="font-montserrat py-2 px-6 border border-primaryColor rounded-3xl shadow-md bg-primaryColor text-white">
                                                Agendar
                                            </button>
                                        </div>
                                    </div>
                                )}{' '}
                            </div>
                        </div>
                    </IonContent>
                </IonPage>
            )}
        </>
    );
};

export default CreateAppointmentPage;
