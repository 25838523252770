import React, { SVGProps } from 'react';

const MonthIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.333 5.333H24V2.667h-2.667v2.666H10.667V2.667H8v2.666H6.667C5.187 5.333 4 6.533 4 8v18.667a2.666 2.666 0 0 0 2.667 2.666h18.666c1.467 0 2.667-1.2 2.667-2.666V8c0-1.467-1.2-2.667-2.667-2.667Zm0 21.334H6.667V12h18.666v14.667ZM8.667 17.333A3.335 3.335 0 0 1 12 14a3.335 3.335 0 0 1 0 6.667 3.335 3.335 0 0 1-3.333-3.334Z"
            fill="#82385E"
        />
    </svg>
);

export default MonthIcon;
