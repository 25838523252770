// dddd, DD [de] MMMM YYYY
export const toFullLongDate = (date: Date) =>
    Intl.DateTimeFormat('es-ES', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    })
        .formatToParts(date)
        .map((part, index) => {
            if (part.type === 'literal' && index === 5) {
                return ' ';
            }
            if (part.type === 'weekday' || part.type === 'month') {
                return part.value.charAt(0).toUpperCase() + part.value.slice(1);
            }
            return part.value;
        })
        .join('');

// MMMM YYYY
export const toMonthYearDate = (date: Date) =>
    Intl.DateTimeFormat('es-ES', {
        month: 'long',
        year: 'numeric',
    })
        .formatToParts(date)
        .filter((part) => part.type !== 'literal')
        .map((part) => {
            if (part.type === 'month') {
                return part.value.charAt(0).toUpperCase() + part.value.slice(1);
            }
            return part.value;
        })
        .join(' ');
