import React, { SVGProps } from 'react';

const CalendarArrowIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect width={24} height={24} rx={12} fill="#82385E" />
        <path d="m15.5 7.5-7 4.5 7 4.5" stroke="#fff" strokeWidth={2} />
    </svg>
);

export default CalendarArrowIcon;
