interface Tt {
    friday: D;
    monday: D;
    saturday: D;
    sunday: D;
    thursday: D;
    tuesday: D;
    wednesday: D;
}
interface D {
    end: string;
    start: string;
}
type Key = keyof Tt;

const getMaxTime = function (workweek: Tt) {
    let maxTime = '';
    const days: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    for (let i = 0; i < days.length; i++) {
        if (workweek[days[i] as Key].start !== '' && workweek[days[i] as Key].end !== '') {
            if (maxTime === '') {
                maxTime = workweek[days[i] as Key].end;
            } else if (Number(maxTime.slice(0, 2)) < Number(workweek[days[i] as Key].end.slice(0, 2))) {
                maxTime = workweek[days[i] as Key].end;
            } else if (Number(maxTime.slice(0, 2)) === Number(workweek[days[i] as Key].end.slice(0, 2))) {
                if (Number(maxTime.slice(3, 5)) < Number(workweek[days[i] as Key].end.slice(3, 5))) {
                    maxTime = workweek[days[i] as Key].end;
                } else {
                    continue;
                }
            }
        } else {
            continue;
        }
    }
    return maxTime;
};

export { getMaxTime };
