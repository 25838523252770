import { gql, useMutation } from '@apollo/client';

interface Time {
    start: string;
    end: string;
}
export const useUpdateTimeTable = () => {
    const [updateOneTimeTable] = useMutation(gql`
        mutation updateOneTimeTable(
            $id: ObjectId!
            $monday: TimeTableMondayUpdateInput
            $tuesday: TimeTableTuesdayUpdateInput
            $wednesday: TimeTableWednesdayUpdateInput
            $thursday: TimeTableThursdayUpdateInput
            $friday: TimeTableFridayUpdateInput
            $saturday: TimeTableSaturdayUpdateInput
            $sunday: TimeTableSundayUpdateInput
        ) {
            updateOneTimeTable(
                query: { _id: $id }
                set: {
                    monday: $monday
                    tuesday: $tuesday
                    wednesday: $wednesday
                    thursday: $thursday
                    friday: $friday
                    saturday: $saturday
                    sunday: $sunday
                }
            ) {
                _id
            }
        }
    `);

    return async (
        id: string,
        data: {
            monday: Time;
            tuesday: Time;
            wednesday: Time;
            thursday: Time;
            friday: Time;
            saturday: Time;
            sunday: Time;
        }
    ) => {
        try {
            const result = await updateOneTimeTable({
                variables: {
                    id,
                    monday: data.monday,
                    tuesday: data.tuesday,
                    wednesday: data.wednesday,
                    thursday: data.thursday,
                    friday: data.friday,
                    saturday: data.saturday,
                    sunday: data.sunday,
                },
            });
            console.log(result.data);
            return result.data.updateOneTimeTable;
        } catch (err) {
            console.log(err);
        }
    };
};
