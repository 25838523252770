import React, { useEffect, useState } from 'react';
import { IonHeader } from '@ionic/react';
import { CrossIcon, MenuIcon } from '../assets/icons';
import Menu from './Menu';
import { useLocation } from 'react-router-dom';

type HeaderProps = {
    title: string;
    left?: React.ReactNode;
    right?: React.ReactNode;
    menuRequire?: boolean;
};

const Header = ({ title, left, right }: HeaderProps) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [statistics, setStatistics] = useState(false);
    const [schedule, setSchedule] = useState(false);
    const [salon, setSalon] = useState(false);
    const [directory, setDirectory] = useState(false);
    const url = useLocation();

    useEffect(() => {
        renderMenuOption();
        function renderMenuOption() {
            if (title === 'Estadísticas') {
                setStatistics(true);
                return;
            }
            if (title === 'Mi Peluquería') {
                setSalon(true);
                return;
            }
            if (title === 'Agenda') {
                setSchedule(true);
                return;
            }
            if (title === 'Directorio de Clientes') {
                setDirectory(true);
                return;
            }
        }
    }, [title]);

    function handleMenu() {
        setOpenMenu(!openMenu);
    }
    return (
        <>
            <IonHeader className="sticky top-0 flex px-4 py-3 bg-gray-100 justify-between items-center drop-shadow-md z-[9999] w-full">
                {!openMenu ? (
                    <div onClick={handleMenu}>{<MenuIcon />}</div>
                ) : (
                    <h1 className="font-bold text-primaryColor text-2xl leading-7 font-primaryFont">Menú</h1>
                )}
                {!openMenu ? (
                    <h1 className="font-bold text-2xl tracking-[-0.25px] text-primaryColor">{title}</h1>
                ) : null}
                {!openMenu ? (
                    !url.pathname.includes('profile') ? (
                        <a
                            href={
                                url.pathname.includes('clients')
                                    ? '/clients/profile'
                                    : url.pathname.includes('owner')
                                    ? '/owner/profile'
                                    : '/stylist/profile'
                            }>
                            <img
                                src="https://xsgames.co/randomusers/avatar.php?g=female"
                                alt="profile"
                                className="rounded-full h-[5vh]"
                            />
                        </a>
                    ) : (
                        <div>{right}</div>
                    )
                ) : (
                    <div onClick={handleMenu}>
                        <CrossIcon />
                    </div>
                )}
            </IonHeader>
            {openMenu ? <Menu statistics={statistics} salon={salon} directory={directory} schedule={schedule} /> : null}
        </>
    );
};

export default Header;
