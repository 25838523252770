import React, { useEffect, useState } from 'react';

import { DollarIcon } from '../../../assets/icons';
import { Header } from '../../../components';
import { Salon } from '../../../lib/dataTypes/salon';
import { Service } from '../../../lib/dataTypes/services';
import { useLazyQuery } from '@apollo/client';
import { getAllServicesQuery } from '../../../lib/dataClient/listingServices';
import { useServicePerSalonCreate } from '../../../lib/dataClient/useServicePerSalonCreate';
import { useSalonUpdate } from '../../../lib/dataClient/useSalonUpdate';
import { useServicePerSalonDelete } from '../../../lib/dataClient/useServicePerSalonDelete';
import { useServicePerSalonUpdate } from '../../../lib/dataClient/useServicePerSalonUpdate';
import { ServicesPerSalon } from '../../../lib/dataTypes/services';
interface Props {
    salon?: Salon;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
    edit: boolean;
    setSalon: React.Dispatch<React.SetStateAction<Salon | undefined>>;
}
const ServicesPage = (props: Props) => {
    const [currentService, setCurrentService] = useState<ServicesPerSalon>();
    const [service, setService] = useState<Service | undefined>();
    const [disable, setDisable] = useState(true);
    const [error, setError] = useState('');
    const [price, setPrice] = useState('');
    const [duration, setDuration] = useState('');

    const [currentIndex, setCurrentIndex] = useState(-1);
    const [addService, setAddService] = useState(false);
    const [loadServices, servicesResult] = useLazyQuery(getAllServicesQuery, {
        fetchPolicy: 'no-cache',
    });
    const updateOneServicePerSalon = useServicePerSalonUpdate();
    const createService = useServicePerSalonCreate();
    const deleteServiceById = useServicePerSalonDelete();
    const { updateSalonServices } = useSalonUpdate();
    const [servicesToShow, setServicesToShow] = useState<Service[]>();
    const durationServiceList = ['00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00'];

    const addServiceToSalon = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (price !== '' && service !== undefined) {
            try {
                const data = {
                    price: price,
                    service: service._id,
                    duration: duration,
                };
                const createdService = await createService(data);
                if (createdService._id) {
                    const services = props.salon?.services.map((service) => service._id);
                    console.log(services);
                    if (services) {
                        services.push(createdService._id);
                        if (props.salon) {
                            try {
                                const result = await updateSalonServices(props.salon._id, services);
                                if (result) {
                                    props.setSalon(() => result);
                                }
                            } catch (err) {
                                console.error(err);
                            }
                        }
                    }
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            setError(() => 'Seleccione un servicio e introduzca un precio');
        }
    };

    const validatePrice = (price: string) => {
        const regex = /^\d*$/i;
        if (!/[A-Za-z\s]+$/g.test(price)) {
            if (price.includes('.')) {
                const array = price.split('.');
                console.log(array.length);
                if (array.length === 2) {
                    const first = price.split('.')[0];
                    const second = price.split('.')[1];
                    if (second.length === 2) {
                        if (regex.test(first)) {
                            if (regex.test(second)) {
                                setPrice(() => price);
                                setError(() => '');
                            } else {
                                setError(() => 'Introduce sólo números');
                            }
                        } else {
                            setError(() => 'Introduce sólo números');
                        }
                    } else {
                        setError(() => 'Introduce el precio sólo con 2 decimales');
                    }
                } else {
                    setError(() => 'Introduce un sólo "."');
                }
            } else {
                setError(() => 'Introduce el precio con un "." para decimales');
            }
        } else {
            setError(() => 'Sólo introduce números por favor');
        }
    };

    const filterServices = () => {
        const salonServicesId = props.salon?.services.map((service) => service.service._id);
        if (salonServicesId && servicesResult.data) {
            const allServices = servicesResult.data.services;
            let filteredServices = [...allServices];
            for (let i = 0; i < salonServicesId.length; i++) {
                filteredServices = filteredServices.filter((service) => service._id !== salonServicesId[i]);
            }
            setServicesToShow(() => filteredServices);
        }
    };

    const deleteService = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (currentService) {
            setError(() => '');
            try {
                const id = currentService._id;
                const deletedService = await deleteServiceById(id);
                if (deletedService._id) {
                    const filteredServices = props.salon?.services.filter(
                        (service) => service._id !== deletedService._id
                    );
                    const services = filteredServices && filteredServices.map((service) => service._id);
                    if (services) {
                        if (props.salon) {
                            try {
                                const result = await updateSalonServices(props.salon._id, services);
                                if (result) {
                                    props.setSalon(() => result);
                                }
                            } catch (err) {
                                console.error(err);
                            }
                        }
                    }
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            setError(() => 'Seleccione un servicio por favor');
        }
    };

    const updateService = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (currentService) {
            setError(() => '');
            try {
                const { _id } = currentService;
                const result = await updateOneServicePerSalon(_id, price, duration);
                if (result) {
                    const services = props.salon?.services.map((service) => service._id);
                    if (services) {
                        if (props.salon) {
                            try {
                                const result = await updateSalonServices(props.salon._id, services);
                                if (result) {
                                    props.setSalon(() => result);
                                }
                            } catch (err) {
                                console.error(err);
                            }
                        }
                    }
                }
            } catch (err) {
                console.error(err);
            }
        } else {
            setError(() => 'Seleccione un servicio por favor');
        }
    };

    const back = () => {
        setAddService(!addService);
        setError(() => '');
        setDisable(() => true);
        setPrice(() => '');
        setService(() => undefined);
    };
    useEffect(() => {
        loadServices();
        filterServices();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        filterServices();
        //eslint-disable-next-line
    }, [servicesResult.data?.services]);
    useEffect(() => {
        error !== '' ? setDisable(() => true) : setDisable(() => false);
        //eslint-disable-next-line
    }, [error]);
    return (
        <div className="flex flex-col justify-center items-center font-montserrat scrollbar-hide ">
            <Header title="Servicios" />
            <section className="text-sm sm:text-base flex flex-wrap justify-start bg-gray-100 mt-5 rounded-md w-[93vw] h-[35vh] p-1 overflow-auto scrollbar-hidden">
                {props.salon?.services.map((service, index) => (
                    <button
                        className={`flex flex-col border-[1px] justify-around items-center w-[28vw] py-1 rounded-md m-1 h-[45%] ${
                            index === currentIndex ? 'bg-primaryColor/20 border-primaryColor ' : 'border-gray-400'
                        }`}
                        key={index}
                        onClick={() => {
                            setCurrentIndex(index);
                            setCurrentService(service);
                            setDuration(service.duration);
                            addService && setAddService(() => false);
                        }}>
                        <p className="text-[16px]">{service.service.name}</p>
                        <div className="flex flex-col items-center h-[70%] justify-center">
                            {service.service.img?.blob && (
                                <img
                                    src={`data:${service.service.img.blob?.mimeType};base64,${service.service.img.blob?.data}`}
                                    alt="img_services"
                                    className="w-10 h-7 mr-2"
                                />
                            )}
                            <p className="text-primaryColor text-[16px]">${service.price}</p>
                        </div>
                    </button>
                ))}
            </section>
            {addService ? (
                <section className="mx-4 max-w-screen-sm w-full px-5 flex flex-col">
                    <h3 className="text-primaryColor font-bold text-lg text-center mt-4 text-[22px]">
                        Agregar Servicio
                    </h3>
                    <form className="flex flex-col justify-center items-center gap-x-3 gap-y-1 sm:text-base">
                        <label className="flex flex-col justify-start focus:outline-none col-span-5">
                            <p className="relative pl-2 pr-2 left-2 -bottom-3 bg-white text-center self-start sm:text-sm text-[16px]">
                                Nombre del Servicio
                            </p>
                            <select
                                onChange={(e) =>
                                    e.target.value !== 'default'
                                        ? setService(() => JSON.parse(e.target.value))
                                        : setService(() => undefined)
                                }
                                defaultValue="default"
                                name="filter"
                                id="filter"
                                className="border rounded-md border-zinc-300 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full mb-8 h-14">
                                <option value="default">Seleccione un servicio</option>
                                {servicesToShow?.map((e: Service, i: number) => (
                                    <option key={i} value={JSON.stringify(e)}>
                                        {e.name}
                                    </option>
                                ))}
                            </select>
                            {service?.img?.blob && (
                                <img
                                    src={`data:${service.img.blob?.mimeType};base64,${service.img.blob?.data}`}
                                    alt="img_services"
                                    className="w-10 h-7 mr-2 self-center"
                                />
                            )}
                        </label>
                        <label className="flex flex-col justify-start focus:outline-none col-span-3">
                            <p className="relative pl-2 pr-2 left-2 -bottom-3 bg-white text-center self-start sm:text-sm text-[16px]">
                                Precio
                            </p>
                            <div className="flex items-center outline outline-1 rounded-sm outline-gray-800 focus-within:outline-primaryColor pr-3">
                                <input
                                    className="px-2 outline-none h-[6vh] w-full mr-1 text-[18px]"
                                    placeholder={'0.00'}
                                    type="text"
                                    onChange={(e) => validatePrice(e.target.value)}
                                />
                                <DollarIcon />
                            </div>
                            <p className="text-xs text-red-400 text-center">{error}</p>
                        </label>
                        <label className="flex flex-col justify-start focus:outline-none col-span-5">
                            <p className="relative pl-2 pr-2 left-2 -bottom-3 bg-white text-center self-start sm:text-sm text-[16px]">
                                Duración
                            </p>
                            <select
                                onChange={(e) => setDuration(e.target.value)}
                                defaultValue="default"
                                name="duration"
                                id="duration"
                                className="border rounded-md border-zinc-300 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full mb-8 h-14">
                                <option value="default">Seleccione una duración</option>
                                {durationServiceList?.map((e) => (
                                    <option key={e} value={e}>
                                        {e}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <div className="flex items-center justify-around col-span-5 mt-5 text-base mb-4 w-full">
                            <button
                                onClick={(e) => addServiceToSalon(e)}
                                disabled={disable}
                                className={`border ${
                                    disable ? 'bg-gray-200 border-primaryColor' : 'bg-primaryColor text-gray-100'
                                } rounded-full px-5 drop-shadow-lg py-1 text-[18px]`}>
                                Agregar
                            </button>
                        </div>
                    </form>
                    <button
                        onClick={() => back()}
                        className=" border text-gray-100 bg-primaryColor rounded-full px-5 drop-shadow-lg py-1 text-[18px] self-center">
                        volver
                    </button>
                </section>
            ) : (
                <section className="mx-4 max-w-screen-sm w-full px-5 flex flex-col">
                    <h3 className="text-primaryColor font-bold text-lg text-center mt-4 text-[22px]">
                        Editar Servicio
                    </h3>
                    <form className="flex flex-col justify-center items-center gap-x-3 gap-y-1 sm:text-base">
                        <label className="flex flex-col justify-start focus:outline-none col-span-3">
                            <p className="relative pl-2 pr-2 left-2 -bottom-3 bg-white text-center self-start sm:text-sm text-[16px]">
                                Precio
                            </p>
                            <div className="flex items-center outline outline-1 rounded-sm outline-gray-800 focus-within:outline-primaryColor pr-3">
                                <input
                                    className="px-2 outline-none h-[6vh] w-full mr-1 text-[18px]"
                                    placeholder={currentService?.price ? String(currentService?.price) : ''}
                                    type="text"
                                    onChange={(e) => validatePrice(e.target.value)}
                                />
                                <DollarIcon />
                            </div>
                            <p className="text-xs text-red-400 text-center">{error}</p>
                        </label>
                        <label className="flex flex-col justify-start focus:outline-none col-span-5">
                            <p className="relative pl-2 pr-2 left-2 -bottom-3 bg-white text-center self-start sm:text-sm text-[16px]">
                                Duración
                            </p>
                            <select
                                onChange={(e) => setDuration(e.target.value)}
                                value={duration}
                                name="duration"
                                id="duration"
                                className="border rounded-md border-zinc-300 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full mb-8 h-14">
                                <option value="default">Seleccione una duración</option>
                                {durationServiceList?.map((e) => (
                                    <option key={e} value={e}>
                                        {e}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <div className="flex items-center justify-around col-span-5 mt-5 text-base mb-4 w-full">
                            <button
                                onClick={(e) => deleteService(e)}
                                className="border border-primaryColor rounded-full px-5 bg-gray-200 drop-shadow-lg py-1 text-[18px]">
                                Eliminar
                            </button>
                            <button
                                onClick={(e) => updateService(e)}
                                disabled={disable}
                                className={`border ${
                                    disable ? 'bg-gray-200 border-primaryColor' : 'bg-primaryColor text-gray-100'
                                } rounded-full px-5 drop-shadow-lg py-1 text-[18px]`}>
                                Guardar
                            </button>
                        </div>
                    </form>
                    <button
                        onClick={() => [setAddService(!addService), setError(() => '')]}
                        className=" border text-gray-100 bg-primaryColor rounded-full px-5 drop-shadow-lg py-1 text-[18px] self-center">
                        Agregar servicio
                    </button>
                    <button
                        onClick={() => [props.setEdit(!props.edit), back()]}
                        className=" border text-gray-100 bg-primaryColor rounded-full px-5 drop-shadow-lg py-1 text-[18px] self-center mt-2">
                        Volver
                    </button>
                </section>
            )}
        </div>
    );
};

export default ServicesPage;
