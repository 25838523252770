import React from 'react';

const WhatsAppIcon = () => {
    return (
        <>
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.0159 0C6.1327 0 0.529358 5.37405 0.526858 11.9813C0.525608 14.0933 1.10189 16.1551 2.1945 17.9719L0.5 24L7.04116 22.5164C8.86509 23.4716 10.9183 23.973 13.0085 23.9742H13.0134C19.8953 23.9742 25.4962 18.599 25.5 11.993C25.5025 8.79017 24.205 5.77888 21.8473 3.51328C19.4896 1.24888 16.3562 0.0012 13.0159 0ZM13.0134 2.4C15.6837 2.4012 18.1931 3.40056 20.0795 5.21016C21.966 7.02216 23.0023 9.42983 22.9998 11.9906C22.9973 17.2754 18.519 21.5742 13.011 21.5742C11.3446 21.573 9.6939 21.1714 8.24001 20.4094L7.39764 19.9688L6.46738 20.1797L4.0062 20.7375L4.60685 18.5953L4.87787 17.6344L4.36024 16.7719C3.48765 15.3223 3.02585 13.6649 3.0271 11.9813C3.0296 6.69885 7.50914 2.4 13.0134 2.4ZM8.59649 6.45C8.38772 6.45 8.05017 6.525 7.76389 6.825C7.47761 7.1238 6.67003 7.84749 6.67003 9.32109C6.67003 10.7947 7.78831 12.2191 7.94457 12.4195C8.09959 12.6187 10.1031 15.7383 13.2747 16.9383C15.9099 17.9355 16.4452 17.7387 17.0177 17.6883C17.5903 17.6391 18.8648 16.9652 19.1249 16.2656C19.3849 15.566 19.3855 14.9642 19.308 14.8406C19.2305 14.7158 19.0223 14.6414 18.7098 14.4914C18.3985 14.3414 16.8645 13.6184 16.5782 13.5188C16.2919 13.4192 16.0826 13.3688 15.875 13.6688C15.6675 13.9688 15.0711 14.6414 14.8886 14.8406C14.7061 15.041 14.5248 15.068 14.2123 14.918C13.8997 14.7668 12.8949 14.4497 11.7023 13.4297C10.7747 12.6365 10.1488 11.6578 9.96625 11.3578C9.78499 11.059 9.94916 10.8949 10.1054 10.7461C10.2454 10.6117 10.4155 10.3963 10.5718 10.2211C10.7268 10.0459 10.7806 9.92107 10.8843 9.72187C10.9881 9.52267 10.935 9.34688 10.8575 9.19688C10.7799 9.04688 10.173 7.5678 9.89545 6.975C9.66167 6.477 9.41477 6.46543 9.19225 6.45703C9.01099 6.44983 8.80401 6.45 8.59649 6.45Z"
                    fill="#82385E"
                />
            </svg>
        </>
    );
};

export default WhatsAppIcon;
