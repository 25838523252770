import React, { useState } from 'react';
import { EditIcon, InfoIcon, LogoSmall } from '../../assets/icons';
import { IonContent, IonPage, useIonToast } from '@ionic/react';
import getAuthenticationClient from '../../lib/getAuthenticationClient';
import validateEmail from '../../lib/validateEmail';
import getRealmUser from '../../lib/getRealmUser';
import { useFileUpload } from '../../lib/dataClient/useFileUpload';
import { ImageFile } from '../../lib/dataTypes/file';
import getFileUrl from '../../lib/getFileUrl';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { login } from '../../reducers/AuthReducer';
import { UserDataResult } from 'entio-sdk';

const CreateAccountPage = () => {
    const history = useHistory();
    const [toast] = useIonToast();
    const [Name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [uploadingFile, setUploadingFile] = useState(false);
    const [productUrl, setProductUrl] = useState<string[]>([]);
    const [files, setFiles] = useState<ImageFile[]>([]);
    const uploadFile = useFileUpload();
    const dispatch = useAppDispatch();

    const onError = async (error: string) => {
        await toast({
            color: 'warning',
            position: 'top',
            message: error,
            duration: 2000,
            cssClass:
                'absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-red-500 text-white rounded-lg p-4',
        });
    };

    const onLoginError = async (error: string) => {
        await toast({
            color: 'danger',
            position: 'top',
            message: error,
            duration: 2000,
            cssClass:
                'absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-red-500 text-white rounded-lg p-4',
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const uploadProduct = async (file: any) => {
        setUploadingFile(true);
        const uploadResult = await uploadFile(file);

        setUploadingFile(false);
        if (!uploadResult) {
            console.warn('An error occurred while uploading the file');
        }

        if (!uploadResult.url) {
            onError('Seleccione una imagen');
        } else {
            setFiles([...files, { type: 'IMAGE', url: uploadResult.url }]);
            setProductUrl([...productUrl, uploadResult.url]);
        }
    };
    //eslint-disable-next-line
    const onLoginSuccess = async (data: UserDataResult<any>) => {
        const sessionState = {
            token: data.token,
            user: data.user,
            profile: data.profile,
            rol: data.profile.rol,
        };
        dispatch(login(sessionState));
    };

    const onSubmit = async () => {
        if (Name.trim() === '') {
            onError('El campo Nombre no puede ir vacío').then();
            return;
        }
        //eslint-disable-next-line
        if (!/^[\w'\-,.]*[^0-9_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/g.test(Name)) {
            onError('Ingresa un Nombre válido').then();
            return;
        }
        if (email.trim() === '') {
            onError('El campo email no puede ir vacío').then();
            return;
        }
        if (!validateEmail(email)) {
            onError('Ingresa un email válido').then();
            return;
        }
        if (password?.length < 8) {
            onError('La contraseña debe ser al menos de 8 caracteres').then();
            return;
        }
        if (phoneNumber.trim() === '') {
            onError('El campo Teléfono no puede ir vacío').then();
            return;
        }
        document.getElementById('btn-create-account')?.setAttribute('disabled', 'true');
        const trimmedFirstName = Name.replace(/  +/g, ' ').trim();
        const trimmedEmail = email.trim();
        const trimmedPhoneNumber = phoneNumber.replace(/ /g, '');
        const countryCodePhoneNumber = trimmedPhoneNumber.startsWith('+')
            ? trimmedPhoneNumber
            : `+52${trimmedPhoneNumber}`;
        const name = `${trimmedFirstName} `;
        const signUpResponse = await getAuthenticationClient().signUp({
            email: trimmedEmail,
            password,
            username: name,
            phone: countryCodePhoneNumber,
            profile: {
                firstName: trimmedFirstName,
                country: country,
                birthDate: birthDate,
                img: {
                    url: `${getFileUrl(productUrl[0])}`,
                    blob: {
                        mimetype: '',
                        data: '',
                    },
                },
                role: ['FASH.CLIENT'],
            },
        });
        if (!signUpResponse.success) {
            onLoginError(signUpResponse.message).then();
            document.getElementById('btn-create-account')?.setAttribute('disabled', 'false');
            return;
        }
        onLoginSuccess(signUpResponse.data).then();
        localStorage.setItem('token', signUpResponse.data.token);
        await getRealmUser();
        document.getElementById('btn-create-account')?.setAttribute('disabled', 'false');
        history.push('/clients/catalogue');
    };

    return (
        <IonPage>
            <IonContent>
                <div className="flex flex-col h-full justify-start overflow-scroll">
                    <div className="flex justify-between w-5/6 self-center mt-4">
                        <LogoSmall />
                        <button>
                            <InfoIcon />
                        </button>
                    </div>
                    <div className="flex flex-col justify-center items-center mt-8">
                        <h2 className="text-[24px]">Bienvenido</h2>
                        <div className="w-[150px] h-[150px] flex flex-col items-center my-5 relative">
                            <label
                                htmlFor="uploadFile"
                                className="absolute top-0 right-0 z-10 rounded-full bg-primaryColor p-2">
                                <EditIcon />
                            </label>
                            {getFileUrl(productUrl[0]) ? (
                                <img
                                    className="rounded-full shadow-md shadow-gray-500"
                                    alt="profile"
                                    src={getFileUrl(productUrl[0])}
                                />
                            ) : (
                                <img
                                    className=" rounded-full shadow-md shadow-gray-500"
                                    src={'https://cdn-icons-png.flaticon.com/512/847/847969.png'}
                                    alt="profile"
                                />
                            )}
                        </div>

                        <input
                            id="uploadFile"
                            hidden
                            className="flex w-5/6 self-center mt-4 mb-10"
                            type="file"
                            accept="image/*"
                            name="uploadFile"
                            disabled={uploadingFile}
                            onChange={(e) => {
                                const file = e?.target?.files?.[0];
                                uploadProduct(file).then();
                            }}
                        />
                    </div>

                    <div className="flex justify-center w-full pb-7">
                        <div className="flex flex-col w-5/6">
                            <div className="relative">
                                <div className="absolute -bottom-3 left-2">
                                    <label
                                        htmlFor="userName"
                                        className="text-primaryColor font-regular bg-white px-1 text-[18px]">
                                        Nombre
                                    </label>
                                </div>
                            </div>

                            <div className=" flex justify-center">
                                <input
                                    type="text"
                                    name="userName"
                                    id="userName"
                                    onChange={(e) => setName(() => e.target.value)}
                                    className="h-14 border rounded-md outline outline-1 outline-gray-400 focus:border-primaryColor focus:border-2 focus:outline-none px-2 pt-2 pb-1  w-full"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center w-full pb-7">
                        <div className="flex flex-col  w-5/6">
                            <div className="relative">
                                <div className="absolute -bottom-3 left-2">
                                    <label
                                        htmlFor="email"
                                        className="text-primaryColor font-regular bg-white px-1 text-[18px]">
                                        Correo
                                    </label>
                                </div>
                            </div>

                            <div className=" flex justify-center">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    onChange={(e) => setEmail(() => e.target.value)}
                                    className="h-14 border rounded-md outline outline-1 outline-gray-400 focus:border-primaryColor focus:border-2 focus:outline-none px-2 pt-2 pb-1  w-full"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center w-full pb-7">
                        <div className="flex flex-col  w-5/6">
                            <div className="relative">
                                <div className="absolute -bottom-3 left-2">
                                    <label
                                        htmlFor="phone"
                                        className="text-primaryColor font-regular bg-white px-1 text-[18px]">
                                        Teléfono
                                    </label>
                                </div>
                            </div>

                            <div className=" flex justify-center">
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    onChange={(e) => setPhoneNumber(() => e.target.value)}
                                    className="h-14 border rounded-md outline outline-1 outline-gray-400 focus:border-primaryColor focus:border-2 focus:outline-none px-2 pt-2 pb-1  w-full"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center w-full pb-7">
                        <div className="flex flex-col  w-5/6">
                            <div className="relative">
                                <div className="absolute -bottom-3 left-2">
                                    <label
                                        htmlFor="country"
                                        className="text-primaryColor font-regular bg-white px-1 text-[18px]">
                                        País
                                    </label>
                                </div>
                            </div>

                            <div className=" flex justify-center">
                                <input
                                    type="text"
                                    name="country"
                                    id="country"
                                    onChange={(e) => setCountry(() => e.target.value)}
                                    className="h-14 border rounded-md outline outline-1 outline-gray-400 focus:border-primaryColor focus:border-2 focus:outline-none px-2 pt-2 pb-1  w-full"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center w-full pb-7">
                        <div className="flex flex-col  w-5/6">
                            <div className="relative">
                                <div className="absolute -bottom-3 left-2">
                                    <label
                                        htmlFor="birthDate"
                                        className="text-primaryColor font-regular bg-white px-1 text-[18px]">
                                        Cumpleaños *
                                    </label>
                                </div>
                            </div>

                            <div className=" flex justify-center">
                                <input
                                    type="Date"
                                    name="birthDate"
                                    id="birthDate"
                                    onChange={(e) => setBirthDate(() => e.target.value)}
                                    className="h-14 border rounded-md outline outline-1 outline-gray-400 focus:border-primaryColor focus:border-2 focus:outline-none px-2 pt-2 pb-1  w-full"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center w-full pb-5">
                        <div className="flex flex-col  w-5/6">
                            <div className="relative">
                                <div className="absolute -bottom-3 left-2">
                                    <label
                                        htmlFor="password"
                                        className="text-primaryColor font-regular bg-white px-1 text-[18px]">
                                        Contraseña
                                    </label>
                                </div>
                            </div>

                            <div className=" flex flex-col justify-center">
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="h-14 outline outline-1 outline-gray-400 border rounded-md focus:border-primaryColor focus:border-2 focus:outline-none px-2 pt-2 pb-1  w-full"
                                />
                                <p className="text-[12px] text-gray-400 mt-2">*Debe contener al menos 8 dígitos </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-center items-center p-3">
                        <button
                            id="btn-create-account"
                            className="rounded-full bg-primaryColor p-3 pr-10 pl-10 text-white font-medium mx-10 my-6 text-[20px] shadow-gray-500 shadow-md"
                            onClick={onSubmit}>
                            Crear Cuenta
                        </button>
                        <p className="self-center text-[16px] mb-8">
                            ¿Ya tienes una cuenta?{' '}
                            <a href="/" className="text-yellow-500 underline">
                                Inicia Sesión
                            </a>
                        </p>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default CreateAccountPage;
