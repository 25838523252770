type time = string[];
type hours = string;
type minutes = string;
type seconds = string;

const timeGenerator = function (timeNeeded: hours | minutes | seconds): time | undefined {
    if (!timeNeeded) return;
    else if (timeNeeded !== 'seconds' && timeNeeded !== 'minutes' && timeNeeded !== 'hours') return;
    const max: number = timeNeeded === 'seconds' || timeNeeded === 'minutes' ? 60 : 24;
    const time: string[] = [];
    while (time.length < max) {
        if (time.length < 9) time.push(`0${time.length + 1}`);
        else if (timeNeeded === 'hours' && time.length === 23) time.push(`00`);
        else if (time.length === 59 && (timeNeeded === 'minutes' || timeNeeded === 'seconds')) time.push(`00`);
        else if (time.length >= 9) time.push(`${time.length + 1}`);
    }
    return time;
};

export { timeGenerator };
