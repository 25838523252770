import React, { SVGProps } from 'react';

const DayIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={33} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M5.222 21.333h21.333c.734 0 1.334.6 1.334 1.334 0 .733-.6 1.333-1.334 1.333H5.222c-.733 0-1.333-.6-1.333-1.333 0-.734.6-1.334 1.333-1.334Zm0-9.333h21.333c.734 0 1.334.6 1.334 1.333v4c0 .734-.6 1.334-1.334 1.334H5.222c-.733 0-1.333-.6-1.333-1.334v-4c0-.733.6-1.333 1.333-1.333Zm0-5.333h21.333c.734 0 1.334.6 1.334 1.333s-.6 1.333-1.334 1.333H5.222c-.733 0-1.333-.6-1.333-1.333s.6-1.333 1.333-1.333Z"
            fill="#82385E"
        />
    </svg>
);

export default DayIcon;
