import React, { useState } from 'react';
import { IonCardHeader } from '@ionic/react';
import { EditIcon, AddressIcon, WorkersIcon, CallIcon, InstagramIcon, WhatsAppIcon } from '../assets/icons';
import { Salon } from '../lib/dataTypes/salon';
import { useSalonUpdate } from '../lib/dataClient/useSalonUpdate';

interface Props {
    salon?: Salon;
    setSalon: React.Dispatch<React.SetStateAction<Salon | undefined>>;
}

const GeneralInfo = (props: Props) => {
    const [read, setRead] = useState(true);
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [whatsApp, setWhatsApp] = useState('');
    const [igUrl, setIgUrl] = useState('');
    const { updateSalonInfo } = useSalonUpdate();
    const [error, setError] = useState({
        phone: '',
        whatsApp: '',
    });
    const [disable, setDisable] = useState(false);

    const validatePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!/^(\+)?[0-9]+(\+[0-9a-zA-Z]+)?$/g.test(e.target.value)) {
            e.target.id === 'phoneNumber'
                ? setError({ ...error, phone: 'Ingresa un teléfono válido' })
                : setError({ ...error, whatsApp: 'Ingresa un teléfono válido' });
            setDisable(() => true);

            return;
        }
        if (e.target.value.startsWith('+') && e.target.value.replace(/ /g, '')?.length - 1 < 10) {
            e.target.id === 'phoneNumber'
                ? setError({ ...error, phone: 'Ingresa un teléfono válido' })
                : setError({ ...error, whatsApp: 'Ingresa un teléfono válido' });
            setDisable(() => true);

            return;
        }
        if (!e.target.value.startsWith('+') && e.target.value.replace(/ /g, '')?.length !== 10) {
            e.target.id === 'phoneNumber'
                ? setError({ ...error, phone: 'Ingresa un teléfono válido' })
                : setError({ ...error, whatsApp: 'Ingresa un teléfono válido' });
            setDisable(() => true);
            return;
        }
        e.target.id === 'phoneNumber' ? setPhoneNumber(() => e.target.value) : setWhatsApp(() => e.target.value);
        e.target.id === 'phoneNumber' ? setError({ ...error, phone: '' }) : setError({ ...error, whatsApp: '' });
        setDisable(() => false);
    };
    const updateSalon = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (props.salon) {
            const data = {
                id: props.salon._id,
                address: address !== '' ? address : props.salon.address,
                phoneNumber: phoneNumber !== '' ? phoneNumber : props.salon.phoneNumber,
                whatsApp: whatsApp !== '' ? whatsApp : props.salon.whatsApp,
                igUrl: igUrl !== '' ? igUrl : props.salon.igUrl,
            };
            try {
                const updatedSalon = await updateSalonInfo(data);
                if (updatedSalon) {
                    props.setSalon(() => updatedSalon);
                    setRead(!read);
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    return (
        <div className="mt-4 flex flex-col font-montserrat px-2 justify-center">
            <IonCardHeader className="navbar navbar-expand-lg shadow-md py-2 px-2 bg-gray-100 flex items-center justify-between">
                <div className="text-primaryColor text-[22px] px-2 font-montserrat">Información General</div>
                <button onClick={() => setRead(() => !read)} className="relative rounded-full bg-primaryColor p-1">
                    <EditIcon />
                </button>
            </IonCardHeader>
            <div className="flex flex-wrap flex-col my-6 mx-4">
                <div className="flex flex-row items-center justify-between">
                    <AddressIcon />
                    <input
                        className={
                            read
                                ? 'font-montserrat text-[1rem] px-1 text-start w-[90%]'
                                : 'font-montserrat text-[1rem] outline outline-[1px] px-3 outline-primaryColor rounded-[4px] text-center outline outline-1'
                        }
                        readOnly={read}
                        defaultValue={props.salon?.address}
                        onChange={(e) => setAddress(() => e.target.value)}
                    />
                </div>
                <div className="h-[1px] bg-primaryColor my-3" />
                <div className="flex flex-row items-center justify-between">
                    <WorkersIcon />
                    <input
                        className={'font-montserrat text-[1rem] px-1 text-start w-[90%]'}
                        readOnly
                        defaultValue={props.salon?.workers.length + ' Profesionales'}
                    />
                </div>
                <div className="h-[1px] bg-primaryColor my-3" />
                <div className="flex flex-row items-center justify-between">
                    <CallIcon />
                    <div className={`flex ${read ? 'flex-row w-[90%]' : 'flex-col items-center'}`}>
                        <input
                            className={
                                read
                                    ? 'font-montserrat text-[1rem] px-1 text-start w-[90%]'
                                    : 'font-montserrat text-[1rem] outline outline-[1px] px-3 outline-primaryColor rounded-[4px] text-center outline outline-1'
                            }
                            readOnly={read}
                            id="phoneNumber"
                            defaultValue={props.salon?.phoneNumber}
                            onChange={(e) => validatePhone(e)}
                        />
                        <p className="text-red-400 text-xs">{error.phone}</p>
                    </div>
                </div>
                <div className="h-[1px] bg-primaryColor my-3" />
                <div className="flex flex-row items-center justify-between">
                    <WhatsAppIcon />
                    <div className={`flex ${read ? 'flex-row w-[90%]' : 'flex-col items-center'}`}>
                        <input
                            className={
                                read
                                    ? 'font-montserrat text-[1rem] px-1 text-start w-[90%]'
                                    : 'font-montserrat text-[1rem] outline outline-[1px] px-3 outline-primaryColor rounded-[4px] text-center outline outline-1'
                            }
                            readOnly={read}
                            id="whatsApp"
                            defaultValue={props.salon?.whatsApp}
                            onChange={(e) => validatePhone(e)}
                        />
                        <p className="text-red-400 text-xs">{error.whatsApp}</p>
                    </div>
                </div>
                <div className="h-[1px] bg-primaryColor my-3" />
                <div className="flex flex-row items-center justify-between">
                    <InstagramIcon />
                    <input
                        className={
                            read
                                ? 'font-montserrat text-[1rem] px-1 text-start w-[90%]'
                                : 'font-montserrat text-[1rem] outline outline-[1px] px-3 outline-primaryColor rounded-[4px] text-center outline outline-1'
                        }
                        readOnly={read}
                        defaultValue={props.salon?.igUrl}
                        onChange={(e) => setIgUrl(() => e.target.value)}
                    />
                </div>
                <div className="h-[1px] bg-primaryColor my-3" />
                {!read && (
                    <button
                        onClick={(e) => updateSalon(e)}
                        disabled={disable}
                        className={`outline rounded-full px-5 drop-shadow-lg mt-2 text-[18px] ${
                            disable
                                ? 'bg-gray-200 outline-[1px] outline-primaryColor'
                                : 'bg-primaryColor text-gray-100 '
                        }`}>
                        Guardar
                    </button>
                )}
            </div>
        </div>
    );
};

export default GeneralInfo;
