import imageCompression from 'browser-image-compression';
const compressFile = async (file: File) => {
    const options = {
        maxSizeMB: 1.9,
    };

    try {
        const compressedFile = await imageCompression(file, options);
        console.log('compressedFile:', compressedFile);
        return compressedFile;
    } catch (error) {
        console.log(error);
        return;
    }
};

export default compressFile;
