import { gql } from '@apollo/client';

export const getAllSalonsQuery = gql`
    query getAllSalons {
        salons {
            _id
            description
            name
            address
            timetable {
                monday {
                    start
                    end
                }
                tuesday {
                    start
                    end
                }
                wednesday {
                    start
                    end
                }
                wednesday {
                    start
                    end
                }
                thursday {
                    start
                    end
                }
                friday {
                    start
                    end
                }
                saturday {
                    start
                    end
                }
                sunday {
                    start
                    end
                }
            }
            services {
                _id
                __typename
                service {
                    _id
                    name
                    description
                }
                price
            }
            owner {
                _id
            }
            workers {
                _id
            }
            img {
                url
            }
        }
    }
`;
