import React from 'react';
import { Link } from 'react-router-dom';
import {
    FashadminLogo,
    SelectAccountClientIcon,
    SelectAccountOwnerIcon,
    SelectAccountStylistIcon,
} from '../../assets/icons';

const SelectAccount = () => {
    return (
        <div className="text-center flex justify-center flex-col items-center min-h-screen font-montserrat w-full">
            <FashadminLogo />
            <section className="my-10">
                <h4 className="text-xl mb-5">Crear cuenta:</h4>
                <Link to="/clients/register" className="items-center justify-center flex flex-col font-semibold">
                    <SelectAccountClientIcon />
                    <p>Cliente</p>
                </Link>
                <div className="flex font-semibold mt-3 gap-5">
                    <Link to="/stylist/register">
                        <SelectAccountStylistIcon />
                        <p>Estilista</p>
                    </Link>
                    <Link to="/owner/register">
                        <SelectAccountOwnerIcon />
                        <p>Dueño</p>
                    </Link>
                </div>
            </section>
            <Link
                className="border-2 border-primaryColor rounded-full py-1 px-5 bg-gray-200 drop-shadow-lg"
                to="/login">
                Ya tengo una cuenta
            </Link>
        </div>
    );
};

export default SelectAccount;
