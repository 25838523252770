import React from 'react';

const ScoreIcon = () => {
    return (
        <div className="p-2">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.55696 14.0196L12.707 16.5296C13.467 16.9896 14.397 16.3096 14.197 15.4496L13.097 10.7296L16.767 7.54958C17.437 6.96958 17.077 5.86958 16.197 5.79958L11.367 5.38958L9.47696 0.929583C9.13696 0.119583 7.97696 0.119583 7.63696 0.929583L5.74696 5.37958L0.916957 5.78958C0.0369574 5.85958 -0.323043 6.95958 0.346957 7.53958L4.01696 10.7196L2.91696 15.4396C2.71696 16.2996 3.64696 16.9796 4.40696 16.5196L8.55696 14.0196Z"
                    fill="#82385E"
                />
            </svg>
        </div>
    );
};

export default ScoreIcon;
