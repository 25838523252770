import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { EditIcon, MenuIcon } from '../../assets/icons';
import { Header } from '../../components';
import Loader from '../../components/Loader';
import StylistCard from '../../components/StylistCard';
import { useAppointmentQuery } from '../../lib/dataClient/useAppointmentQuery';
import { Appointment } from '../../lib/dataTypes/appointments';
import { FashProfile } from '../../lib/dataTypes/fashProfile';
import { getProfileClient } from '../../lib/getProfileClient';

const ProfileClientPage = () => {
    const { getAppointmentByClientId } = useAppointmentQuery();
    const [userProfile, setUserProfile] = useState<FashProfile>();
    const [userAppointments, setUserAppointments] = useState<Appointment[]>([]);
    const profileClient = getProfileClient();
    const getUserData = async () => {
        const profile = await profileClient.getProfile();
        setUserProfile(() => profile.data);
        const appointments = await getAppointmentByClientId(profile.data.profile.user);
        setUserAppointments(() => appointments);
    };
    useEffect(() => {
        getUserData();
        //eslint-disable-next-line
    }, []);
    return (
        <IonPage>
            <IonContent>
                {userProfile ? (
                    <div className="w-full">
                        <Header title="Mi Perfil" right={''} left={<MenuIcon />} />
                        <div className="flex justify-center my-7">
                            <div className="relative h-[130px] rounded-full">
                                <img
                                    src={
                                        userProfile?.profile?.img?.url
                                            ? userProfile?.profile?.img?.url
                                            : userProfile?.profile?.img?.blob.data
                                            ? `data:${userProfile?.profile?.img?.blob.mimetype};base64,${userProfile?.profile?.img?.blob.data}`
                                            : 'https://picsum.photos/200/300?random=1'
                                    }
                                    alt="profileImg"
                                    className="h-full w-[130px] rounded-full"
                                />
                                <div className="flex justify-end">
                                    <div
                                        className="bg-[#82385E] rounded-full p-2 absolute top-0 -right-2 cursor-pointer"
                                        onClick={() => {
                                            console.log('boton clickeado');
                                        }}>
                                        <EditIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center w-full pb-5">
                            <div className="flex flex-col  w-5/6">
                                <div className="relative">
                                    <div className="absolute -bottom-3 left-2">
                                        <label
                                            htmlFor="userName"
                                            className="text-[#82385E] font-semibold bg-white px-1">
                                            Nombre
                                        </label>
                                    </div>
                                </div>

                                <div className=" flex justify-center">
                                    <input
                                        type="text"
                                        defaultValue={userProfile?.profile.firstName}
                                        name="userName"
                                        id="userName"
                                        className="h-14 border rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1  w-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center w-full pb-5">
                            <div className="flex flex-col w-5/6">
                                <div className="relative">
                                    <div className="absolute -bottom-3 left-2">
                                        <label
                                            htmlFor="userName"
                                            className="text-[#82385E] font-semibold bg-white px-1">
                                            Correo
                                        </label>
                                    </div>
                                </div>

                                <div className="flex justify-center">
                                    <input
                                        type="text"
                                        name="userEmail"
                                        defaultValue={userProfile?.user.email}
                                        id="userEmail"
                                        className="h-14 border rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center w-full pb-5">
                            <div className="flex flex-col w-5/6">
                                <div className="relative">
                                    <div className="absolute -bottom-3 left-2">
                                        <label
                                            htmlFor="userName"
                                            className="text-[#82385E] font-semibold bg-white px-1">
                                            Teléfono
                                        </label>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <input
                                        type="text"
                                        name="userPhone"
                                        id="userPhone"
                                        defaultValue={userProfile?.user.phone}
                                        className="h-14 border rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center w-full pb-5">
                            <div className="flex flex-col w-5/6">
                                <div className="relative">
                                    <div className="absolute -bottom-3 left-2">
                                        <label
                                            htmlFor="userName"
                                            className="text-[#82385E] font-semibold bg-white px-1">
                                            Cumpleaños *
                                        </label>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <input
                                        type="date"
                                        name="birthDate"
                                        id="birthDate"
                                        defaultValue={userProfile?.profile.birthDate}
                                        className="h-14 border rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full"
                                    />
                                </div>
                                <div className="flex justify-start mt-1">
                                    <p className="text-[#1F1F1F80]">*Formato: día/mes/año </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center w-full mb-2">
                            <div className="w-5/6 text-center rounded-md bg-[#F5F5F5] py-2 text-2xl drop-shadow-md">
                                <p className="text-[#82385E]">Mis citas</p>
                            </div>
                        </div>
                        <div className="flex justify-center w-[full] mb-2">
                            <div className="w-[90%] flex justify-center flex-col items-center">
                                {userAppointments?.length > 0 ? (
                                    userAppointments
                                        .slice(0, 3)
                                        .map((appointment, index) => (
                                            <StylistCard appointment={appointment} key={index} />
                                        ))
                                ) : (
                                    <p className="text-primaryColor m-4">No agendaste citas todavía</p>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-center w-full mb-2">
                            <div className="flex flex-col w-5/6 ">
                                <div className="text-center rounded-md bg-[#F5F5F5] py-2 text-2xl drop-shadow-md mb-6">
                                    <p className="text-[#82385E]">Mis estilistas</p>
                                </div>
                                <div className="mb-5">
                                    <div className="relative">
                                        <div className="absolute -bottom-3 left-2">
                                            <label
                                                htmlFor="userName"
                                                className="text-[#82385E] font-semibold bg-white px-1">
                                                Peluquero habitual
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        <input
                                            type="text"
                                            name="mainCut"
                                            id="mainCut"
                                            className="h-14 border rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full"
                                        />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="relative">
                                        <div className="absolute -bottom-3 left-2">
                                            <label
                                                htmlFor="userName"
                                                className="text-[#82385E] font-semibold bg-white px-1">
                                                Barbero habitual
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        <input
                                            type="text"
                                            name="mainBarber"
                                            id="mainBarber"
                                            className="h-14 border rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full"
                                        />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="relative">
                                        <div className="absolute -bottom-3 left-2">
                                            <label
                                                htmlFor="userName"
                                                className="text-[#82385E] font-semibold bg-white px-1">
                                                Manicurista habitual
                                            </label>
                                        </div>
                                    </div>
                                    <div className="flex justify-center">
                                        <input
                                            type="text"
                                            name="mainHand"
                                            id="mainHand"
                                            className="h-14 border rounded-md border-slate-400 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Loader />
                )}
            </IonContent>
        </IonPage>
    );
};

export default ProfileClientPage;
