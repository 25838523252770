import { gql, useMutation } from '@apollo/client';

export const useServicePerSalonDelete = () => {
    const [deleteServicePerSalon] = useMutation(gql`
        mutation DeleteOneServicePerSalon($query: ServicePerSalonQueryInput!) {
            deleteOneServicePerSalon(query: $query) {
                _id
            }
        }
    `);

    return async (id: string) => {
        const { data } = await deleteServicePerSalon({
            variables: {
                query: {
                    _id: id,
                },
            },
        });
        return data.deleteOneServicePerSalon;
    };
};
