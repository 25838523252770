import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import getRealmUser, { appId } from './getRealmUser';
const { REACT_APP_GRAPHQL_URL } = process.env;
const graphqlUri = REACT_APP_GRAPHQL_URL || `https://realm.mongodb.com/api/client/v2.0/app/${appId}/graphql`;
const getApolloClient = () => {
    return new ApolloClient({
        link: new HttpLink({
            uri: graphqlUri,
            fetch: async (uri: RequestInfo, options?: RequestInit) => {
                const realmUser = await getRealmUser();
                const realmAccessToken = realmUser?.accessToken;
                const authorizationHeader = `Bearer ${realmAccessToken}`;
                const headers = (options?.headers || {}) as Record<string, string>;
                headers.Authorization = authorizationHeader;

                if (!options) {
                    options = {};
                }

                options.headers = headers;
                return fetch(uri, options);
            },
        }),
        cache: new InMemoryCache(),
    });
};

export default getApolloClient;
