import { gql, useLazyQuery } from '@apollo/client';
export const useUserQuery = () => {
    const [getUsers] = useLazyQuery(
        gql`
            query users($entioId: String) {
                user(query: { entioId: $entioId }) {
                    _id
                    entioId
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
        }
    );
    const getUserByEntioId = async (entioId: string) => {
        try {
            const result = await getUsers({ variables: { entioId } });
            console.log(result);
            return result.data?.user;
        } catch (err) {
            console.log(err);
        }
    };
    return {
        getUserByEntioId,
    };
};
