import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import { Header } from '../../components';
import { CatalogueCard } from '../../components';

import { MenuIcon, SearchIcon } from '../../assets/icons';
import { IonContent, IonPage } from '@ionic/react';

import { getAllSalonsQuery } from '../../lib/listingSalons';
import { getAllServicesQuery } from '../../lib/listingServices';

import { Salon } from '../../lib/dataTypes/salon';
import Loader from '../../components/Loader';

interface Service {
    _id: string;
    name: string;
    description: string;
}

const CataloguePage = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [loadSalons, salonsResult] = useLazyQuery(getAllSalonsQuery, {
        fetchPolicy: 'no-cache',
    });
    const [loadServices, servicesResult] = useLazyQuery(getAllServicesQuery, {
        fetchPolicy: 'no-cache',
    });

    const [filterSelected, setFilterSelected] = useState<string>('default');
    const [salonName, setSalonName] = useState<string>('');

    function selectFilter(event: React.ChangeEvent<HTMLSelectElement>) {
        setFilterSelected((prevState) => (prevState = event.target.value));
    }

    function searchByName(event: React.ChangeEvent<HTMLInputElement>) {
        setSalonName((prevState) => (prevState = event.target.value));
    }

    let salonsToShow: Salon[] = [];

    if (filterSelected === 'default' && salonsResult.data !== undefined) {
        salonsToShow = [...salonsResult.data.salons];
    } else if (filterSelected !== 'default' && salonsResult.data !== undefined) {
        const result = salonsResult?.data?.salons.filter((e: Salon) => {
            return e.services.some((e) => e.service.name.includes(filterSelected));
        });
        salonsToShow = [...result];
    }

    if (salonName !== '') {
        const result = salonsToShow.filter((e: Salon) => {
            const rgxp = new RegExp(salonName, 'i');
            return rgxp.test(e.name);
        });
        salonsToShow = [...result];
    }

    useEffect(() => {
        loadSalons();
        loadServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        salonsResult.data && setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salonsResult]);
    return (
        <IonPage>
            {loading ? (
                <IonContent>
                    <Loader />
                </IonContent>
            ) : (
                <IonContent>
                    <Header
                        title="Catálogo"
                        left={<MenuIcon />}
                        right={
                            <img
                                src="https://xsgames.co/randomusers/avatar.php?g=female"
                                alt="profile"
                                className="rounded-full h-[5vh]"
                            />
                        }
                        menuRequire
                    />
                    <div className="w-10/12 mx-auto mt-6 mb-8 bg-[#F5F5F5] h-12 rounded-2xl pl-5 pr-4 flex shadow-[0_1px_3px_rgba(0,0,0,0.3)]">
                        <input
                            onChange={searchByName}
                            type="text"
                            placeholder="Nombre"
                            name="search"
                            className="bg-transparent h-12 w-full focus:outline-none text-[18px] font-monserrat text-black"
                        />
                        <button type="submit">
                            <SearchIcon />
                        </button>
                    </div>
                    <div className="flex justify-center w-full bg-white">
                        <div className="flex flex-col w-5/6">
                            <div className="relative">
                                <div className="absolute -bottom-3 left-2">
                                    <label htmlFor="filter" className="text-[#82385E] font-semibold bg-white pr-1">
                                        Filtrar por servicios
                                    </label>
                                </div>
                            </div>
                            <div className="w-full  bg-white">
                                <select
                                    onChange={selectFilter}
                                    defaultValue="default"
                                    name="filter"
                                    id="filter"
                                    className="border bg-white rounded-md border-zinc-300 focus:border-[#82385E] focus:border-2 focus:outline-none px-2 pt-2 pb-1 w-full mb-8 h-14">
                                    <option value="default">Seleccione una opción</option>
                                    {servicesResult?.data?.services.map((e: Service, i: number) => (
                                        <option key={i} value={e.name}>
                                            {e.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    {salonsToShow.length ? (
                        salonsToShow.map((e: Salon, i: number) => (
                            <Link to={'/clients/salon/' + e._id} key={i}>
                                <CatalogueCard key={i} data={e} />
                            </Link>
                        ))
                    ) : (
                        <p className="flex justify-center w-full">No se hallaron resultados</p>
                    )}
                </IonContent>
            )}
        </IonPage>
    );
};

export default CataloguePage;
