import { gql, useMutation } from '@apollo/client';
import { ServicesPerSalon } from '../dataTypes/services';

export const useAppointmentCreate = () => {
    const [create] = useMutation(gql`
        mutation CreateAppointment(
            $client: ObjectId!
            $salon: ObjectId!
            $date: DateTime!
            $time: [String!]
            $stylist: ObjectId!
            $service: [ObjectId!]
            $servicePerSalon: [ObjectId!]
            $price: String!
        ) {
            insertOneAppointment(
                data: {
                    client: { link: $client }
                    salon: { link: $salon }
                    date: $date
                    time: $time
                    stylist: { link: $stylist }
                    service: { link: $service }
                    servicePerSalon: { link: $servicePerSalon }
                    price: $price
                }
            ) {
                _id
                client {
                    _id
                    entioId
                }
                date
                time
                stylist {
                    _id
                    entioId
                }
                salon {
                    _id
                }
                service {
                    _id
                }
                servicePerSalon {
                    _id
                }
                price
            }
        }
    `);

    return async (data: {
        client: string;
        salon: string;
        date: Date;
        time: Array<string>;
        stylist: string;
        service: Array<string>;
        servicePerSalon: Array<ServicesPerSalon>;
        price: string;
    }) => {
        try {
            const newData = {
                client: data.client,
                salon: data.salon,
                date: data.date,
                time: data.time,
                stylist: data.stylist,
                service: data.service,
                servicePerSalon: data.servicePerSalon.map((e) => e._id),
                price: data.price,
            };
            const response = await create({
                variables: newData,
            });
            console.log(response);
            return response?.data?.insertOneAppointment;
        } catch (err) {
            console.log(err);
        }
    };
};
