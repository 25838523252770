import React from 'react';

type Props = {
    image: string;
    name: string;
    phone: string;
    email: string;
    id: string;
};

const ClientDirectoryCard = ({ name, image, phone, email, id }: Props) => {
    return (
        <div className="w-full bg-[#F5F5F5] mx-auto my-5 border border-1 border-[#7E747C] rounded-[12px] flex items-center pl-4">
            <img src={image} className="w-16 h-16 rounded-full" alt="client" />
            <div className="pl-4 py-1 font-monserrat">
                <p className="text-[#82385E] text-[20px] font-medium leading-6 mb-1 tracking-tight">{name}</p>
                <p className="text-[14px] leading-6 tracking-tight">Teléfono: {phone}</p>
                <p className="text-[14px] leading-6 tracking-tight">Correo: {email}</p>
                <a href={`/owner/client/${id}`} className="tracking-tight text-yellow-500">
                    Ver más
                </a>
            </div>
        </div>
    );
};

export default ClientDirectoryCard;
