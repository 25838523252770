import React from 'react';
import { AddressIcon, StylistNumIcon, ScoreIcon } from '../assets/icons';
import { Salon } from '../lib/dataTypes/salon';
import getFileUrl from '../lib/getFileUrl';

interface Props {
    salon: Salon;
}
const InformationSalon = (props: Props) => {
    const salon = props?.salon;
    const name = salon?.name || '';
    return (
        <div className="m-auto">
            <div className="flex flex-col w-full h-auto bg-gray-100 rounded-t-3xl">
                <div className="row-span-2 flex flex-col items-center justify-center w-full h-auto">
                    {/* <img     
                                    className="rounded-full border-2 border-primaryColor w-12 h-12"
                                /> */}
                </div>
                <div className="w-full h-auto  flex flex-row text-center font-montserrat pl-3 pt-2 text-3xl">
                    <h1 className="w-full">{name}</h1>
                </div>
                <div className="col-span-4 flex flex-row flex-wrap justify-evenly w-full">
                    <div className="flex w-auto flex-row justify-center items-center">
                        <AddressIcon />
                        <p className="font-montserrat text-base">{props.salon?.address || ''}</p>
                    </div>
                    <div className="w-auto flex flex-row justify-center items-center">
                        <StylistNumIcon />
                        <p className="font-montserrat text-base">{props.salon?.workers.length || ''} estilistas</p>
                    </div>
                    <div className="w-auto flex flex-row justify-center items-center">
                        <ScoreIcon />
                        <p className="w-auto font-montserrat text-base">{}</p>
                    </div>
                </div>
            </div>

            <div className="flex flex-col w-full h-auto bg-white">
                <p className="max-h-auto p-3 font-montserrat text-lg w-full break-words tracking-wide">
                    {props.salon?.description || ''}
                </p>
                <img
                    src={
                        props?.salon?.img?.url
                            ? getFileUrl(props?.salon?.img?.url)
                            : 'https://ciep.mx/wp-content/uploads/2019/09/placeholder.png'
                    }
                    alt="salon"
                    className="w-full h-52"
                />
            </div>
        </div>
    );
};

export default InformationSalon;
