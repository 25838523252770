interface Tt {
    friday: D;
    monday: D;
    saturday: D;
    sunday: D;
    thursday: D;
    tuesday: D;
    wednesday: D;
}
interface D {
    end: string;
    start: string;
}
type Key = keyof Tt;

const getLastDay = function (workweek: Tt) {
    let lastDay = '';
    const days: string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    for (let i = days.length - 1; i >= 0; i--) {
        if (
            Object.keys(workweek).includes(days[i]) &&
            workweek[days[i] as Key].start !== '' &&
            workweek[days[i] as Key].end !== '' &&
            lastDay === ''
        ) {
            lastDay =
                days[i] === 'monday'
                    ? 'Lunes'
                    : days[i] === 'tuesday'
                    ? 'Martes'
                    : days[i] === 'wednesday'
                    ? 'Miércoles'
                    : days[i] === 'thursday'
                    ? 'Jueves'
                    : days[i] === 'friday'
                    ? 'Viernes'
                    : days[i] === 'saturday'
                    ? 'Sábado'
                    : days[i] === 'sunday'
                    ? 'domingo'
                    : 'No establecido';
        }
    }
    return lastDay;
};

export { getLastDay };
