import React, { useEffect, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { MenuIcon, SettingsIcon } from '../../assets/icons';
import { Calendar, Header } from '../../components';
import { toFullLongDate } from '../../utils/dateFormatter';
import { useAppointmentQuery } from '../../lib/dataClient/useAppointmentQuery';
import { Appointment } from '../../lib/dataTypes/appointments';
import { useSalonQuery } from '../../lib/dataClient/useSalonQuery';
import { Salon } from '../../lib/dataTypes/salon';
import { ScheduleArray } from '../../utils/schedule';
import moment from 'moment';
import { getProfileClient } from '../../lib/getProfileClient';
import { FashProfile } from '../../lib/dataTypes/fashProfile';

interface Schedule {
    start: string;
    end: string;
}

const SchedulePage = () => {
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const { getAppointmentsByStylist } = useAppointmentQuery();
    const { getOneSalonByStylistEntioId } = useSalonQuery();
    const [user, setUser] = useState<FashProfile>();
    const [clients, setClients] = useState<FashProfile[]>([]);
    const profileClient = getProfileClient();
    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [hoursToShow, setHoursToShow] = useState<string[]>([]);
    const [salon, setSalon] = useState<Salon>();
    const getAppointments = async () => {
        try {
            if (salon && user) {
                const data = await getAppointmentsByStylist(salon?._id, selectedDate, user.user.id);
                setAppointments(() => data);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const getSalonData = async () => {
        const user = await profileClient.getProfile();
        setUser(() => user.data);
        try {
            const data = await getOneSalonByStylistEntioId(user.data.user.id);
            setSalon(() => data);
        } catch (err) {
            console.log(err);
        }
    };

    const salonInning = async () => {
        let filteredHours = [...ScheduleArray];
        const day = moment(selectedDate).format('dddd');
        let schedule: Schedule | undefined;
        day === 'Monday'
            ? (schedule = salon?.timetable.monday)
            : day === 'Tuesday'
            ? (schedule = salon?.timetable.tuesday)
            : day === 'Wednesday'
            ? (schedule = salon?.timetable.wednesday)
            : day === 'Thursday'
            ? (schedule = salon?.timetable.thursday)
            : day === 'Friday'
            ? (schedule = salon?.timetable.friday)
            : day === 'Saturday'
            ? (schedule = salon?.timetable.saturday)
            : (schedule = salon?.timetable.sunday);
        const start = schedule?.start || '';
        const indexStart = filteredHours.findIndex((e: string) =>
            e.startsWith('T') ? e.slice(1).startsWith(start) : e.startsWith(start)
        );
        if (moment(selectedDate).isBefore(moment().subtract(1, 'day'))) {
            filteredHours = ['Fecha no válida'];
            setHoursToShow(() => filteredHours);
            return;
        }
        const end = schedule?.end || '';
        const indexEnd = filteredHours.findIndex((e: string) => e.endsWith(end));
        if (end && start) {
            setHoursToShow(() => filteredHours.slice(indexStart, indexEnd + 1));
        } else {
            setHoursToShow(() => []);
        }
    };
    const getClients = async () => {
        const clientsId = appointments.map((appointment) => appointment.client.entioId);
        const profiles = await profileClient.getProfiles(clientsId);
        //eslint-disable-next-line
        const profilesArray: FashProfile[] | any = [];
        for (const data in profiles.data) {
            profilesArray.push(profiles.data[data]);
        }
        setClients(() => profilesArray);
    };

    useEffect(() => {
        getAppointments();
        getSalonData();
        salonInning();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        salonInning();
        getAppointments();
        // eslint-disable-next-line
    }, [selectedDate]);

    useEffect(() => {
        getClients();
        //eslint-disable-next-line
    }, [appointments]);

    return (
        <IonPage>
            <IonContent>
                <Header title="Agenda" left={<MenuIcon />} right={<SettingsIcon />} menuRequire />
                <div className="px-4 py-8 flex flex-col gap-7 w-full max-w-[420px] mx-auto">
                    <p className="text-center text-primaryColor">
                        {hoursToShow.length > 1 ? ' ' : 'Selecciona una fecha en el calendario para mostrar los turnos'}{' '}
                    </p>
                    <Calendar onChange={setSelectedDate} value={selectedDate} className="m-2" />
                    <header className="bg-gray-100 text-center shadow-[0_1px_2px_rgb(0,0,0,0.3)] mx-2">
                        <h2 className="p-2 text-xl tracking-[-0.025px] text-primaryColor">
                            {toFullLongDate(selectedDate)}
                        </h2>
                    </header>
                    <section className="flex flex-col gap-4 m-2">
                        {hoursToShow &&
                            hoursToShow.map((hour, index) => (
                                <article
                                    key={index}
                                    className="flex flex-shrink-0 justify-between items-center rounded-[4px] bg-primaryColor bg-opacity-[0.12] h-[10vh] outline outline-[#82385e] outline-1">
                                    {hour === 'Fecha no válida' ? (
                                        <p className="py-1 px-3 w-full text-center self-center font-medium rounded-[4px] mx-2">
                                            {hour}
                                        </p>
                                    ) : (
                                        <p className="py-1 px-3 min-w-[114px] text-center bg-white border border-[#1F1F1F80] rounded-[4px] mx-2">
                                            {hour}
                                        </p>
                                    )}
                                    {hour !== 'Fecha no válida' && (
                                        <div className="flex h-[10vh] items-center justify-start w-[45%]">
                                            {appointments &&
                                                appointments.map(
                                                    (appointment, index) =>
                                                        appointment.time.includes(hour) && (
                                                            <a
                                                                key={index}
                                                                href={`/stylist/appointment/${appointment._id}`}
                                                                className="w-1/4 outline outline-1 h-[95%] flex flex-col justify-around items-center p-1 bg-[#f3f3f3] rounded-[4px]">
                                                                <img
                                                                    className="rounded-full h-[5vh] w-[5vh]"
                                                                    src="https://picsum.photos/200/300?random=1"
                                                                    alt=""
                                                                />
                                                                <p className="text-xs">
                                                                    {clients &&
                                                                        clients[index]?.profile?.firstName?.split(
                                                                            ' '
                                                                        )[0]}
                                                                </p>
                                                            </a>
                                                        )
                                                )}
                                        </div>
                                    )}
                                </article>
                            ))}
                    </section>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default SchedulePage;
