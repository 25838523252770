import React from 'react';
const PingIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="21" fill="none" viewBox="0 0 16 21">
            <path
                fill="#82385E"
                d="M8 10.661c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0-10c4.2 0 8 3.22 8 8.2 0 3.18-2.45 6.92-7.34 11.23-.38.33-.95.33-1.33 0C2.45 15.781 0 12.041 0 8.861c0-4.98 3.8-8.2 8-8.2z"></path>
        </svg>
    );
};

export default PingIcon;
