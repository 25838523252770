import React, { useEffect, useState } from 'react';
import { OurServicesItem, GeneralInfoItem, DaysHoursItem, WorkersItem, SalonImageItem } from '../../../components';
import { MenuIcon, SettingsIcon } from '../../../assets/icons';
import { Header } from '../../../components';
import { Salon } from '../../../lib/dataTypes/salon';
import { useSalonQuery } from '../../../lib/dataClient/useSalonQuery';
import { IonContent, IonPage } from '@ionic/react';
import ServicesPage from './EditHairdresserServices';
import EditHairdresserWorkers from './EditHairdresserWorkers';
import { getProfileClient } from '../../../lib/getProfileClient';
const MyHairdresserPage = () => {
    const { getOneSalonByOwnerEntioId } = useSalonQuery();
    const [salon, setSalon] = useState<Salon>();
    const [editService, setEditService] = useState(false);
    const [editWorkers, setEditWorkers] = useState(false);
    const profileClient = getProfileClient();
    const getSalonData = async () => {
        try {
            const owner = await profileClient.getProfile();
            const result = await getOneSalonByOwnerEntioId(owner.data.user.id);
            setSalon(() => result);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getSalonData();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        console.log(salon);
    }, [salon]);
    return (
        <IonPage>
            <IonContent>
                {editService ? (
                    <ServicesPage edit={editService} setSalon={setSalon} salon={salon} setEdit={setEditService} />
                ) : editWorkers ? (
                    <EditHairdresserWorkers
                        workers={salon ? salon.workers : []}
                        salonId={salon?._id}
                        edit={editWorkers}
                        setEdit={setEditWorkers}
                        setSalon={setSalon}
                    />
                ) : (
                    <div className="w-full">
                        <Header title="Mi Salón" left={<MenuIcon />} right={<SettingsIcon />} menuRequire />
                        {salon && <SalonImageItem salon={salon} setSalon={setSalon} />}
                        {salon && <OurServicesItem setEdit={setEditService} edit={editService} salon={salon} />}
                        {salon && <GeneralInfoItem salon={salon} setSalon={setSalon} />}
                        {salon && <DaysHoursItem salon={salon} setSalon={setSalon} />}
                        {salon && (
                            <WorkersItem
                                salon={salon}
                                setSalon={setSalon}
                                setEditWorkers={setEditWorkers}
                                editWorkers={editWorkers}
                            />
                        )}
                    </div>
                )}
            </IonContent>
        </IonPage>
    );
};
export default MyHairdresserPage;
