const REACT_APP_BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL || '';

const getFileUrl: (filePath: string) => string = (filePath?: string) => {
    if (!filePath) {
        return '';
    }

    return `${REACT_APP_BASE_IMAGE_URL}${filePath}`;
};

export default getFileUrl;
