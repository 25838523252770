import React, { useEffect, useState } from 'react';
import { IonCardHeader } from '@ionic/react';
import { EditIcon } from '../assets/icons';
import { Salon } from '../lib/dataTypes/salon';
import { getProfileClient } from '../lib/getProfileClient';
import { FashProfile } from '../lib/dataTypes/fashProfile';

interface Props {
    salon: Salon;
    setSalon: React.Dispatch<React.SetStateAction<Salon | undefined>>;
    setEditWorkers: React.Dispatch<React.SetStateAction<boolean>>;
    editWorkers: boolean;
}

const WorkersItem = (props: Props) => {
    const profileClient = getProfileClient();
    const [workers, setWorkers] = useState<FashProfile[]>();
    const getWorkers = async () => {
        const workersId = props.salon.workers.map((worker) => worker.entioId);
        const response = await profileClient.getProfiles(workersId);
        if (response.data) {
            //eslint-disable-next-line
            const workersArray: FashProfile[] | any = [];
            for (const data in response.data) {
                workersArray.push(response.data[data]);
            }
            setWorkers(() => workersArray);
        }
    };
    useEffect(() => {
        getWorkers();
        //eslint-disable-next-line
    }, [props.salon]);
    return (
        <div className="mt-4 flex flex-col font-montserrat px-2 justify-center">
            <IonCardHeader className="navbar navbar-expand-lg shadow-md py-2 px-2 bg-gray-100 flex items-center justify-between">
                <div className="text-primaryColor text-[22px] px-2">Trabajadores</div>
                <div
                    className="relative rounded-full bg-primaryColor p-1"
                    onClick={(e) => {
                        props.setEditWorkers(() => !props.editWorkers);
                    }}>
                    <EditIcon />
                </div>
            </IonCardHeader>

            <div className="flex flex-wrap m-4 h-[30vh] overflow-auto scrollbar-hidden">
                {workers &&
                    workers.map((worker, i) => {
                        return (
                            <div
                                key={i}
                                className="flex flex-col h-[45%] justify-around items-center rounded-[4px] w-[25%] break-words  m-1 p-1">
                                <img
                                    src={
                                        worker?.profile?.img?.url
                                            ? worker?.profile?.img?.url
                                            : worker?.profile?.img?.blob.data
                                            ? `data:${worker.profile?.img?.blob.mimetype};base64,${worker.profile?.img?.blob.data}`
                                            : 'https://picsum.photos/200/300?random=1'
                                    }
                                    alt="imagen de perfil del trabajador"
                                    className="w-[80%] h-[70%] my-1 rounded-full object-cover outline-1 outline outline-primaryColor"
                                />
                                <p className="text-center w-full text-xs">{worker.profile.firstName}</p>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default WorkersItem;
