import React from 'react';

const CrossIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0001 0.666687C6.62675 0.666687 0.666748 6.62669 0.666748 14C0.666748 21.3734 6.62675 27.3334 14.0001 27.3334C21.3734 27.3334 27.3334 21.3734 27.3334 14C27.3334 6.62669 21.3734 0.666687 14.0001 0.666687ZM14.0001 24.6667C8.12008 24.6667 3.33341 19.88 3.33341 14C3.33341 8.12002 8.12008 3.33335 14.0001 3.33335C19.8801 3.33335 24.6667 8.12002 24.6667 14C24.6667 19.88 19.8801 24.6667 14.0001 24.6667ZM14.0001 12.12L18.7867 7.33335L20.6667 9.21335L15.8801 14L20.6667 18.7867L18.7867 20.6667L14.0001 15.88L9.21342 20.6667L7.33342 18.7867L12.1201 14L7.33342 9.21335L9.21342 7.33335L14.0001 12.12Z"
                fill="#82385E"
            />
        </svg>
    );
};

export default CrossIcon;
