import React, { SVGProps } from 'react';

const MoneyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M16 0C7.168 0 0 7.168 0 16s7.168 16 16 16 16-7.168 16-16S24.832 0 16 0Zm2.256 25.744v.928c0 1.168-.96 2.128-2.128 2.128h-.016a2.137 2.137 0 0 1-2.128-2.128v-.96c-2.128-.448-4.016-1.616-4.816-3.584-.368-.88.32-1.856 1.28-1.856h.384c.592 0 1.072.4 1.296.96.464 1.2 1.68 2.032 4.016 2.032 3.136 0 3.84-1.568 3.84-2.544 0-1.328-.704-2.576-4.272-3.424-3.968-.96-6.688-2.592-6.688-5.872 0-2.752 2.224-4.544 4.976-5.136v-.96c0-1.168.96-2.128 2.128-2.128h.016c1.168 0 2.128.96 2.128 2.128v.992c2.208.544 3.6 1.92 4.208 3.616.32.88-.352 1.808-1.296 1.808h-.416c-.592 0-1.072-.416-1.232-.992-.368-1.216-1.376-2-3.392-2-2.4 0-3.84 1.088-3.84 2.624 0 1.344 1.04 2.224 4.272 3.056 3.232.832 6.688 2.224 6.688 6.256-.032 2.928-2.224 4.528-5.008 5.056Z"
            fill="#82385E"
        />
    </svg>
);

export default MoneyIcon;
