import React from 'react';
import { getMinTime } from '../utils/minTime';
import { getMaxTime } from '../utils/maxTime';
import { getFirstDay } from '../utils/firstDay';
import { getLastDay } from '../utils/lastDay';
import { CalendarIcon, PingIcon, WorkersIcon } from '../assets/icons';
import { Salon } from '../lib/dataTypes/salon';
import getFileUrl from '../lib/getFileUrl';

interface Props {
    data: Salon;
}

const CatalogueCard = (props: Props) => {
    console.log(props);
    const minTime = getMinTime(props.data.timetable);
    const maxTime = getMaxTime(props.data.timetable);
    const firstDay = getFirstDay(props.data.timetable);
    const lastDay = getLastDay(props.data.timetable);
    return (
        <div className="flex justify-center mb-4">
            <div className="flex flex-row w-5/6 border border-zinc-300 rounded-lg bg-[#F5F5F5]">
                <div className="w-4/5 px-3">
                    <div className="flex flex-col">
                        <p className="font-semibold text-lg mt-2">{props.data.name}</p>
                        <div className="flex flex-row mt-2">
                            <div className="pt-2">
                                <CalendarIcon />
                            </div>
                            <div className="flex flex-col ml-3 mb-2">
                                <p className="text-sm">{`${firstDay} a ${lastDay}`}</p>
                                <p className="text-sm">{`${minTime} a ${maxTime}`}</p>
                            </div>
                        </div>
                        <div className="flex flex-row mb-2">
                            <div className="flex flex-row">
                                <PingIcon />
                                <p className="ml-3 text-sm">{props.data.address}</p>
                            </div>
                            <div className="flex flex-row ml-10">
                                <WorkersIcon />
                                <p className="ml-3 text-sm">{`${props.data.workers.length} estilistas`}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-4/12 rounded-tr-lg rounded-br-lg">
                    <img
                        src={
                            props?.data?.img?.url
                                ? getFileUrl(props?.data?.img?.url)
                                : 'https://ciep.mx/wp-content/uploads/2019/09/placeholder.png'
                        }
                        className="h-full w-full"
                        alt="product"
                    />
                </div>
            </div>
        </div>
    );
};

export default CatalogueCard;
