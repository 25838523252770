import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { CalendarIcon, ClockIcon } from '../assets/icons';
import { Appointment } from '../lib/dataTypes/appointments';
import { getProfileClient } from '../lib/getProfileClient';
import { FashProfile } from '../lib/dataTypes/fashProfile';

interface Props {
    appointment: Appointment;
}
const ClientCard = ({ appointment }: Props) => {
    const profileClient = getProfileClient();
    const [user, setUser] = useState<FashProfile[]>();
    const getData = async () => {
        if (appointment.client.entioId) {
            try {
                const ids: string[] = [appointment.client.entioId];
                const profile = await profileClient.getProfiles(ids);
                if (profile.data) {
                    //eslint-disable-next-line
                    const array: FashProfile[] | any = [];
                    for (const data in profile.data) {
                        array.push(profile.data[data]);
                        setUser(() => array);
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
    };
    useEffect(() => {
        getData();
        //eslint-disable-next-line
    }, []);

    return (
        <a href={`/stylist/appointment/${appointment._id}`} className="w-full flex flex-col items-center mb-4">
            {appointment.status === 'paid' ? (
                <p className="text-center text-[18px] bg-primaryColor text-white pl-2 pr-2 rounded-t-lg">Pagado</p>
            ) : (
                <p className="text-center text-[18px] bg-primaryColor text-white pl-2 pr-2 rounded-t-lg">Por pagar</p>
            )}
            <div className="flex outline outline-1 outline-gray-400 bg-secondaryColor py-[1.5%] pr-2 pl-4 w-full rounded-md h-[107px] hover:outline-primaryColor justify-between">
                <div className="w-[80%] h-full flex flex-col justify-around">
                    <h3 className="text-[20px] font-medium">{user && user[0].profile.firstName}</h3>
                    <div className="flex items-center">
                        <CalendarIcon />
                        <p className="text-xs ml-2">{moment(appointment.date).format('dddd, DD MMMM YY')}</p>
                    </div>
                    <div className="flex justify-between w-full items-center">
                        <div className="flex items-center w-[40]">
                            <ClockIcon />
                            <p className="text-xs ml-2">{`${appointment?.time?.[0].slice(0, 8)}${appointment?.time?.[
                                appointment?.time?.length - 1
                            ].slice(8)}`}</p>
                        </div>
                        <div className="flex items-center justify-center flex-col w-[40%] break-words">
                            <div>
                                {appointment.service.map((service, index) => (
                                    <p key={index} className="text-xs mr-1">
                                        {service.name.length > 10
                                            ? service.name.substring(0, 10) + '...'
                                            : service.name}
                                    </p>
                                ))}
                            </div>
                            <div className="flex flex-row justify-around w-full">
                                {appointment.service.map((service, index) => (
                                    <img
                                        key={index}
                                        src={`data:${service.img?.blob?.mimeType};base64,${service.img?.blob?.data}`}
                                        alt="img_services"
                                        className="w-[20px] h-[20px] mx-[2px]"
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-12 flex justify-center items-center">
                    <img
                        className="w-full rounded-full shadow-md"
                        src="https://xsgames.co/randomusers/avatar.php?g=male"
                        alt="avatar"
                    />
                </div>
            </div>
        </a>
    );
};

export default ClientCard;
